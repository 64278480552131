import React from 'react';

import { TaskChart } from './TaskChartInfo/TaskChart';
import { TicketChart } from './TicketChartInfo/TicketChart';

export default function GeneralCharts() {
    return (
        <div className='seccion-tabla charts'>
            <div className='seccion-tabla-interno'>
                <div className='general_charts_panel'>
                    <TicketChart />
                    <TaskChart />
                </div>
            </div>
        </div>
    )
}
