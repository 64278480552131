export const initialUserFormState = {
  name: "",
  lastname: "",
  email: "",
  phone: "",
  cellphone: "",
  extension: "",
  username: "",
  password: "",
  password2: "",
  companies: [],
  role: "",
};

export const initialErrorState = { status: false, message: "" };
