import React from 'react'

export const Loader = (isSmall = false) => {
    return (
        <div className={`lds-ring ${isSmall ? 'small': ''}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
