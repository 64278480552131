const LASTNAME = 'lastname'
const EXTENSION = 'extension'
const PHONE = 'phone'
const CELLPHONE = 'cellphone'
const PASSWORD_CONFIRMATION = 'password2'

export const UNREQUIRED = [
	LASTNAME,
	EXTENSION,
	PHONE,
	CELLPHONE,
	PASSWORD_CONFIRMATION,
]

export const SUCCESS = (message = '') => ({
	status: true,
	message,
})
export const FAILURE = (message = '') => ({
	status: true,
	message,
})
