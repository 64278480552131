export const noData = data => {
    if(data[0] && (data[0].value < 1)) {
        return true;
    }
    return false;
}

export const onePriority = data => {
    const aux = data.filter( dataKey => dataKey.value > 0 );
    if(aux.length < 2) {
        return true;
    }
    return false;
}