import React from 'react';
import { useSelector } from 'react-redux';

export const DailyClosedTasksChart = () => {
    const { finishedDailyTasks:tasks } = useSelector(state => state.charts);
    const { user } = useSelector(state => state.auth);
    let data2 = [
        {name: "Total", value: 0}
    ];
    if(tasks) {
        const {
            high,
            medium,
            low
        } = tasks;

        data2 = [
            { name: "Total", value: Number(high + medium + low) }
        ];
    }

    return (
        <div>
            <p>Cerradas diarias <br />({user && user.username})</p>
            <b>{(data2[0] && Number.isInteger(data2[0].value)) ? data2[0].value : 0}</b> 
        </div>
    )
}
