import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadTicket } from '../actions/ticket-detail';
import { hasPanelAccess } from '../helpers/permission';
import { CommentBox } from './CommentBox';
import { Comments } from './Comments';
import { DerivedTo } from './DetalleTicket/DerivedTo';
import { Interactions } from './Interactions';


export const DetalleTicket = () => {

    const {name:role} = useSelector(state => state.auth.role)
    const dispatch = useDispatch();
    const {detail, comments, interactions, time:timeSpent } = useSelector(state => state.detail.ticket);

    const {is_taken, status} = detail;
    /**Función para devolver string con el nombre del grupo o usuario del derivado, si lo tiene, si no, se devuelve un "-" */
    const auxDerived = () => {
        let aux;
        if (detail.derived_to) {
            if(detail.derived_user[0]) {
                aux = detail.derived_user[0].username;
            } else if(detail.derived_group[0]) {
                aux = detail.derived_group[0].name;
            } 
        } else {
            aux = '-'
        }

        return aux;
    }
//console.log('detail', detail.status)
    let { slug } = useParams();
    useEffect( () => {
        dispatch(loadTicket(slug))
    }, [ slug, dispatch ]);

    return (
        <div className='seccion-tabla ticket-detail-special-margin'>
            <div className='seccion-tabla-interno'>
                <div className='seccion-detalle-ticket'>
                    <div className='seccion-detalle-2'>
                        <h5>Comentarios</h5>
                        <div className='seccion-detalle-comentarios'>
                            <Comments comments={ comments } />
                            
                        </div>
                        <div className='comment-box'>

                            <CommentBox
                                slug={ slug } 
                                isTaken={is_taken} 
                                status={status}
                            />

                        </div>
                    </div>
                    
                    <div className='seccion-detalle-1'>
                        <h5>Datos de contacto</h5>
                            <div className='seccion-detalle-datos'>
                                <div className='seccion-detalle-datos-info'>
                                        
                                    <div>
                                        <p>Nombre: </p>
                                        <span>{ detail.user && detail.user.name } { detail.user && detail.user.lastname } </span>
                                    </div>
                                    <div>
                                        <p>Usuario: </p>
                                        <span>{ detail.user && detail.user.username }</span>
                                    </div>
                                    <div>
                                        <p>Empresa: </p>
                                        <span>{ detail.company && detail.company.name }</span>
                                    </div>
                                    <div>
                                        <p>Teléfono: </p>
                                        <span>{ detail.user && detail.user.phone }</span>
                                    </div>
                                    <div>
                                        <p>Interno: </p>
                                        <span>{ detail.user && detail.user.extension 
                                                ? detail.user.extension 
                                                : '-' }</span>
                                    </div>
                                    <div>
                                        <p>Email: </p>
                                        <span>{ detail.user && detail.user.email }</span>
                                    </div>
                                    <div>
                                        <p>Celular: </p>
                                        <span>
                                            { detail.user && detail.user.cellphone 
                                                ? detail.user.cellphone
                                                : '-' }
                                        </span>
                                    </div>
                                    <div>
                                        <p>Tiempo invertido: </p>
                                        <span>{ `${ timeSpent } mins` }</span>
                                    </div>
                                    <div>
                                        <p>Derivado:</p>
                                        <span>
                                            { 
                                                auxDerived() //String con el nombre, user o "-" de derivado
                                            }

                                        </span>
                                    </div>
                                    <div>
                                        <p>Tópico: </p>
                                        <span>{ detail.topic && detail.topic.name }</span>
                                    </div>
                                    <div>
                                        <p>Prioridad</p>
                                        <span>{ detail.priority && detail.priority.name }</span>
                                    </div>
                                    {
                                        hasPanelAccess(role)
                                        && <div>
                                                <p>Tarea asociada:</p>
                                                {
                                                    detail.task
                                                    && <span>
                                                        <Link 
                                                            to={`/panel/task/${ detail.task.id }/detail`}
                                                            target="_blank"
                                                        >
                                                            Tarea #{detail.task.id}("{detail.task.title}")
                                                        </Link>
                                                    </span>
                                                }
                                            </div>
                                    }
                                </div>
                                <div>
                                    <button
                                        className='seccion-detalle-datos-usuario'
                                        disabled
                                    >
                                        Datos de usuario
                                    </button>
                                </div>

                            </div>
                    </div>
                    
                    {
                        hasPanelAccess(role)
                        && <div className='seccion-detalle-3'>
                                <h5>Interacciones</h5>
                                <div className='seccion-detalle-interaccion'>
                                    
                                    <Interactions interactions={ interactions }/>
                                    <div className='detalle-interaccion-fin'>
                                                <div className='detalle-interaccion-fecha'>
                                                    <p> </p>
                                                </div>
                                            </div>
                                </div>
                            </div>
                    }
                    {
                        hasPanelAccess(role)
                        && <div className='seccion-detalle-4'>
                                <DerivedTo slug={slug} />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
