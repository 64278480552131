import Axios from 'axios';
import { TYPES } from '../../types/types';
import { setHeader } from '../header';
import { isLoading, isNotLoading } from '../ui';


export const loadTask = slug => {
    return (dispatch) => {
        dispatch(isLoading());
        //console.log('cargando ticket')
        Axios.get(`api/tasks/${slug}`,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                const {
                    comments,
                    companies,
                    dependencies,
                    interactions,
                    priorities,
                    task,
                    task_children,
                    task_users,
                    task_derive_groups,
                    task_derive_users,
                    time,
                    types
                } = res.data.data;

                //Datos para el detalle
                dispatch(TaskDetail(task));
                dispatch(TaskComments(comments));
                dispatch(TaskInteractions(interactions));
                dispatch(TaskTime(time));
                dispatch(loadTaskInfo(priorities, types, task_users, task_children, dependencies, companies,task_derive_groups,task_derive_users));

                //Datos para el header
                dispatch(setHeader(res.data.data.title, res.data.data.description))
                dispatch(isNotLoading());
            })
            .catch( err => {
                console.log('asd',err);
                dispatch(isNotLoading());
            } )
    }
}


export const updateTaskState = (slug, formValue) => {
    return async(dispatch) => {
        dispatch(isLoading());
        //console.log('VERIFICACIÓN',formValue, slug)
        await Axios.post(`api/tasks/${slug}/update`, formValue,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            console.log(res)
            //alert(res.data.message);
            dispatch(isNotLoading());
        })
        .catch( err => {
            dispatch(isNotLoading());
            alert('No se pudo actualizar la tarea');
            console.log('error');
        } )
    }
}


export const TaskDetail = task => ({
    type: TYPES.setTaskDetail,
    payload: task
})

export const TaskComments = comments => ({
    type: TYPES.setTaskComments,
    payload: comments
})

export const TaskInteractions = (interactions) => ({
    type: TYPES.setTaskInteractions,
    payload: interactions
})

export const TaskTime = timeInMinutes => ({
    type: TYPES.setTaskTime,
    payload: {
        time_spent: timeInMinutes
    }
})

//cambiar la configuración de la tarea y actualizarla.
export const loadTaskInfo = (
    priorities, 
    types, 
    task_users, 
    task_children, 
    dependencies, 
    companies, 
    task_derive_groups,
    task_derive_users
) => ({
    type: TYPES.updateTaskDetail,
    payload:{
            priorities: priorities,
            types: types,
            task_users: task_users,
            task_children: task_children,
            task_derive_users: task_derive_users,
            task_derive_groups: task_derive_groups,
            dependencies: dependencies,
            companies: companies
        }
})
