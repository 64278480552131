import {
  isValidString,
  stringHasNumber,
  isValidEmail,
  onlyNumbers,
} from "helpers/string";
import { hasLetters } from "helpers/number";

export const strategy = {
  name: {
    rule: (item) => !isValidString(item) || stringHasNumber(item),
    message: "Es necesario un nombre válido",
  },
  lastname: {
    rule: (item) => !isValidString(item) || stringHasNumber(item),
    message: "Es necesario un apellido válido",
  },
  email: {
    rule: (item) => !isValidString(item) || !isValidEmail(item),
    message: "Es necesario un correo válido",
  },
  phone: {
    rule: (item) => item && (!isValidString(item) || !onlyNumbers(item)),
    message: "Es necesario un teléfono válido",
  },
  /* 
	cellphone: {
		rule: (item) => item && isValidString(item) && !onlyNumbers(item),
		message: 'Es necesario un celular válido',
	}, */
  username: {
    rule: (item) => !isValidString(item),
    message: "Es necesario un nombre de usuario válido",
  },
  password: {
    rule: (item) => item && !isValidString(item),
    message: "Es necesaria una contraseña válida",
  },
  /* company: {
		rule: (item) =>
			item === undefined ||
			item === null ||
			!isValidString(item) ||
			hasLetters(item),
		message: 'Es necesaria una empresa válida',
	}, */
  companies: {
    rule: (item) =>
      item === undefined ||
      item === null ||
      !Array.isArray(item) ||
      (item && item.length < 1),
    message: "Es necesaria al menos una empresa válida",
  },
  role: {
    rule: (item) =>
      item === undefined ||
      item === null ||
      !isValidString(item) ||
      hasLetters(item),
    message: "Es necesario un rol válido",
  },
};
