import React, {useState} from 'react'

import { Tabs, Tab } from 'react-bootstrap'; 
import { SubscriptionsCreate } from './SubscriptionsCreate';
import { SubscriptionsFormTab } from './SubscriptionsFormTab';
const swatchStatus = Object.freeze({
  unassigned_user: 'no swatch',
  assigned_user: 'swatch user',
})

export const SubscriptionsForm = ({setConfirmation}) => {
  
  const [key, setKey] = useState(swatchStatus.unassigned_user);
  return (
    <Tabs
        id="controlled-ot-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
    >
      <Tab 
        eventKey={swatchStatus.unassigned_user} 
        title={
            <SubscriptionsFormTab
              content={'Modificar estado Swatch'}
              iconClass={'fas fa-list'}
            />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
          <SubscriptionsCreate 
            setConfirmation={setConfirmation}
          />
        </div>
      </Tab>
      {/* <Tab 
        disabled
        eventKey={swatchStatus.assigned_user} 
        title={
          <SubscriptionsFormTab 
            content={'Usuarios Swatch'}
            iconClass={'fas fa-user-check'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
        </div>
      </Tab>
      <Tab 
        disabled
        eventKey="withdraw" 
        title={
          <SubscriptionsFormTab 
            content={'Confirmar retiro de OT'}
            iconClass={'fas fa-sign-out-alt'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
        </div>
      </Tab> */}
    </Tabs>
  )
}
