import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datatable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


import { TextField, ClearButton, ToRButton } from '../Datatables/styledComponents';
import { updateTaskState } from '../../actions/tasks/task-detail';

import { formatDateWithTime, unsetFormat } from '../../helpers/helpers.js';
import { PATHS } from '../../types/paths';
import '../../styles/NewDatatable.css'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <Tippy content='Filtro de palabras' placement='left'>
            <TextField 
                id="search" 
                type="text" 
                placeholder="Buscar..." 
                aria-label="Search Input" 
                value={filterText} 
                onChange={onFilter} 
            />
        </Tippy>

        <Tippy content='Borrar filtro' placement='top'>
            <ClearButton 
                type="button" 
                onClick={onClear}
            >
                <i className="fas fa-eraser"></i>
            </ClearButton>
        </Tippy>
    </>
);

export const TaskDatatable = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [filterText, setFilterText] = useState('');

    const { tasks } = useSelector(state => state.storage)
    
    let auxTasks;
    let filteredItems;

    const taskDerived = task => {
        if(task.status.name === 'Derivada' || task.derived_user || task.derived_group) {
            if(task.derived_user) {
                return task.derived_user.username
            } 
            if(task.derived_group) {
                return task.derived_group.name
            }
        } else {
            return '~'
        }
    }

    if ( tasks.length > 0 ) {
        auxTasks = tasks.map( task => {
            return {
                slug: task.id, 
                title: task.title,
                user: (task.user ? task.user.username : '~' ),
                priority: task.task_priority,
                startDate:formatDateWithTime(task.start_date) ,
                endDate:formatDateWithTime(task.end_date) ,
                //date: commentDate(task.created_at),
                urgency: task.weigth,
                status: task.status.name,
                category: task.task_type.name,
                company: (task.company ? task.company.name : '~' ),
                derived: taskDerived(task),
                is_taken: task.is_taken,
                agent:task.agent
            }
        })

        filteredItems = auxTasks.filter( item => (item.slug && (item.slug.toString()).includes(filterText))
                                            || (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.user && item.user.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.priority.name && item.priority.name.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.startDate && item.startDate.includes(filterText)) 
                                            || (item.endDate && item.endDate.includes(filterText)) 
                                            || (item.derived && item.derived.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.category && item.category.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.company && item.company.toLowerCase().includes(filterText.toLowerCase())) 
                                            || (item.derived && item.derived.toLowerCase().includes(filterText.toLowerCase())) 
        );
    }


    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);


    const indexUpdate = async(e,slug) => {
        e.preventDefault();
        const name = e.currentTarget.attributes.auxname.value;
        const value = e.currentTarget.attributes.auxvalue.value;

        const fd = new FormData();
        fd.append('action', 'edit');
        
        if(name === 'priority' && (parseInt(value) > 0 && parseInt(value) < 4)) {
            fd.append(`${name}`, value);
        } else if(name === 'urgency' && (parseInt(value) > 0 && parseInt(value) < 11)) {
            fd.append(`${name}`, value);
        } else {
            return alert('No se puede realizar ese tipo de acción')
        }
        await dispatch(updateTaskState(slug, fd));
        window.location.reload();
    }

    const cols = [
        {   
            grow: 1,
            name:'#',
            selector:'slug',
            sortable: true
        },
        { 
            grow: 2, 
            name:'Título',
            selector:'title',
            sortFunction: (a,b) => {
                if(a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                if(a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                return 0;
            },
            sortable: true,
        },
        { 
            grow: 1, 
            name:'Creado por',
            selector:'user',
            sortFunction: (a,b) => {
                if(a.user.toLowerCase() > b.user.toLowerCase()) return 1;
                if(a.user.toLowerCase() < b.user.toLowerCase()) return -1;
                return 0;
            },
            sortable: true
        },
        { 
            grow: 1, 
            name:'Empresa',
            selector:'company',
            sortable: true,
            sortFunction: (a,b) => {
                if(a.company.toLowerCase() > b.company.toLowerCase()) return 1;
                if(a.company.toLowerCase() < b.company.toLowerCase()) return -1;
                return 0;
            },
        },
        { 
            grow: 1, 
            name:'Prioridad',
            selector:'priority',
            sortable:true,
            sortFunction: (a,b) => {
                return parseInt(b.priority.id) - parseInt(a.priority.id)
            },
            cell : row => 
                <div className='d-flex align-items-center justify-content-between index-update'>
                {
                    (row.priority.id > 0 && row.priority.id < 3) 
                    ?  
                    <Tippy content='Aumentar prioridad' placement='top'>
                        <i 
                            onClick={ e => indexUpdate(e , row.slug) } 
                            auxvalue={row.priority.id + 1} 
                            auxname='priority'
                            className={"fas fa-arrow-up index-action-btn " + (row.is_taken ? 'taken-task' : '') }
                        />
                    </Tippy>

                    :<div style={{width:'10px'}}></div>
                }
                

                <small> {row.priority.name} </small>

                {
                    (row.priority.id > 1 && row.priority.id < 4) 
                    ? <Tippy content='Disminuir prioridad' placement='top'> 
                        <i 
                            onClick={ e => indexUpdate(e , row.slug) } 
                            auxvalue={row.priority.id - 1} 
                            auxname='priority'
                            className={"fas fa-arrow-down index-action-btn " + (row.is_taken ? 'taken-task' : '') }
                        />
                    </Tippy> 

                    :<div style={{width:'10px'}}></div>
                }
                
            </div>
        },
        { 
            grow: 1, 
            name:'Urgencia',
            selector:'urgency',
            sortable:true,
            sortFunction: (a,b) => {
                return parseInt(b.urgency) - parseInt(a.urgency)
            },
            cell : row => <div className='d-flex row align-items-center justify-content-between index-update'>
                {
                    (row.urgency > 0 && row.urgency < 10) 
                    ?  <Tippy content='Aumentar urgencia' placement='top'>
                            <i 
                                onClick={ e => indexUpdate(e , row.slug) } 
                                auxvalue={row.urgency + 1} 
                                auxname='urgency'
                                className={"fas fa-arrow-up index-action-btn " + (row.is_taken ? 'taken-task' : '') }
                            />
                        </Tippy>
                    :<div style={{width:'10px'}}></div>
                }

                <small> {row.urgency} </small>

                {
                    (row.urgency > 1 && row.urgency < 11) 
                    ?  <Tippy content='Disminuir urgencia' placement='top'>
                            <i 
                                onClick={ e => indexUpdate(e , row.slug) } 
                                auxvalue={row.urgency - 1} 
                                auxname='urgency'
                                className={ "fas fa-arrow-down index-action-btn " + (row.is_taken ? 'taken-task' : '') }
                            />
                        </Tippy>
                    :<div style={{width:'10px'}}></div>
                }


            </div>
        },
        { 
            grow: 1, 
            name:'Categoría',
            selector:'category',
            sortable: true
        },
        { 
            grow: 1, 
            name:'Inicio',
            selector:'startDate',
            sortable: true,
            sortFunction: (a,b) => {
                return unsetFormat(b.startDate) - unsetFormat(a.startDate)
            },
            cell: row => <small className='task-start-date'>{row.startDate}</small>
        },
        { 
            grow: 1, 
            name:'Fin',
            selector:'endDate',
            sortable: true,
            sortFunction: (a,b) => {
                return unsetFormat(b.endDate) - unsetFormat(a.endDate)
            },
            cell: row => <small className='task-start-date'>{row.endDate}</small>
        },
        { 
            grow: 1, 
            name:'Derivado',
            selector:'derived',
            sortable: true,
            sortFunction: (a,b) => {
                if(a.derived.toLowerCase() > b.derived.toLowerCase()) return 1;
                if(a.derived.toLowerCase() < b.derived.toLowerCase()) return -1;
                return 0;
            },
        },
        { 
            grow: 1, 
            name:'Estado',
            selector:'status',
            sortable:true,
            cell: row => <div className='d-flex flex-column align-items-center justify-content-center task-status'>
                            <p className='p-0 m-0'>{row.status}</p>
                            {row.agent && <p className='p-0 m-0'> <i class="fas fa-user-lock"></i> {row.agent.username}</p>} 
                        </div>
        },{ 
            grow: 1,
            name:'Opciones',
            cell: row => 
            <>
                <Tippy content='Abrir en nueva pestaña' placement='top'>
                    <button
                        className='detail-new-tab'
                        onClick={e => {
                            e.preventDefault();
                            window.open(`/panel/task/${ row.slug }/detail`);
                        }}
                    >
                        <i className="fas fa-external-link-alt"></i>
                    </button>
                </Tippy>
            {
                !( window.location.pathname.includes('finished') 
                    || window.location.pathname.includes('aproved')) 
                && <Tippy content='Tomar/Soltar' placement='top'> 
                        <ToRButton 
                            value={ (row.is_taken) ? 'release' : 'take' } 
                            className={ (row.is_taken ? 'release-submit': 'take-submit') + ' testtor' } 
                            type='button' 
                            onClick={ async(e) => {
                                e.preventDefault();

                                const fd = new FormData(); 
                                fd.append('comment', '');
                                fd.append('action', row.is_taken ? 'release' : 'take');
                                
                                await dispatch(updateTaskState(row.slug, fd ));
                                history.push(PATHS.generalTasks);
                            }}
                        > 
                            {
                                row.is_taken 
                                ? <i className="far fa-hand-rock testtor"></i> 
                                : <i className="far fa-hand-paper testtor"></i> 
                            }
                        </ToRButton>
                </Tippy>
            }
            </>,
            button:true,
        },
    ]

/**
 * if( window.location.pathname.includes('tasks/derived') ){
        cols.splice(7,0, 
        { 
            grow: 1, 
            name:'Usuario/Grupo',
            selector:'derived',
            sortable: true
        }, )
    }
 */

    const conditionalRowStyles = [
        {
            when: row => row.urgency === 10,
            style: {
                backgroundColor: 'rgb(255, 71, 71)',
                color:'white',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'red' ,
                }
            }
        },
        {
            when: row => row.is_taken,
            style: {
                backgroundColor: 'yellow',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'rgb(255, 230, 0)' ,
                }
            }
        },
    ];

    const pagOpts = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItems: true,
        selectAllRowsItemText:'Todos'
    };

    const customStyles = {
        rows:{
            style: {
                fontSize:'.7rem',
                cursor:'pointer',
                '&:hover': {
                    background: 'rgba(114, 107, 121, 0.2)'
                }
            }
        }
    }
    //console.log('tareas', tasks)
    
    return (
        <>
            <div className='seccion-tabla' id={window.location.pathname.includes('custom/search') ? 'tarea-tabla-busqueda': undefined}>
                <div className='seccion-tabla-interno' id={window.location.pathname.includes('custom/search') ? 'tarea-tabla-busqueda-in': undefined}>
                <Datatable 
                
                    columns={cols} 
                    data={filteredItems} 
                    pagination
                    paginationComponentOptions={pagOpts}
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader={window.location.pathname.includes('custom/search') ? false : true}
                    subHeaderWrap={true}
                    subHeaderComponent={ subHeaderComponentMemo }
                    fixedHeaderScrollHeight='70px'
                    persistTableHead
                    onRowClicked={ row => {
                        return history.push(`/panel/task/${ row.slug }/detail`)
                    } }
                    customStyles={customStyles}
                    noDataComponent='No hay registros'
                    conditionalRowStyles={conditionalRowStyles}
                />
                </div>
            </div>
        </>
    )
}
