import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getEditOtInfo,
	getOtInfo,
	updateOtInfo,
} from '../../../actions/swatch-users/get-swatch-user'
import { hasLetters } from 'helpers/number'

export const ModifyOTAdminForm = () => {
	const dispatch = useDispatch()

	const {
		tickets,
		ot: { states, destinations, detail },
	} = useSelector((state) => state.storage)

	const modifyOtState = {
		ticket_id: '',
		ot_status: '',
		rma_id: '',
		enable_destination: false,
		destination: '',
		enable_budget: false,
		budget: '',
	}
	const [error, setError] = useState({ status: false, message: '' })
	const [modifyOt, setModifyOt] = useState(modifyOtState)

	const {
		ticket_id,
		ot_status,
		rma_id,
		destination,
		enable_destination,
		enable_budget,
		budget,
	} = modifyOt

	const handleChange = (e) => {
		setModifyOt({
			...modifyOt,
			[e.target.name]: e.target.value,
		})
	}
	const handleCheckboxChange = (e) => {
		setModifyOt({
			...modifyOt,
			[e.target.name]: e.target.checked,
		})
	}

	const getOtInfoBySlug = () => {
		const otSlug = parseInt(rma_id)
		if (typeof otSlug !== 'number' || hasLetters(rma_id)) {
			return setError({
				status: true,
				message: 'Es necesario ingresar un Slug válido',
			})
		}

		dispatch(getOtInfo(otSlug))
	}

	const handleSubmit = () => {
		if (!ticket_id || !ot_status || !rma_id) {
			return setError({
				status: true,
				message: 'Es necesario completar los campos mínimos requeridos',
			})
		}

		// TICKET ID
		let ticketId = parseInt(ticket_id)
		if (ticket_id.includes('-')) {
			ticketId = parseInt(ticket_id.split('-')[0])
		}
		if (typeof ticketId !== 'number') {
			return setError({
				status: true,
				message: 'Es necesario ingresar un ID de Ticket válido',
			})
		}

		// OT STATUS
		const otStatus = states.filter((state) => state.name === ot_status)
		if (!otStatus.length) {
			return setError({
				status: true,
				message: 'Es necesario ingresar un Estado de OT válido',
			})
		}

		// OT SLUG
		const otSlug = parseInt(rma_id)
		if (typeof otSlug !== 'number' || hasLetters(rma_id)) {
			return setError({
				status: true,
				message: 'Es necesario ingresar un Slug válido',
			})
		}

		// DESTINATIONS
		const newDestination = destinations.filter(
			(dest) => dest.username === destination
		)
		if (enable_destination && newDestination.length < 1) {
			return setError({
				status: true,
				message: 'Verificar nuevo destino',
			})
		}

		// BUDGET
		if ((enable_budget && budget.length < 1) || hasLetters(budget)) {
			return setError({
				status: true,
				message: 'Verificar nuevo presupuesto',
			})
		}

		setError({ status: false, message: '' })
		const data = {
			ticket_id: ticketId,
			ot_status: otStatus[0].id,
			rma_id: otSlug,
		}

		if (enable_destination && newDestination[0]) {
			data.destination = newDestination[0].id
			data.enable_destination = enable_destination
		}

		if (enable_budget && budget) {
			data.budget = parseInt(budget)
			data.enable_budget = enable_budget
		}

		dispatch(updateOtInfo(data))
		setModifyOt(modifyOtState)
		dispatch(getEditOtInfo())
	}

	useEffect(() => {
		dispatch(getEditOtInfo())
	}, [dispatch])
	return (
		<form className='swatch_user_status_change_form'>
			<div className='swatch_user_status_change_top'>
				<h6>Cambio de parámetros de OT</h6>
				{error.status ? (
					<span className='text-danger'>{error.message}</span>
				) : (
					<span className='swatch_user_status_change_top_info'>
						Verifique y cambie los parámetros de la OT
					</span>
				)}
			</div>
			<div className='swatch_user_status_change_body'>
				<div className='swatch_user_status_change_list'>
					<label htmlFor='swatch_user_status_change_input_list'>
						<small>Lista de Tickets abiertos</small>
					</label>
					<input
						id='swatch_user_status_change_input_user_list'
						list='open_ticket_list'
						placeholder='Ticket abierto'
						value={ticket_id}
						name='ticket_id'
						onChange={handleChange}
					/>
					<datalist id='open_ticket_list'>
						{tickets?.map(({ slug, title }) => {
							const defString = `${slug}-${title}`
							return (
								<option value={defString} key={defString}>
									{defString}
								</option>
							)
						})}
					</datalist>
				</div>

				<div className='swatch_user_status_change_list'>
					<label htmlFor='swatch_user_status_change_input_list'>
						<small>Estados</small>
					</label>
					<input
						id='swatch_user_status_change_input_user_list'
						list='ot_status_list'
						placeholder='Estados de OT'
						value={ot_status}
						name='ot_status'
						onChange={handleChange}
					/>
					<datalist id='ot_status_list'>
						{states?.map(({ name }) => (
							<option value={name} key={name}>
								{name}
							</option>
						))}
					</datalist>
				</div>

				<div className='swatch_user_status_change_list complex'>
					<label htmlFor='swatch_user_status_change_input_type_list'>
						<small>Slug</small>
					</label>
					<input
						type='text'
						id='swatch_user_status_change_input_type_list'
						placeholder='Número de OT'
						value={rma_id}
						name='rma_id'
						onChange={handleChange}
					/>
					<button type='button' onClick={getOtInfoBySlug}>
						<i className='fas fa-search'></i>
					</button>
					{detail?.state && detail?.destination && (
						<div className='ot_search_info'>
							<span>
								<strong>Destino</strong>: {detail?.destination}
							</span>
							<span>
								<strong>Estado</strong>: {detail?.state}
							</span>
							<span>
								<strong>Tiene presupuesto</strong>:
								{detail?.budget?.status ? 'Si' : 'No'}
							</span>
							{detail?.budget?.status && (
								<span>
									<strong>Presupuesto</strong>: ${detail?.budget?.amount}
								</span>
							)}
						</div>
					)}
				</div>

				<div className='swatch_user_status_change_list complex'>
					<label htmlFor='swatch_user_status_change_input_list'>
						<small>Destinos</small>
					</label>

					<input
						type='checkbox'
						id='swatch_user_status_change_input_enable_destination'
						name='enable_destination'
						onChange={handleCheckboxChange}
					/>
					<input
						autoComplete='off'
						disabled={!enable_destination}
						data-disabled={!enable_destination}
						type='text'
						id='swatch_user_status_change_input_user_list'
						list='ot_destinations_list'
						placeholder='Destinos disponibles'
						value={destination}
						name='destination'
						onChange={handleChange}
					/>
					<datalist id='ot_destinations_list'>
						{destinations?.map(({ username }) => (
							<option value={username} key={username}>
								{username}
							</option>
						))}
					</datalist>
				</div>

				<div className='swatch_user_status_change_list complex'>
					<label htmlFor='swatch_user_status_change_input_list'>
						<small>Presupuesto</small>
					</label>

					<input
						type='checkbox'
						id='swatch_user_status_change_input_enable_budget'
						name='enable_budget'
						onChange={handleCheckboxChange}
					/>
					<input
						autoComplete='off'
						disabled={!enable_budget}
						data-disabled={!enable_budget}
						type='text'
						id='swatch_user_status_change_input_user_list'
						placeholder='Cambio de presupuesto'
						value={budget}
						name='budget'
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className='swatch_user_status_change_bottom'>
				<button type='button' onClick={handleSubmit}>
					<span>
						<i className='fas fa-sync'></i>
						Modificar parámetros
					</span>
				</button>
			</div>
		</form>
	)
}
