import { deleteInfoHeader } from '../actions/header'
import { deleteContent, getTicketCount } from '../actions/tickets'
import { deleteDetail } from '../actions/ticket-detail'
import { deleteCreateInfo } from '../actions/ticket-create'
import { deleteChartInfo } from '../actions/charts/general-charts'

export const cleanTheStorage = (count = true) => {
	return (dispatch) => {
		dispatch(deleteContent())
		dispatch(deleteInfoHeader())
		dispatch(deleteDetail())
		dispatch(deleteCreateInfo())
		dispatch(deleteChartInfo())
		if (count) {
			dispatch(getTicketCount())
		}
	}
}
