import React, { useState } from 'react'

import { Tabs, Tab } from 'react-bootstrap'; /* 
import { OTReportsForm } from '../OTForms/OTReportsForm';
import { OTSearchForm } from '../OTForms/OTSearchForm';
import { OTWithdrawalForm } from '../OTForms/OTWithdrawalForm'; */
import { ModifyOTAdminForm } from './ModifyOTAdminForm';
import { SwatchUserFormTab } from './SwatchUserFormTab';
import { SwatchUserStatusChangeForm } from './SwatchUserStatusChangeForm';

const swatchStatus = Object.freeze({
  unassigned_user: 'no swatch',
  assigned_user: 'swatch user',
  modify_ot: 'swatch ot modify'
})

export const SwatchUserForm = () => {

  const [key, setKey] = useState(swatchStatus.unassigned_user);
  return (
    <Tabs
      id="controlled-ot-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab
        eventKey={swatchStatus.unassigned_user}
        title={
          <SwatchUserFormTab
            content={'Modificar estado Swatch'}
            iconClass={'fas fa-user-plus'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
          <SwatchUserStatusChangeForm />
        </div>
      </Tab>
      <Tab
        disabled
        eventKey={swatchStatus.assigned_user}
        title={
          <SwatchUserFormTab
            content={'Usuarios Swatch'}
            iconClass={'fas fa-user-check'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
          {/* <OTReportsForm /> */}
        </div>
      </Tab>
      <Tab
        eventKey={swatchStatus.modify_ot}
        title={
          <SwatchUserFormTab
            content={'Cambio de parámetros de OT'}
            iconClass={'fas fa-sync'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
          <ModifyOTAdminForm />
        </div>
      </Tab>
      {/* <Tab 
        disabled
        eventKey="withdraw" 
        title={
          <SwatchUserFormTab 
            content={'Confirmar retiro de OT'}
            iconClass={'fas fa-sign-out-alt'}
          />
        }
      >
        <div className='panel_cliente_crear_ticket ot'>
        </div>
      </Tab> */}
    </Tabs>
  )
}
