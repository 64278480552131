import React from 'react'
import { ChartColorLegend } from '../ChartColorLegend'
//Primera fila
import { AssignedTicketChart } from './Divisions/First row/AssignedTicketChart'
import { GroupTicketChart } from './Divisions/First row/GroupTicketChart'
import { UnassignedTicketChart } from './Divisions/First row/UnassignedTicketChart'

//Segunda fila
import { DailyClosedTicketsChart } from './Divisions/Second row/DailyClosedTicketsChart'
import { MonthlyClosedTicketsChart } from './Divisions/Second row/MonthlyClosedTicketsChart'

//Tercera fila
import { TicketBarChart } from './BarChart/TicketBarChart'

export const TicketChart = () => {
    return (
        <div
            className='general_charts_division' 
        >
            <p>Tickets</p>

            <div className='general_charts_pie_n_board'>
                <div //Primera fila
                    className='general_charts_tickets'
                >
                    <small>Abiertos</small>

                    <div className='general_charts_no_legend'>
                        <AssignedTicketChart />
                        <div
                            className='general_charts_side'
                        >
                            <GroupTicketChart />
                            <UnassignedTicketChart />
                        </div>
                    </div>
                    <ChartColorLegend total={true} />
                </div>
                <div className='general_charts_boards'>
                    <div className='general_charts_daily'>
                        <DailyClosedTicketsChart />
                    </div>
                    <div className='general_charts_monthly'>
                        <MonthlyClosedTicketsChart />
                    </div>
                </div>
            </div>


            <div //Tercera fila
                className='general_charts_bar'
            >
                <small>Rango general semanal</small>
                <TicketBarChart />
                {/**<ChartColorLegend /> */}
            </div>
        </div>
                    
    )
}
