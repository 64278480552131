import React from 'react';
import { useSelector } from 'react-redux';
import { 
    PieChart, 
    Pie, 
    Cell, 
    Tooltip, 
    ResponsiveContainer 
} from "recharts";
import { noData } from '../../../../../helpers/chart_data';

import { bg, COLORS } from '../../../colors';

export const UnassignedTaskChart = () => {
    const { unassigned:tasks } = useSelector(state => state.charts.tasks);
    let data = [], data2 = [];
    if(tasks) {
        const {
            high,
            medium,
            low
        } = tasks;

        data = [
            { name: "Alta", value: high },
            { name: "Media", value: medium },
            { name: "Baja", value: low }
        ];
        data2 = [
            { name: "Total", value: parseInt(high + medium + low) }
        ];
    }
    return (<div
        className='general_charts_small_pie'
    >
        <ResponsiveContainer
            width='50%'
            height='100%'
        >
            <PieChart>
                {(data2[0] && data2[0].value > 0) && <Tooltip />}
                <Pie
                    data={data}
                    cx='50%'
                    cy='45%'
                    innerRadius={20}
                    outerRadius={35}
                    fill="#8884d8"
                    dataKey="value"
                    labelLine={false}
                    stroke=''
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Pie>
                <Pie 
                    data={noData(data2) ? [{name:'Total',value:1}]: data2} 
                    dataKey="value" 
                    cx="50%" 
                    cy="45%" 
                    outerRadius={noData(data2) ? 35 : 15}
                    fill={bg}
                    stroke='' 
                />
            </PieChart>
        </ResponsiveContainer>
        <small><b>{data2[0] && data2[0].value}</b> sin asignar</small>
    </div>
    )
}
