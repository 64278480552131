import React, { useState } from 'react';
import DatePicker,{ registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css"; //Estilos desde react-datepicker

//Importación de idiomas para traducción de texto dentro del datepicker
import es from 'date-fns/locale/es';
import { commentDate } from '../../helpers/helpers';
registerLocale('es', es)

export const Fecha = ({handleChange, formValue }) => {
    //console.log(`error`, error)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const selectStartDate = e => {
        //console.log('start',commentDate(e));
        setStartDate(e);
        handleChange({
            target:{
                name:'due_date',
                value:commentDate(e)
            }
        })
    }

    const selectEndDate = e => { 
        //console.log(e);
        setEndDate(e);
        handleChange({
            target:{
                name:'end_date',
                value:commentDate(e),
            }
        })
    }

    return (<div className='d-flex justify-content-left for-task-detail'>
        <div className='task-create-from'>
            <label htmlFor='inputSelectFrom'>Inicio</label>
            <DatePicker
                selected={startDate}
                onChange={selectStartDate}
                startDate={startDate}
                placeholderText="Introduzca una fecha de inicio"
                closeOnScroll={true} 
                dateFormat="dd/MM/yyyy h:mm aa"
                locale='es'
                selectsStart
                showWeekNumbers
                endDate={endDate}
                calendarClassName="zIndex"
                className={`inputWidth`}
                showTimeInput
                shouldCloseOnSelect={false}
            />
        </div>

        <div className='task-create-till'>
            <label htmlFor='inputSelectTill'>Hasta</label>
            <DatePicker
                selected={endDate}
                onChange={selectEndDate}
                startDate={startDate}
                placeholderText="Introduzca una fecha fin"
                closeOnScroll={true}
                dateFormat="dd/MM/yyyy h:mm aa"
                locale='es'
                selectsEnd
                showWeekNumbers
                endDate={endDate}
                minDate={startDate}
                calendarClassName="zIndex"
                className={`inputWidth`}
                showTimeInput
                shouldCloseOnSelect={false}
            />
        </div>
        
    </div>)
}
