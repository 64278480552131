import React from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateWithTime } from '../../helpers/helpers';
import { Loader } from '../Loader/Loader';


export const SwatchUserDatatable = ({filterText}) => {
  let auxUsers, filteredItems;

  const history = useHistory();
  const {users} = useSelector(state => state.users_list);
  const { loading } = useSelector(state => state.ui)
  
  if ( users.length > 0 ) {
    auxUsers = users.map( user => {
      return {
        _id: user.id,
        username: user.username,
        email: user.email,
        //Tipo
        swatch_user_type: (user.swatch_user && user.swatch_user.swatch_user_type) 
            ? user.swatch_user.swatch_user_type.name 
            : 'No es usuario Swatch',
        //Estado activo
        active: (user.swatch_user 
            && user.swatch_user.active 
            && parseInt(user.swatch_user.active) === 1)
                ? 'Activo'
                : 'Inhabilitado',
        //Es destino
        is_destination: (user.swatch_user 
            && user.swatch_user.is_destination 
            && parseInt(user.swatch_user.is_destination) === 1) 
                ? 'Es destino' 
                : 'No es destino',
        //Mismo destino
        same_destination: (user.swatch_user 
            && user.swatch_user.same_destination
            && parseInt(user.swatch_user.same_destination) === 1)
            ? 'Mismo destino' 
            : 'No es mismo destino',
        //Crea vouchers
        enable_voucher: (user.swatch_user 
            && user.swatch_user.enable_voucher
            && parseInt(user.swatch_user.enable_voucher) === 1)
            ? 'Crea vouchers' 
            : 'No crea vouchers',
      }
    })

    filteredItems = auxUsers.filter(user => 
      (user.username && user.username.toLowerCase().includes(filterText.toLowerCase())) 
      || (user.email && user.email.toLowerCase().includes(filterText.toLowerCase())) 
      || (user.swatch_user_type && user.swatch_user_type.toLowerCase().includes(filterText.toLowerCase())) 
    );

  }

  const cols = [
    {   
      grow: 1,
      name:'Usuario',
      selector:'username',
      sortable: true
    },
    { 
      grow: 1, 
      name:'Correo',
      selector:'email',
      sortable: true,
    },
    { 
      grow: 2, 
      name:'Tipo Usuario Swatch',
      selector:'swatch_user_type',
      sortable: true
    },
    { 
      grow: 1, 
      name:'Es destino',
      selector:'is_destination',
      sortable: true
    },
    { 
      grow: 1, 
      name:'Mismo destino',
      selector:'same_destination',
      sortable: true
    },
    { 
      grow: 1, 
      name:'Crea vouchers',
      selector:'enable_voucher',
      sortable: true
    },/* 
    { 
      grow: 1, 
      name:'Modificado',
      selector:'date',
      sortable: true,
      cell: row => <small className='ticket-date'>{row.date}</small>
    },
    { 
      grow: 1, 
      name:'Estado',
      selector:'status',
      cell: row => <div className='d-flex flex-column align-items-center justify-content-center ticket-status'>
                      <p className='p-0 m-0'>{row.status}</p>
                      {row.agent && <p className='p-0 m-0'> <i className="fas fa-user-lock"></i> {row.agent.username}</p>} 
                  </div>
    }, */
    { 
      grow: 1, 
      name:'Estado',
      selector:'active',
      sortable: true
    },
  ]
/* 
    const conditionalRowStyles = [
        {
            when: row => row.priority === 'Emergencia',
            style: {
                backgroundColor: 'rgb(255, 71, 71)',
                color:'white',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'red' ,
                }
            }
        },
        {
            when: row => row.is_taken,
            style: {
                backgroundColor: 'yellow',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'rgb(255, 230, 0)' ,
                }
            }
        },
    ]; */

    const pagOpts = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItems: true,
        selectAllRowsItemText:'Todos'
    };

    const customStyles = {
        rows:{
            style: {
                fontSize:'.7rem',
                cursor:'pointer',
                '&:hover': {
                    background: 'rgba(114, 107, 121, 0.2)'
                }
            }
        }
    }

    return (
        <DataTable
            columns={cols} 
            data={filteredItems} 
            pagination={(filteredItems && filteredItems.length < 10) ? false : true}
            paginationComponentOptions={pagOpts}
            onRowClicked={ row => {
                return history.push(`/panel/config/${ row._id }/detail`)
            } }
            customStyles={customStyles}
            noDataComponent='No hay registros'
            /* conditionalRowStyles={conditionalRowStyles} */
            progressPending={loading}
            progressComponent={<Loader />}
        />
    )
}
