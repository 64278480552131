import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'/* 
import { sendProfileInfo } from '../actions/user/user-profile'; */
import { getSwatchUserDetail, updateSwatchUser } from '../../../actions/swatch-users/get-swatch-user';
import { SwatchUserDeleteModal } from './SwatchUserDeleteModal';

export const SwatchUserDetail = () => {
  const {_id} = useParams();
  const {detail:user, roles} = useSelector(state => state.users_list);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState({status:false,info:null});

  const [info, setInfo] = useState({
    name:'',
    lastname:'',
    email:'',
    phone:'',
    cellphone:'',
    extension:'',
    companies:[],
    username:'',
    role:'',
    is_destination:false,
    same_destination:false,
    enable_voucher:false,
    active:false,
  });

  const [error, setError] = useState({
    status:false,
    message:''
  });

  const handleChange = e => {
    setInfo({
      ...info,
      [e.target.name] : e.target.value
    });
  }

  const handleDeleteModal = e => {
    e.preventDefault();
    setModalShow({status:true,info:null})
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const aux = Object.entries(info).filter( value => !(value[0] === 'companies' 
    || value[0] === 'username'
    || value[0] === 'role'
    || value[0] === 'lastname'
    || value[0] === 'cellphone'
    || value[0] === 'extension'
    || value[0] === 'active'
    ) && value[1] );
    if(aux.length < 3) {
        return setError({
            status:true,
            message: 'Algunos datos son obligatorios'
        })
    }

    const fd = new FormData();
    aux.map(value => fd.append(`${value[0]}`, value[1]));
    fd.append('active',info.active ? 1 : 0)
    
    const auxRole = roles.filter(role => role.name === info.role)
    if(!auxRole[0]) {
        return setError({
            status:true,
            message: 'El rol de usuario swatch ingresao es incorrecto'
        })
    }

    if(info.is_destination) {
        fd.append('is_destination',1)
    }
    if(info.same_destination) {
        fd.append('same_destination',1)
    }
    if(info.enable_voucher) {
        fd.append('enable_voucher',1)
    }

    if(parseInt(info.cellphone)) {
        fd.append('cellphone',info.cellphone)
    } else {
        fd.append('cellphone',"")
    }
    if(parseInt(info.extension)) {
        fd.append('extension',info.extension)
    } else {
        fd.append('extension',"")
    }
    
    if(info.lastname && info.lastname.length > 0) {
        fd.append('lastname',info.lastname)
    } else {
        fd.append('lastname',"")
    }
    fd.append('role',auxRole[0].id)

    setError({status:false, message: ''})
    console.log(aux)
    await dispatch(updateSwatchUser(fd,user.id))
    return dispatch(getSwatchUserDetail(_id))
  }
  
  const handleCheckboxChange = e => {
    setInfo({
      ...info,
      [e.target.name]:e.target.checked
    })
  }

  useEffect(() => {
    if(user && user.swatch_user && user.companies && (user.swatch_user && user.swatch_user.swatch_user_type)){
      setInfo({
        username:user.username,
        companies:[...user.companies],
        role:(user && user.swatch_user && user.swatch_user.swatch_user_type) && user.swatch_user.swatch_user_type.name,
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        cellphone: user.cellphone,
        extension: user.extension,
        is_destination:(user 
            && user.swatch_user 
            && user.swatch_user.is_destination 
            && user.swatch_user.is_destination === 1) ? true : false ,
        same_destination:(user 
            && user.swatch_user 
            && user.swatch_user.same_destination 
            && user.swatch_user.same_destination === 1) ? true : false ,
        enable_voucher:(user 
            && user.swatch_user 
            && user.swatch_user.enable_voucher 
            && user.swatch_user.enable_voucher === 1) ? true : false ,
        active:(user 
            && user.swatch_user 
            && user.swatch_user.active 
            && user.swatch_user.active === 1) ? true : false,
      })
    }
  }, [user]);

  useEffect(() => {
    dispatch(getSwatchUserDetail(_id))
  },[dispatch,_id])

  return (
        <div className='seccion-tabla profile-special-margin'>
            <div className='seccion-tabla-interno'>
                <form className='profile-form'>
                    
                { 
                            error.status 
                            && <p style={{
                                color:'red', 
                                display:'flex', 
                                width:'80%', 
                                margin:'0 auto',
                                justifyContent:'center',
                                padding:'1rem',
                                background:'rgba(255, 0, 0, 0.3)',
                                border:'1px solid red',
                                borderRadius:'.3rem'
                            }}>
                                { error.message }
                            </p> 
                        }

                    <div className='profile-div'>
                        <div className='profile-div-part-a'>
                            <div className='profile-full-name'>
                                <div className='profile-name'>
                                    <label className="" htmlFor="profile-name">Nombre</label>
                                    <input 
                                        type='text' 
                                        id='profile-name' 
                                        placeholder='Nombre del usuario' 
                                        value={ info.name }
                                        name='name'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='profile-lastname'>
                                    <label className="" htmlFor="profile-lastname">Apellido</label>
                                    <input 
                                    type='text' 
                                    id='profile-lastname' 
                                    placeholder='Apellido del usuario' 
                                    value={ info.lastname }
                                    name='lastname'
                                    onChange={handleChange}
                                />
                                </div>
                            </div>
                            <div className='profile-email'>
                                <label className="" htmlFor="profile-email">E-mail</label>
                                <input 
                                    type='email' 
                                    id='profile-email' 
                                    placeholder='Correo electrónico' 
                                    value={ info.email }
                                    name='email'
                                    onChange={handleChange}
                                />
                            </div>
                            <hr />
                            <div className='profile-telephone'>
                                <label className="" htmlFor="profile-telephone">Teléfono</label>
                                <input 
                                    type='text' 
                                    id='profile-telephone' 
                                    placeholder='Ej: 15 1111 1111' 
                                    value={ info.phone }
                                    name='phone'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='profile-cellphone'>
                                <label className="" htmlFor="profile-cellphone">Celular</label>
                                <input 
                                    type='text' 
                                    id='profile-cellphone' 
                                    placeholder='Celular del usuario' 
                                    value={ info.cellphone ? info.cellphone : '' }
                                    name='cellphone'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='profile-interno'>
                                <label className="" htmlFor="profile-interno">Interno</label>
                                <input 
                                    type='text' 
                                    id='profile-interno' 
                                    placeholder='Ej: 100' 
                                    value={ info.extension ? info.extension : '' }
                                    name='extension'
                                    onChange={handleChange}
                                />
                            </div>

                        </div>
                        <div className='profile-div-part-b'>

                            <div className='profile-user'>
                                <label className="" htmlFor="profile-user">Usuario</label>
                                <input 
                                    readOnly
                                    id='profile-user' 
                                    placeholder='Usuario' 
                                    value={ info.username }
                                />
                            </div>
                            <div className='profile-company'>
                                <label className="" htmlFor="profile-company">Empresa</label>
                                <input 
                                    readOnly
                                    id='profile-company' 
                                    placeholder='Empresa' 
                                    value={ (info.companies) && info.companies.map( company => ` ${company.name}`) }
                                />
                            </div>
                            
                            <div className='swatch-user-profile-detail'>
                                <div className='profile-role'>
                                    <label className="" htmlFor="profile-role">Rol usuario Swatch</label>
                                    <input 
                                        /* readOnly */
                                        onChange={handleChange}
                                        name='role'
                                        id='profile-role' 
                                        placeholder='Rol del usuario' 
                                        value={info.role}
                                        list='profile-role-datalist'
                                    />
                                    <datalist
                                        id='profile-role-datalist'
                                    >
                                        {
                                            roles && roles.map(role => (
                                                <option
                                                    key={role.id}
                                                    value={role.name}
                                                >
                                                    {role.name}
                                                </option>
                                            ))
                                        }
                                    </datalist>
                                </div>
                                
                                <div className={`swatch_user_detail_is_destination ${(info.role !== 'Customer Service' && info.role !== 'Punto de venta') ? 'blocked' : ''}`}>
                                    { /* forceReset && */
                                    <input 
                                        disabled={(info.role !== 'Customer Service' && info.role !== 'Punto de venta') ? true : false}
                                        type='checkbox'
                                        id='swatch_user_detail_input_is_destination'
                                        name='is_destination'
                                        checked={info.is_destination}
                                        onChange={handleCheckboxChange}
                                    />
                                    }
                                    <label htmlFor='swatch_user_detail_input_is_destination'>
                                        <small>Es destino</small>
                                    </label>
                                </div>
                                
                                <div 
                                className={`swatch_user_detail_same_destination ${(info.role !== 'Customer Service' && info.role !== 'Punto de venta') ? 'blocked' : ''}`}>
                                    {
                                    /* forceReset &&  */
                                    <input 
                                        disabled={(info.role !== 'Customer Service' && info.role !== 'Punto de venta') ? true : false}
                                        type='checkbox'
                                        id='swatch_user_detail_input_same_destination'
                                        name='same_destination'
                                        checked={info.same_destination}
                                        onChange={handleCheckboxChange}
                                    />
                                    }
                                    <label htmlFor='swatch_user_detail_input_same_destination'>
                                        <small>Mismo destino</small>
                                    </label>
                                </div>
                                
                                <div className='swatch_user_detail_same_destination'>
                                    {
                                    /* forceReset &&  */
                                    <input 
                                        type='checkbox'
                                        id='swatch_user_detail_input_enable_voucher'
                                        name='enable_voucher'
                                        checked={info.enable_voucher}
                                        onChange={handleCheckboxChange}
                                    />
                                    }
                                    <label htmlFor='swatch_user_detail_input_enable_voucher'>
                                        <small>Crea vouchers</small>
                                    </label>
                                </div>
                                <div className='swatch_user_detail_same_destination'>
                                    {
                                    /* forceReset &&  */
                                    <input 
                                        type='checkbox'
                                        id='swatch_user_detail_input_active'
                                        name='active'
                                        checked={info.active}
                                        onChange={handleCheckboxChange}
                                    />
                                    }
                                    <label htmlFor='swatch_user_detail_input_active'>
                                        <small>Usuario activo</small>
                                    </label>
                                </div>
                                <div className='swatch_user_detail_delete_user'>
                                    <button
                                        onClick={handleDeleteModal}
                                    >
                                        Borrar usuario
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='profile-buttons'>
                        <button 
                            className='profile-button'
                            onClick={handleSubmit}
                        >
                            <i className="fas fa-user"></i> Modificar usuario
                        </button>
                        <Link to='/panel/config/swatch-users' className='profile-return'><i className="fas fa-undo-alt"></i> Volver</Link>
                    </div>
                </form>
            </div>
            
            <SwatchUserDeleteModal
                user={{username:info.username, _id}}
                show={modalShow}
                onHide={() => setModalShow({status:false,info:null})}
            />
        </div>
    )
}
