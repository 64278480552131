import { TYPES } from "../types/types";

const initialState = {
    subscriptions: [],
    groups:[]
}

export const subscriptionsReducer = ( state = initialState, action ) => {

    switch (action.type) {
        
        case TYPES.setSubscriptions:
            return {
                ...state,
                subscriptions: [...action.payload.subs],
                groups: [...action.payload.groups]
            };
        case TYPES.setTopics:
            return {
                ...state,
                topics: [...action.payload]
            };

        case TYPES.deleteSubscriptionsAndTopics:
            return { ...initialState }

        default:
            return state;
    }

}
