import React from 'react'
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PATHS } from '../types/paths'

import { Nav } from './NavBar/Nav'
import { PanelHeader } from './Panel General/PanelHeader'
import GeneralCharts from './Charts/GeneralCharts'

import { DetalleTicket } from './DetalleTicket'
import { TaskDetail } from './Tasks/TaskDetail'

import { NewDataTable } from './NewDataTable'
import { TaskDatatable } from './Tasks/TaskDatatable'

import { CrearTicket } from './CrearTicket'
import { TaskCreate } from './Tasks/TaskCreate'
import CreateUser from './Users/RegularUsers/CreateUsers'
import EditUsers from './Users/RegularUsers/EditUsers'

import { CustomSearch } from './CustomSearch/CustomSearch'
import { Perfil } from './Perfil'
import { isAdmin } from '../helpers/permission'
import { SwatchUserPanel } from './Users/SwatchUserPanel'
import { SwatchUserDetail } from './Users/SwatchUserDetail/SwatchUserDetail'
import { SubscriptionsIndex } from './Subscriptions/SubscriptionsIndex'
import { RegularUsersPanel } from './Users/RegularUsers/RegularUsersPanel'

const Panel = () => {
	const { loading } = useSelector((state) => state.ui)
	const { name: role } = useSelector((state) => state.auth.role)
	return (
		<Router>
			{loading && (
				<div
					style={{
						width: '200vw',
						height: '200vh',
						background: 'rgba(45, 130, 199,0.3)',
						zIndex: '500',
						position: 'absolute',
						top: 0,
						left: 0,
					}}
				></div>
			)}
			{loading && (
				<div
					style={{
						zIndex: '500',
						position: 'absolute',
						top: 0,
						left: 0,
					}}
					className='lds-ellipsis'
				>
					<div style={{ background: 'rgb(45, 130, 199)' }}></div>
					<div style={{ background: 'rgb(45, 130, 199)' }}></div>
					<div style={{ background: 'rgb(45, 130, 199)' }}></div>
					<div style={{ background: 'rgb(45, 130, 199)' }}></div>
				</div>
			)}
			<div className='panel-general'>
				<Nav />
				<PanelHeader />
				<Switch>
					<Route //RUTA PARA GRÁFICOS
						exact
						path={PATHS.panelCustomCharts}
						component={GeneralCharts}
					/>

					<Route exact path='/panel'>
						{<Redirect to={PATHS.panelCustomCharts} />}
					</Route>

					<Route //RUTA PARA CREAR TICKETS
						exact
						path={PATHS.createTickets}
						component={CrearTicket}
					/>
					<Route //RUTA PARA CREAR TAREAS
						exact
						path={PATHS.createTasks}
						component={TaskCreate}
					/>

					<Route //RUTA PARA DETALLES DE TICKETS
						path={PATHS.detailTickets}
						component={DetalleTicket}
					/>

					<Route //RUTA PARA DETALLES DE TAREAS
						path={PATHS.detailTasks}
						component={TaskDetail}
					/>

					<Route //RUTA PARA TABLAS DE TICKETS
						exact
						path={PATHS.templateTickets}
						component={NewDataTable}
					/>
					<Route //RUTA PARA TABLAS DE TAREAS
						exact
						path={PATHS.templateTasks}
						component={TaskDatatable}
					/>

					<Route //RUTA PARA CONFIGURACIONES DE PERFIL
						exact
						path={PATHS.panelConfigProfile}
						component={Perfil}
					/>

					<Route //RUTA PARA BÚSQUEDA AVANZADA
						exact
						path={PATHS.panelCustomSearch}
						component={CustomSearch}
					/>

					{isAdmin(role) && (
						<Switch>
							<Route //RUTA PARA USUARIOS SWATCH
								exact
								path={PATHS.panelConfigSwatchUsers}
								component={SwatchUserPanel}
							/>

							<Route //RUTA PARA DETALLE USUARIOS SWATCH
								exact
								path={PATHS.configSwatchUsersDetail}
								component={SwatchUserDetail}
							/>

							<Route //RUTA PARA ABONOS
								exact
								path={PATHS.topicsPanel}
								component={SubscriptionsIndex}
							/>

							<Route //RUTA PARA EDITAR USUARIOS
								exact
								path={PATHS.editUsers}
								component={EditUsers}
							/>
							<Route //RUTA PARA CREAR USUARIOS
								exact
								path={PATHS.createUsers}
								component={CreateUser}
							/>
							<Route //RUTA PARA USUARIOS
								exact
								path={PATHS.users}
								component={RegularUsersPanel}
							/>
						</Switch>
					)}

					<Redirect to={PATHS.panelCustomCharts} />
				</Switch>
			</div>
		</Router>
	)
}

export default Panel
