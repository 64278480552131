import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketState } from '../../actions/ticket-detail';
import { useForm } from '../../hooks/useForm';

export const DerivedTo = ({slug}) => {

    const dispatch = useDispatch();
    const { update_info } = useSelector(state => state.detail.ticket);
    const [error, setError] = useState({
        status:false,
        message:''
    });

    const [ list, setList ] = useState([]);

    const [ , handleInputChange, actualValue ] = useForm({
        action:'edit',
        priority:'',
        topic:'',
        derived:'',
        userOrGroup:'',
    });

    const handleUpdate = async(e) => {
        e.preventDefault();
        let valor = await actualValue();

        if ( !valor.priority && !valor.topic && !valor.derived && !valor.userOrGroup ) {

            setError({
                status:true,
                message:'Se necesita que seleccione alguna opción para poder actualizar'
            }); 

            return false;
        }

        if(valor.derived && !valor.userOrGroup){

            document.getElementById('ticket-update-user-group').setAttribute('required', true);

            setError({
                status:true,
                message:'Se necesita saber qué usuario o grupo para así poder actualizar'
            });
            
            return false;
        }

        setError({status:false, message:''});
        await dispatch(updateTicketState( slug, valor ));
        window.location.reload();
    }

    let aux = document.getElementById('ticket-update-derive') || {value:'m'};

    useEffect(() => {
        if(aux.value === '1') {
            setList([...update_info.users])
        } else if(aux.value === '2') {
            setList([...update_info.groups])
        } else {
            setList([])
        }
    }, [ update_info.users, update_info.groups, aux.value ])

    return (
        <form className='ticket-update-form'>
            <h5> Actualizar ticket </h5>
            <div className='ticket_update_body'>
                <div className='ticket-update-priority-topic'>
                    
                    <div className='ticket-update-priority'>
                        <label htmlFor='ticket-update-priority'>Prioridad: </label>
                        <select 
                            id='ticket-update-priority'
                            name='priority'
                            onChange={handleInputChange}
                        >
                            <option 
                                style={{color:'grey'}}
                                value=''
                            >
                                No actualizar
                            </option>
                            {
                                update_info.priority 
                                && update_info.priority.map( prior => {
                                    return <option 
                                                key={prior.id}
                                                value={prior.id}
                                            >
                                                {prior.name}
                                            </option>
                                })
                            }
                        </select>
                    </div>
                    
                    <div className='ticket-update-topic'>
                        <label htmlFor='ticket-update-topic'>Tópico: </label>
                        <select 
                            id='ticket-update-topic'
                            name='topic'
                            onChange={handleInputChange}
                        >
                            <option 
                                style={{color:'grey'}}
                                value=''
                            >
                                No actualizar
                            </option>
                            {
                                update_info.topic
                                && update_info.topic[0].topics.map( top => {
                                    return <option
                                                key={top.id}
                                                value={top.id}
                                            >
                                                {update_info.topic[0].name + ' - ' + top.name }
                                            </option>
                                })
                            }
                        </select>
                    </div>

                </div>

                <div className='ticket-update-derive-to'>
                <h5>Derivar:</h5>
                <div>

                    <div className='ticket-update-derive'>
                        <select 
                            id='ticket-update-derive'
                            name='derived'
                            onChange={handleInputChange}
                        >
                            <option 
                                style={{color:'grey'}}
                                value='cero'
                            >
                                No actualizar
                            </option>

                            <option value='1'>Usuario</option>
                            <option value='2'>Grupo</option>
                        </select>
                    </div>

                    <div className='ticket-update-group-user'>
                        <select 
                            id='ticket-update-user-group'
                            name='userOrGroup'
                            onChange={handleInputChange}
                        >
                            <option 
                                style={{color:'grey'}}
                                value=''
                            >
                                No actualizar
                            </option>

                            {
                                list
                                && list.map( userOrGroup => {
                                    return <option
                                                key={userOrGroup.id}
                                                value={userOrGroup.id}
                                            >
                                                { userOrGroup.username ? userOrGroup.username : userOrGroup.name }
                                            </option>
                                })
                            }
                        </select>
                    </div>

                </div>
            </div>
                           
                <div>
                    {
                        error.status 
                        && <p style={{ color:'red' }}>
                                {error.message} 
                            </p>
                        
                    }
                    <button 
                        className='ticket-update-submit'
                        type='button'
                        onClick={ handleUpdate }
                    >
                        Actualizar
                    </button>
                </div>
            </div> 
        </form>
    )
}
