import Tippy from '@tippyjs/react'
import React from 'react'

export const SwatchUserFormTab = ({content, iconClass}) => {
  return (
    <Tippy content={content} placement='top'>
        <div 
          className='ot_tab_icon'
          >
            <i className={iconClass}></i>
        </div> 
      </Tippy>
  )
}
