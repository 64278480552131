import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

//Reducers
import { authReducer } from '../reducers/authReducer';
import { detailReducer } from '../reducers/detailReducer';
import { createReducer } from '../reducers/createReducer';
import { headerReducer } from '../reducers/headerReducer';
import { contentReducer } from '../reducers/contentReducer';
import { uiReducer } from '../reducers/uiReducer';
import { userReducer } from '../reducers/userReducer';
import { chartReducer } from '../reducers/chartReducer';
import { swatchUsersReducer } from '../reducers/swatchUsersReducer';
import { subscriptionsReducer } from '../reducers/subscriptionsReducer';


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    users_list: swatchUsersReducer,
    ui: uiReducer,
    storage: contentReducer, //tickets y tasks
    header: headerReducer,
    detail: detailReducer,
    create: createReducer,
    charts: chartReducer,
    subscriptions: subscriptionsReducer
});

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk)),
);