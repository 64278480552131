import Tippy from '@tippyjs/react'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { storeSubscription } from '../../../../actions/subscriptions/subscriptions'

export const SubscriptionsEdit = (props) => {
  const 
    {subscriptions,groups} = useSelector(state => state.subscriptions),
    subscription = subscriptions.filter(sub => sub.id === props.show.info),
    strEDITorNew = "edit_or_new", strDELETE = "delete", strDeleteSub = "deleteSub",
    initState = {
      id:"",name:"",group:"", subscription: subscription?.id,flag:strEDITorNew,newTopic: true
    },
    [error, setError] = useState({status:false, message:''}),
    [topicToSend, setTopicToSend] = useState(initState),
    //dispatch = useDispatch(),
    {setConfirmation} = props,
    
    editTopic = ti => {
    //ti => topic info (ti.target.value)
      const 
        topic = subscription && subscription[0].topics?.filter(tp => tp.id === ti.target.value)[0],
        auxGroup = groups.filter(group => group.id === topic.user__group_id)
      return setTopicToSend({
        ...topicToSend,
        id:topic.id, 
        name: topic.name, 
        group: auxGroup[0].name,
        flag: strEDITorNew,
        newTopic: false
      })
    }


  const {newTopic} = topicToSend

  const handleTopicChange = e => {
    const {name, value, checked} = e.target
    return setTopicToSend({
      ...topicToSend, 
      [name]: 
        name === "newTopic" 
        ? checked 
        : value
    })
  }

  const topicReadyToSend = (e,flag, topic = null) => {
    const fd = new FormData()
    let auxMessage
    if(flag === strDELETE) {
      e.stopPropagation()
      fd.append('flag', strDELETE)
      fd.append('id', topic)
      fd.append('subscription', subscription && subscription[0]?.id)
      auxMessage = 'Eliminar tópico'
    }
    if(flag === strDeleteSub) {
      fd.append('flag', strDeleteSub)
      fd.append('subscription', subscription && subscription[0]?.id)
      auxMessage = 'Eliminar abono'
    }
    if(flag === strEDITorNew) {
      const 
        arrTopicToSend = Object.entries(topicToSend),
        arrFiltered = arrTopicToSend.filter(topic => topic[0] !== "id" && topic[0] !== "subscription" && topic[0] !== "newTopic"),
        aux2 = groups.filter(groupB => groupB.name === topicToSend.group)[0]

      if(!aux2) {
        return setError({status:true, message:"No existe un grupo con ese nombre"})
      }
      if(!newTopic && !topicToSend.id) {
        return setError({status:true, message:"Elija el tópico a editar"})
      }
      if(arrFiltered.filter(entry => !entry[1]).length) {
        return setError({status:true, message:"No puede haber campos vacíos"})
      }

      arrTopicToSend.map(topic => (topic[0] !== "group" && topic[0] !== "subscription" && topic[0] !== "newTopic") && fd.append(`${topic[0]}`,topic[1]))
      fd.append('newTopic', newTopic ? 1 : 0)
      fd.append('subscription',props.show.info)
      fd.append('group',groups.filter(group => group.name === topicToSend.group)[0].id)
    
      auxMessage = topicToSend.newTopic ? 'Nuevo tópico' : 'Editar tópico'
    }

    setError({status:false, message:""})

    setConfirmation({
      status:true, 
      info:{
        fd,
        subscriptionDispatcher: storeSubscription,
        code:"edit",
        stateToReset: initState,
        stateResetter: setTopicToSend,
        option: auxMessage
      }
    })
  }


  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='subscriptions_edit'
    >
      <Modal.Header closeButton className='subscriptions_edit_header'>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>Edición de Abono "{subscription[0] ? subscription[0].name : ''}"</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='subscriptions_edit_body m-0'>
          {
            error.status 
            ? <small className='text-danger'>{error.message}</small>
            : <small>Después de modificar el Abono, debe confirmar los cambios con el botón de Enviar ahora</small>
          }
          <Form className="edit">
            <Form.Group className="input_div">
              <Form.Label htmlFor="input_name">Nombre</Form.Label>
              <Form.Control  
                readOnly
                id="input_name"
                value={subscription[0] ? subscription[0].name : ''}
              />
            </Form.Group>
            <Form.Group className="input_div">
              <Form.Label htmlFor="input_topic">Tópico</Form.Label>
              <Form.Control  
                id="input_topic"
                value={topicToSend.name ? topicToSend.name: ""}
                name="name"
                onChange={handleTopicChange}
              />
            </Form.Group>
            <Form.Group className="input_div">
              <Form.Label htmlFor="input_group">Grupo</Form.Label>
              <Form.Control  
                id="input_group"
                value={topicToSend.group ? topicToSend.group : ""}
                name="group"
                onChange={handleTopicChange}
                list="se_id_group"
              />
              <datalist id="se_id_group">
                {groups && groups.map((group,i) => (
                  <option
                    key={i}
                    id={`group-${group.id}`}
                    value={group.name}
                  >
                    {group.name}
                  </option>
                ))}
              </datalist>
            </Form.Group>
                
            <Button
              variant="info" 
              className="mr-1 se_button"
              onClick={e => topicReadyToSend(e,strEDITorNew)}
            >
              <i className="fas fa-clipboard-check"></i>Enviar
            </Button>
            
            <Button 
              variant="danger"
              className="mr-1 se_button" 
              onClick={(e) => topicReadyToSend(e,strDeleteSub)}
            >
              Eliminar Abono
            </Button>
            {['checkbox'].map((type) => (
              <div key={`se-${type}`} className="mb-3 se_checkbox">
                <Form.Check>
                  <Form.Check.Input 
                    type={type} 
                    isValid 
                    name="newTopic"
                    onChange={handleTopicChange}
                    checked={newTopic}
                    id={`se-${type}`}
                  />
                  <Form.Check.Label htmlFor={`se-${type}`}>Nuevo tópico</Form.Check.Label>
                </Form.Check>
              </div>
            ))}
          </Form>
          <div className="topics">
            <span>Lista de tópicos</span>
            <ul className="topics_list">
              {subscription[0] ? subscription[0].topics?.filter(topic => topic.status === 'active')
              .map(topic => (
                <li 
                  value={topic.id} 
                  key={topic.id}
                  onClick={ti => editTopic(ti)}
                  className={topicToSend.id === topic.id ? "active" : ""}
                >
                  {topic.name}
                  <Tippy content='Borrar tópico' placement='right'>
                    <button 
                      className="menu"
                      onClick={(e) => topicReadyToSend(e,strDELETE,topic.id)}
                    >
                      <i className="fas fa-minus-circle"></i>
                    </button>
                  </Tippy>
                </li>
              )) : ''}
            </ul>
          </div>
      </Modal.Body>
      <Modal.Footer className='subscriptions_edit_footer'>

      </Modal.Footer>
    </Modal>
  )
}
