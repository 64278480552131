import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { hasPanelAccess } from '../../helpers/permission'
import { PATHS } from '../../types/paths'

export const NavMenu = () => {
	const [showMenu, setShowMenu] = useState(false)
	const { name: role } = useSelector((state) => state.auth.role)

	const handleShow = (e) => {
		setShowMenu(true)
	}

	const dontShow = (e) => {
		setShowMenu(false)
	}

	return (
		<div
			className='create-buttons'
			onMouseEnter={handleShow}
			onMouseLeave={dontShow}
		>
			<button className='crear-ticket-button' type='button'>
				<h2>+</h2>
				<p>CREAR</p>
			</button>

			{showMenu && (
				<div className='create-nav-menu'>
					<Link
						className='create-ticket'
						id='create-ticket'
						to={PATHS.createTickets}
						onClick={dontShow}
					>
						<i className='fas fa-ticket-alt'></i> Ticket
					</Link>
					{hasPanelAccess(role) && (
						<>
							<Link
								className='create-task'
								id='create-task'
								to={PATHS.createTasks}
								onClick={dontShow}
							>
								<i className='fas fa-sticky-note'></i> Tarea
							</Link>
							<Link
								className='create-task'
								id='create-task'
								to={PATHS.createUsers}
								onClick={dontShow}
							>
								<i className='fas fa-user-plus'></i> Usuario
							</Link>
						</>
					)}
				</div>
			)}
		</div>
	)
}
