import { isValidString } from "helpers/string";
import { UNREQUIRED } from "../constants";
import { strategy } from "./strategy";

const isEmptyFieldHelper = (input) =>
  !Array.isArray(input) ||
  !input.length ||
  !input[0].length ||
  !input[1].length;

export const requiredUserInfoValidator = (info) =>
  Array.isArray(info) && info.length
    ? info.filter((info) => !UNREQUIRED.includes(info[0]))
    : info;

export const checkUserInfoValidator = (info) => {
  if (Array.isArray(info) && info.length) {
    // Se extraen solo los valores requeridos
    // para luego ser evaluados
    const requiredUserInfo = requiredUserInfoValidator(info);

    // Si hay campos requeridos y están vacíos
    if (requiredUserInfo.filter(isEmptyFieldHelper).length)
      return "Es necesario que ingrese los datos requeridos";

    // Si las contraseñas no coinciden
    const [, password] = info.find(([name]) => name === "password");
    const [, password2] = info.find(([name]) => name === "password2");

    if (password !== password2)
      return "Es necesario que las contraseñas coincidan";

    // Validación sobre campos requeridos
    // Detalles en './strategy'
    const [validationMessage] = requiredUserInfo
      .map(([name, value]) =>
        strategy[name].rule(value) ? strategy[name].message : false
      )
      .filter((input) => input);

    return validationMessage;
  }
};

export const userInfoFormData = (info) => {
  if (Array.isArray(info)) {
    const fd = new FormData();

    info.forEach(([name, value]) => {
      if (name !== "password2" && name !== "companies" && value) {
        fd.append(`${name}`, value);
      }
      if (name === "companies") {
        value.forEach((company) => fd.append("companies[]", company));
      }
    });

    return fd;
  }
};
