import { TYPES } from "../types/types";

const initialState = {
    ticket:{
        detail: {},
        comments: {},
        interactions: [],
        time: 0,
        update_info:{}
    },
    task:{
        detail: {},
        comments: {},
        interactions: [],
        time: 0,
        update_info:{}
    }
}

export const detailReducer = (state = initialState, action) => {

    switch (action.type) {

        /** TICKETS TICKETS TICKETS TICKETS */
        case TYPES.setTicketDetail:
            return {
                ...state,
                ticket:{
                    ...state.ticket,
                    detail: {...action.payload}
                }
            };

        case TYPES.setTicketComments:
            return {
                ...state,
                ticket:{
                    ...state.ticket,
                    comments: {...action.payload}
                }
            };

        case TYPES.setTicketInteractions:
            return {
                ...state,
                ticket:{
                    ...state.ticket,
                    interactions: {...action.payload}
                }
            };

        case TYPES.setTicketTime:
            return {
                ...state,
                ticket:{
                    ...state.ticket,
                    time: action.payload.time_spent
                }
            };

        case TYPES.updateTicketDetail:
            return {
                ...state,
                ticket:{
                    ...state.ticket,
                    update_info: {
                        priority: action.payload.priority,
                        topic: action.payload.topic,
                        users: action.payload.users,
                        groups: action.payload.groups,
                    }
                }
            }
            

        /** TASKS TASKS TASKS TASKS TASKS TASKS */

        case TYPES.setTaskDetail:
            return {
                ...state,
                task:{
                    ...state.task,
                    detail: {...action.payload}
                }
            };

        case TYPES.setTaskComments:
            return {
                ...state,
                task:{
                    ...state.task,
                    comments: {...action.payload}
                }
            };

        case TYPES.setTaskInteractions:
            return {
                ...state,
                task:{
                    ...state.task,
                    interactions: {...action.payload}
                }
            };

        case TYPES.setTaskTime:
            return {
                ...state,
                task:{
                    ...state.task,
                    time: action.payload.time_spent
                }
            };

        case TYPES.updateTaskDetail:
            return {
                ...state,
                task:{
                    ...state.task,
                    update_info: {
                        priorities: action.payload.priorities,
                        types: action.payload.types,
                        task_users: action.payload.task_users,
                        task_children: action.payload.task_children,
                        dependencies: action.payload.dependencies,
                        companies: action.payload.companies,
                        task_derive_users:action.payload.task_derive_users,
                        task_derive_groups:action.payload.task_derive_groups,
                    }
                }
            }
            
        case TYPES.deleteDetail:
            return { ...initialState };

        default:
            return state;
    }
}