import { TYPES } from '../types/types'

const initialState = {
	tickets: [],
	tasks: [],
	ot: {
		destinations: [],
		states: [],
		detail: {},
	},
}

export const contentReducer = (state = initialState, action) => {
	switch (action.type) {
		/**TICKETS */
		case TYPES.setTickets:
			return {
				...state,
				tickets: [...action.payload],
			}

		case TYPES.deleteTickets:
			return {
				...state,
				tickets: [],
			}

		/**TASKS */
		case TYPES.setTasks:
			return {
				...state,
				tasks: [...action.payload],
			}

		case TYPES.deleteTasks:
			return {
				...state,
				tasks: [],
			}

		case TYPES.deleteContent:
			return { ...initialState }

		case TYPES.otEdit:
			return {
				...state,
				tickets: [...action.payload?.tickets],
				ot: {
					destinations: [...action.payload?.destinations],
					states: [...action.payload?.states],
					detail: {},
				},
			}
		case TYPES.setOtInfo:
			return {
				...state,
				ot: {
					...state.ot,
					detail: {
						state: action.payload?.state,
						destination: action.payload?.destination,
						budget: {
							status: action.payload?.has_budget,
							amount: action.payload?.budget_amount,
						},
					},
				},
			}

		default:
			return state
	}
}
