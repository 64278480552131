
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { updateTicketState } from '../actions/ticket-detail';
import { useForm } from '../hooks/useForm';
import { PATHS } from '../types/paths';
import { hasPanelAccess } from '../helpers/permission';

export const CommentBox = ({slug, isTaken, status}) => {
    const history = useHistory();
    const {name:role} = useSelector(state => state.auth.role)
    const [error, setError] = useState({
        status:false,
        message:''
    });

    const isDisabled = () => {
        if(status) {
            if ((status.name && status.name === 'Cerrado')
            || (status.name && status.name === 'Solucionado') ) {
            console.log('true')
            return true;
        } else {
            return false;
        }
        }
    }

    const dispatch = useDispatch();

    const initialValue = {
        comment: '',
        action:'',
        ticketFile:null
    }
    
    const [ value, handleInputChange, actualValue ] = useForm(initialValue)

    //console.log('valores iniciales',value, initialValue)
    const handleClick = async(e) => {
        e.preventDefault();
        //console.log(value)
        let buttonValue = '';

        //Se utilizan estas condicionales debido a que al hacer click en los botones, se puede llegar a presionar 
        //no solo el botón, sino el ícono, que como tal, es un elemento diferente dentro del botón por lo que 
        //las propiedades internas serían diferentes al botón en sí, que es el que está realizando la acción del 
        //handleClick, por lo que el evento "e", tomaría los valores del ícono
        if(e.target.innerHTML) {
            buttonValue = e.target.value;
        } else {
            buttonValue = e.target.parentNode.value;
        }
        
        //console.log('action',buttonValue, 'comment', value)
        /** Al hacer click se ha elegido una acción:
         * -Comentar
         * -Solucionar
         * -Cerrar
         * -Comentar en interno
        */

        //Para poder ser desestructurado dentro del useForm, se procede a establecer esta estructura de datos
        const body = {
            target : {
                name: 'action',
                value: buttonValue
            }
        } 

        handleInputChange(body)
        /**handleInputChange({
            ...value,
            action: buttonValue
        })*/

        /**
         * valor ACTUAL COMPROBADO DEL ESTADO, se cambia una vez se haya llamado asíncronamente al handleInputChange -> setValue 
         * setValue tarda un rato en establecer el cambio cuando se le llama, 
         * una vez procesado como se hace en el CustomHook, este debe ser esperado asíncronamente(await), así se obtiene el 
         * valor actualizado real del estado
         */
        let valor = await actualValue();

        //console.log('Valor', valor); 
        //console.log('prueba valor real', actualValue(), valor)
                
        if( !valor.action ) {
                console.log('is not ')
                //setError(true);
                return;
            } 
            

        if (valor.action === 'tarea' && !valor.comment) {
            setError({
                status:true,
                message:'Escriba el título de la nueva tarea en la caja de comentario'
            });
            document.getElementById('text-area-ticket-comment').setAttribute('required', true);
            return;
        }


        if((valor.action === 'close' && !valor.comment) 
        || (valor.action === 'done' && !valor.comment) 
        || (valor.action === 'comment' && !valor.comment)){
            setError({
                status:true,
                message:'Se necesita hacer un comentario para poder continuar con la acción'
            });
            console.log('no hay comentario, close, done, o comment no se pueden pasar', valor);
            document.getElementById('text-area-ticket-comment').setAttribute('required', true);
            return;
        }

        let x = new FormData();

        x.append('ticketFile', valor.ticketFile);
        x.append('action', valor.action);
        x.append('comment', valor.comment);
        
        await dispatch(updateTicketState(slug, x));  
        setError({
            status:false,
            message:''
        });
        switch (valor.action) {
            case 'close':
                return history.push(PATHS.generalTickets);
            case 'done':
                return history.push(PATHS.generalTickets);
            case 'reopen':
                return history.push(PATHS.generalTickets);
            case 'tarea':
                return history.push(PATHS.generalTasks);
            default:
                return window.location.reload();
        }
    }

    
    const fileUpload = async(e) => {
        console.log('carga de archivo',e.target.files[0]);
        handleInputChange({
            target: {
                name : [e.target.name],
                value : e.target.files[0]
            }            
        })
    }

    return (
        <form 
            className='comment-box-form'
            encType="multipart/form-data"
        >

        <>
            <textarea 
                placeholder='Comentario...' 
                value={ value.comment } 
                name='comment'
                onChange={ handleInputChange } 
                id='text-area-ticket-comment'
                disabled= { isDisabled() }
            >
            </textarea>

            { //Caso de error
                error.status
                && <small style={{color:'red'}}>
                    {error.message}
                </small>
            }

            { //Si está habilitado el ticket
                isDisabled()
                ? <div className='comment-box-buttons'>
                    <div>

                        {/**BOTÓN PARA REABRIR */}
                        <Tippy content='Reabrir el ticket' placement='bottom'>
                            <button 
                                className='comment-submit' 
                                name='action'
                                value='reopen' 
                                onClick={ handleClick }
                            >
                                Reabrir
                            </button> 
                        </Tippy>
                    </div>
                </div>
                : <div className='comment-box-buttons'>
                    
                    <div>

                        {/**BOTÓN PARA COMENTAR */}
                        <button 
                            className='comment-submit' 
                            name='action'
                            value='comment' 
                            onClick={ handleClick }
                        >
                            Comentar
                        </button>
                        {
                            (status && status.name !== 'Resolucion pendiente')
                            && <>
                            {
                                hasPanelAccess(role)
                                && //BOTÓN PARA INTERNO 
                                <Tippy content='Comentario interno' placement='bottom'>
                                    <button 
                                        className='interno-submit internal' 
                                        name='action'
                                        value='internal' 
                                        onClick={ handleClick }
                                    >
                                        <i className="fas fa-bullhorn internal"></i>
                                    </button>
                                </Tippy>
                            }
            
                            {
                                hasPanelAccess(role) 
                                && //BOTÓN PARA CONVERTIR A TAREA
                                <Tippy content='Cerrar y crear tarea' placement='bottom'>
                                    <button 
                                        className='task-submit' 
                                        name='action'
                                        value='tarea' 
                                        onClick={ handleClick }
                                    >
                                        <i className="fas fa-copy"></i>
                                    </button>
                                </Tippy>
                            }
    
                            {/**BOTÓN PARA SOLUCIONAR */}
                            <Tippy content='Solucionar' placement='bottom'>
                                <button
                                    className='solved-submit solve' 
                                    name='action'
                                    value='done' 
                                    onClick={ handleClick }
                                >
                                    <i className="fas fa-check solve"></i>
                                </button>
                            </Tippy>
    
                            {
                                hasPanelAccess(role)
                                && //BOTÓN PARA CERRAR
                                <Tippy content='Cerrar' placement='bottom'>
                                    <button 
                                        type='button'
                                        className='closed-submit closed' 
                                        name='action'
                                        value='close' 
                                        onClick={ handleClick }
                                    >
                                        <i className="fas fa-times closed"></i>
                                    </button>
                                </Tippy>
                            }

                            {
                                hasPanelAccess(role)
                                && //BOTÓN PARA TAKE/RELEASE
                                <Tippy content='Tomar/Soltar' placement='bottom'>
                                    <button 
                                        id='comment-take-release-button' 
                                        name='action'
                                        className={ isTaken ? 'release-submit tor': 'take-submit tor' } 
                                        value={ isTaken ? 'release' : 'take' } 
                                        onClick={ handleClick }
                                    >
                                        { 
                                            isTaken 
                                            ? <i className="far fa-hand-rock tor"></i> 
                                            : <i className="far fa-hand-paper tor"></i> 
                                        }
                                    </button>
                                </Tippy>
                            }
                        </>
                        }
                    </div>
                    {
                        (status && status.name !== 'Resolucion pendiente') 
                        && <div className="form-group file">
                                {/**INPUT PARA CARGAR ARCHIVO */}
                                <Tippy content='Solo imágenes' placement='bottom'>
                                    <input 
                                        type="file" 
                                        className="form-control-file exampleFormControlFile1" 
                                        name='ticketFile'
                                        onChange={fileUpload}                            
                                    />
                                </Tippy>
                            </div>
                    }
                    
                </div>
            }
            </>
        
        </form>
    )
}
