import React from 'react';
import { useSelector } from 'react-redux';

export const MonthlyClosedTicketsChart = () => {
    const { finishedMonthlyTickets:tickets } = useSelector(state => state.charts);
    const { user } = useSelector(state => state.auth);
    let data2 = [];
    if(tickets) {
        const {
            emergency,
            high,
            medium,
            low
        } = tickets;

        data2 = [
            { name: "Total", value: parseInt(emergency + high + medium + low) }
        ];
    }

    return (
        <div>
            <p>Cerrados mensuales <br />({user && user.username}) </p>
            <b>{(data2[0] && Number.isInteger(data2[0].value)) ? data2[0].value : 0}</b>
        </div>
    )
}
