import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createTask, getCreateTaskInfo } from '../../actions/tasks/task-create';
import { toTheTop,commentDate } from '../../helpers/helpers';
import { Fecha } from '../DatePicker/Fecha';

export const TaskCreate = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const weights = [ 1,2,3,4,5,6,7,8,9,10 ];
    const initialError = {status:false, message:''}
    const [error, setError] = useState(initialError);

    const { 
        priorities, 
        categories, 
        companies,
        deriveUsers,
        groups
    } = useSelector(state => state.create.task_info);

    const [formValue, setFormValue] = useState({
        priority:'1',
        urgency:'1',
        due_date:'',
        end_date:'',
        category:'',
        company:'',
        derive:'',
        derivedTo:'',
        dependency:'',
        title:'',
        description:''
    })

    const {
        priority, 
        urgency, 
        due_date,
        end_date,
        category, 
        company, 
        derive, 
        derivedTo, 
        dependency, 
        title, 
        description
     } = formValue;

    const handleChange = (e) => {
        //console.log('capturando', e.target, 'formulario', formValue);
        const { name, value } = e.target;

        if( name === 'derive' && !value ) {
            return setFormValue({
                ...formValue,
                derivedTo:'',
                [e.target.name]: e.target.value
            })
        }

        if( name === 'company') {
            return setFormValue({
                ...formValue,
                derivedTo:'',
                [e.target.name]: e.target.value
            })
        }

        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });

    }

    //Filtro que devuelve true si un usuario tiene una compañia con el mismo nombre que el argumento 'cname'
    const testt = (b_user, cname) => {
        if(b_user.companies){
            return [...b_user.companies].some(company => company.name === cname);
        }
    }
    
    const handleClick = async(e) => {
        e.preventDefault();
        //Si existe algún campo vacío lo devuelve en un array
        //let aux = Object.entries(formValue).filter( value => !value[1] && !auxArray.includes(value[0]) );
        let aux = Object.entries(formValue).filter( value => !(value[0] === 'derive' 
        || value[0] === 'derivedTo' 
        || value[0] === 'dependency'
        || value[0] === 'category'
        || value[0] === 'due_date'
        || value[0] === 'end_date'
        ) && !value[1]);
                
        //console.log(aux.length)
        if( aux.length > 2 ){
            setError({
                status:true,
                message:'Debe completar correctamente los campos necesarios del formulario.',
            });
            
            return toTheTop();
        }

        if ( derive && !derivedTo ) {
            setError({
                status:true,
                message:'Seleccione a quién va a derivar la tarea',
            });

            return toTheTop(); 
        }

        if( !company ) {
            setError({
                status:true,
                message:'Seleccione una EMPRESA para la tarea',
            });

            return toTheTop(); 
        } else {
            setError({
                status:false,message:'Seleccione una EMPRESA para la tarea',
            })
        }
        
        if( !category ) {
            setError({
                status:true,
                message:'Seleccione una CATEGORÍA para la tarea',
            });

            return toTheTop(); 
        }        
        
        if((due_date && end_date) 
        && (Date.parse(due_date) > Date.parse(end_date) 
            || Date.parse(commentDate(Date.now())) > Date.parse(end_date))) {
            setError({
                status:true,
                message:'Cambie la fecha de finalización',
            });

            return toTheTop(); 
        }

        if(!title || !description) {
            setError({
                status:true,
                message:'Se requiere un TÍTULO y una DESCRIPCIÓN para la tarea',
            });

            return toTheTop(); 
        }
        setError({status:false,message:''}); 
        let formData = new FormData();
        
        if(derive && derivedTo){
            formData.append('derive',derive);
            if(derive === 'user') {
                const aux_user = deriveUsers.filter(user => user.username === derivedTo);
                if(aux_user.length < 1 ) {
                    return setError({
                        status:true,
                        message:'Se requiere un USUARIO válido para la tarea',
                    });
                } else {
                    formData.append('derivedTo',aux_user[0].id)
                }
            }
            
            if(derive === 'group') {
                const aux_group = groups.filter(group => group.name === derivedTo);
                if(aux_group.length < 1) {
                    return setError({
                        status:true,
                        message:'Se requiere un GRUPO válido para la tarea',
                    });
                } else {
                    formData.append('derivedTo',aux_group[0].id)
                }
            }
        }
        if(dependency){
            formData.append('dependency',parseInt(dependency));
        }
        if( !due_date && end_date && commentDate(Date.now()) < end_date) {          
            formData.append('due_date',commentDate(Date.now()));
            formData.append('end_date',end_date);
        }
        if( due_date && !end_date ) { 
            formData.append('due_date',due_date);
            formData.append('end_date',commentDate(Date.now() + parseInt(7 * 24 * 60 * 60 * 1000)));
        }

        if(due_date && end_date){
            formData.append('due_date',due_date);
            formData.append('end_date',end_date);
        }
        
        formData.append('company',(companies.filter(comp => comp.name === company))[0].id);
        formData.append('priority',priority);
        formData.append('urgency',urgency);
        formData.append('category',category);
        formData.append('title',title);
        formData.append('description',description);
        
        await dispatch( createTask(formData) );
        //console.log(formValue);
        history.push('/panel/tasks/general');
    }
    
    useEffect(() => {
        dispatch(getCreateTaskInfo());
    },[dispatch]);

    return (
        <div className='seccion-tabla ticket-create-special-margin'>
            <div className='seccion-tabla-interno'>
                <form className='task-form' encType='multipart/form-data'>
                    <div className='task-create-form'>

                        { 
                            error.status 
                            && <p style={{
                                color:'red', 
                                display:'flex', 
                                width:'80%', 
                                margin:'0 20%',
                                justifyContent:'center',
                                padding:'1rem',
                                background:'rgba(255, 0, 0, 0.3)',
                                border:'1px solid red',
                                borderRadius:'.3rem'
                            }}>
                                { error.message }
                            </p> 
                        }

                        <div className='task-create-part-a'>

                        <div className='task-create-company'>
                                <label htmlFor='inputSelectCompany'>Empresa</label>
                                <input 
                                    autoComplete="off"
                                    id='inputSelectCompany' 
                                    list="company_filter" 
                                    name='company'
                                    onChange={handleChange}
                                    placeholder='Empresa'
                                    required 
                                    value={company}
                                    onFocus={() => {
                                        document.getElementById('company_filter').style.display='block'
                                    }}
                                />
                                    
                                <datalist 
                                    id="company_filter"
                                    onClick={() => {
                                        document.getElementById('company_filter').style.display='none'
                                    }}
                                >
                                    {
                                        companies && companies.map( company => {
                                            return <option 
                                                value={company.name}
                                                key={company.id}
                                            >
                                                {company.name}
                                            </option>
                                        })
                                    }
                                </datalist>
                            </div>
                            
                            <div className='task-create-category'>
                                <label htmlFor='inputSelectCategory'>Categoría</label>
                                <select 
                                    required 
                                    id='inputSelectCategory' 
                                    value={category}
                                    name='category'
                                    onChange={handleChange}
                                >
                                    <option value='' disabled>--Categoría--</option>
                                    {
                                        categories && categories.map( category => {
                                            return (
                                                <option 
                                                    value={ category.id } 
                                                    key={ category.id }
                                                >
                                                    { category.name }
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            

                            
                            <hr />

                            <Fecha 
                                handleChange={handleChange}
                                formValue={formValue}
                                error={error}
                            />

                            <hr />
                            <div className='task-create-priority'>
                                <label htmlFor='inputSelectPriority'>Prioridad</label>
                                <select 
                                    required 
                                    id='inputSelectPriority' 
                                    value={priority}
                                    name='priority'
                                    onChange={handleChange}
                                >
                                    <option value='1'>Baja</option>
                                    { 
                                        priorities && priorities.filter(prior => prior.name !== 'Baja').map( priority => {
                                            return (
                                                <option 
                                                    value={ priority.id } 
                                                    key={ priority.id }
                                                >
                                                    { priority.name }
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>

                            
                            <div className='task-create-urgency'>
                                <label htmlFor='inputSelectUrgency'>Urgencia</label>
                                <select 
                                    required 
                                    id='inputSelectUrgency' 
                                    value={urgency}
                                    name='urgency'
                                    onChange={handleChange}
                                >
                                    <option value='' disabled >--Urgencia--</option>
                                    {weights.map( (weight,i) => {
                                        return (<option key={i} value={weight}> {weight} </option>)
                                    } )}
                                </select>
                            </div>

                        </div>
                        <div className='task-create-part-b'>

                        <div className='task-create-asign'>
                                <label htmlFor='inputSelectAsign'>Asignar a:</label>
                                <select 
                                    id='inputSelectAsign' 
                                    value={derive}
                                    name='derive'
                                    onChange={handleChange}
                                >
                                    <option value='' style={{color:'grey'}}>--A derivar--</option>
                                    <option value='user'>Usuario</option>
                                    <option value='group'>Grupo</option>
                                </select>
                            </div>
                            
                            <div className='task-create-userGroup'>
                                <label htmlFor='inputSelectUserGroup'>Usuario o grupo:</label>
                                <input 
                                    autoComplete="off"
                                    id='inputSelectUserGroup' 
                                    list="user_group_filter" 
                                    name='derivedTo'
                                    onChange={handleChange}
                                    placeholder='Usuario/Grupo'
                                    value={derivedTo}
                                />
                                <datalist
                                    id="user_group_filter"
                                >
                                    {(derive && derive === 'user' && deriveUsers && company)
                                    && deriveUsers.filter( user => testt(user, company))
                                    .map( user => {
                                            return <option 
                                                value={user.username}
                                                key={user.id}
                                            >
                                                {user.username}
                                                </option>;
                                        })
                                    }

                                    {(derive && derive === 'user' && !company)
                                        && deriveUsers.map( user => {
                                            return <option 
                                                value={user.username}
                                                key={user.id}
                                            >
                                                {user.username}
                                                </option>;
                                        })
                                    }

                                    {
                                        ( derive && derive === 'group' )
                                        && groups.map( group => {
                                            return (
                                                <option 
                                                    value={group.name}
                                                    key={group.id}
                                                >
                                                    { group.name }
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                            </div>

                            <hr />
                            
                            <div className='task-create-dependency'>
                                <label htmlFor='inputSelectDependency' style={{color:'grey'}}>Dependencia</label>
                                <select 
                                    id='inputSelectDependency' 
                                    value={dependency}
                                    name='dependency'
                                    onChange={handleChange}
                                    placeholder='--Tarea padre--'
                                    disabled
                                >
                                    
                                    {/** <option value='' style={{color:'white'}}>--Tarea padre--</option>
                                        dependencies && dependencies.map( dependency => {
                                            return (
                                                <option 
                                                    value={ dependency.id }
                                                    key={ dependency.id }
                                                >
                                                    { dependency.title }
                                                </option>
                                            )
                                        })
                                    */}
                                </select>
                            </div>
                            
                            <hr />

                            <div className='task-create-title'>
                                <label htmlFor='inputTaskTitle'>Título</label>
                                <input 
                                    autoComplete='off'
                                    type='text' 
                                    id='inputTaskTitle' 
                                    placeholder='Título'  
                                    value={title}
                                    name='title'
                                    onChange={handleChange}
                                />
                            </div>
                            
                            <div className='task-create-description'>
                                <label htmlFor='inputTaskDescription'>Descripción</label>
                                <textarea 
                                    autoComplete='off'
                                    id='inputTaskDescription' 
                                    placeholder='Descripción...' 
                                    value={description}
                                    name='description'
                                    onChange={handleChange}
                                ></textarea>
                            </div>  
                            
                        </div>
                    </div>
                    <div className='task-create-buttons'>
                        
                        <button 
                            type='submit'
                            className='task-create-button'
                            onClick={handleClick}
                        >
                            <i className="fas fa-sticky-note"></i> Crear tarea 
                        </button>
                        <button 
                            disabled
                            className='task-create-return'
                        >
                                <i className="fas fa-undo-alt"></i> Volver 
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
