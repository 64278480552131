import React from 'react'
//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const SubscriptionsRowOptions = ({setSimple,id}) => {
  return (
    <div className='subscriptions_row_options'>
      <Tippy content='Editar información de Abono' placement='bottom'>
        <button 
          className='subscriptions_row_button'
          onClick={() => setSimple({status:true,info:id})}
        >
          <i className="fas fa-edit"></i>
        </button>
      </Tippy>
    </div>
  )
}
