
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSwatchUser } from '../../../actions/swatch-users/get-swatch-user'
import { isWatchmaker } from '../../../helpers/permission'

export const SwatchUserDeleteModal = (props) => {

  const {name:role} = useSelector(state => state.auth.role)
  const dispatch = useDispatch()
  const [error, setError] = useState({status:false, message:''})

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(props.user._id)
    await dispatch(deleteSwatchUser(props.user._id))
  }

  /* useEffect(() => {
    if(isWatchmaker(role)) {
      dispatch(otGetObservationsListWatchmaker(props.slug))
    }
  }, [dispatch,props.slug,role]) */
  return (
    
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal swatch_user_delete'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter ">
          <h6>Borrar usuario</h6>
          <span>Se eliminará el usuario Swatch <strong>-{props.user ? props.user.username : '-'}-</strong></span>
          {/* {
            error.status 
            ? <small className='text-danger'>{error.message}</small>
            : <small>Si no selecciona un tipo de reparación, el OT será tomado como "Reparación"</small>
          } */}
        </Modal.Title>
      </Modal.Header>{/* 
      <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
        
        
      </Modal.Body> */}
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom'>
        


        <Button 
          variant="danger" 
          onClick={handleSubmit}
        >
          Borrar
        </Button>

        <Button 
          variant="warning" 
          onClick={props.onHide}
        >
          Volver
        </Button>

      </Modal.Footer>
    </Modal>
  
  )
}
