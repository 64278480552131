import Tippy from '@tippyjs/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sendSearchInfo } from '../../actions/custom-search/custom-search';
import { deleteAllTickets } from '../../actions/tickets';
import { deleteAllTasks } from '../../actions/tasks/tasks';
import { commentDate } from '../../helpers/helpers';
import { useForm } from '../../hooks/useForm';
import ReactDatePicker from 'react-datepicker';

export const CustomSearchBar = ({setTable,table}) => {
    const dispatch = useDispatch();
    const weights = [ 1,2,3,4,5,6,7,8,9,10 ];
    const [error, setError] = useState({
        status:false,
        message:''
    });

    const { 
        task_info:{
            priorities: task_priorities, //valor: renombrar valor
            categories, 
            deriveUsers,
            status:task_status
        },
        ticket_info:{
            companies,
            priorities: ticket_priorities,
            status:ticket_status
        }
    } = useSelector(state => state.create);

    /**Ticket o tarea */
    const [type, setType] = useState({
        ticketOrTask:'1'
    })
    

    const initialValue = {
        slug:'',
        title:'',
        topic:'',
        user:'',
        company:'',
        start_date: null,
        end_date: null,
        priority:'',
        urgency:'',
        status:'',
        category:'',
    }

    const [ value, handleInputChange, , reset ] = useForm(initialValue);

    const handleTypeChange = e => {
        reset();
        setError({ status:false, message:'' })
        setType({
            ticketOrTask: e.target.value
        });
        dispatch(deleteAllTickets());
        dispatch(deleteAllTasks());
        setTable(!table);
    }

    const handleDelete = e => {
        e.preventDefault();
        reset();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(e);

        const aux = Object.entries(value).filter( item => item[1] !== '' && (item[0] !== 'start_date' && item[0] !== 'end_date') );
        //console.log('aux', aux)
        if(aux.length === 0 && commentDate(value.start_date) === '1969-12-31 21:00') {
            //console.log('length')
            return setError({
                status:true,
                message:'Necesita utilizar al menos un filtro'
            })  
        } 

        const fd = new FormData();

        if(value.slug && !parseInt(value.slug) > 0) {
            //console.log('slug')
            //console.log(value.slug,'slug')
            return setError({
                status:true,
                message:'Tiene que ser un número mayor a 0'
            })
        }

        if((commentDate(value.start_date) !== '1969-12-31 21:00' 
        && commentDate(value.start_date) !== null) && commentDate(value.end_date) === '1969-12-31 21:00'){
            return setError({
                status:true,
                message:'Tiene que introducir un fecha de finalización'
            });
        } else if(commentDate(value.start_date) !== '1969-12-31 21:00' && commentDate(value.end_date) !== '1969-12-31 21:00') {
            fd.append('start_date',commentDate(value.start_date))
            fd.append('end_date',commentDate(value.end_date))
        }
        
        fd.append('info',type.ticketOrTask);

        for(let a = 0; a < aux.length; a++) {
            if(aux[a][0] === 'user') {
                let userForId = deriveUsers.filter(user => user.username === aux[a][1]);
                //console.log('userForId', userForId)
                if(userForId.length === 0) {
                    return setError({
                        status:true,
                        message:'El usuario no existe'
                    })
                } else {
                    fd.append(`${aux[a][0]}`, userForId[0].id)
                }
            } else if(aux[a][0] === 'company') {
                let companyForId = companies.filter(company => company.name === aux[a][1]);
                //console.log('companyForId', companyForId)
                if(companyForId.length === 0) {
                    return setError({
                        status:true,
                        message:'La empresa no existe'
                    })
                } else {
                    fd.append(`${aux[a][0]}`, companyForId[0].id)
                }
            } else if(aux[a][0] === 'status') {
                let statusForId;

                if(parseInt(type.ticketOrTask) === 1) {
                    statusForId = ticket_status.filter(stat => stat.name === aux[a][1]);
                } else if (parseInt(type.ticketOrTask) === 2) {
                    statusForId = task_status.filter(stat => stat.name === aux[a][1]);
                }
                //console.log('statusForId', statusForId)
                if(statusForId.length === 0) {
                    return setError({
                        status:true,
                        message:'El tipo de estado no existe'
                    })
                } else {
                    fd.append(`${aux[a][0]}`, statusForId[0].id)
                }
            } else {
                fd.append(`${aux[a][0]}`,aux[a][1])
            }
        }
        
        setError({ status:false, message:'' });
        dispatch(sendSearchInfo(fd,type.ticketOrTask));
    }

    //Filtro que devuelve true si un usuario tiene una compañia con el mismo nombre que el argumento 'cname'
    const testt = (user, cname) => {
        const auxComp = [...user.companies].some(company => company.name === cname);
        return auxComp
    }

    //console.log(value.company, deriveUsers && deriveUsers.filter( user => testt(user, value.company)).length)
    return (
        <>
        <form className='custom-search-form' id='custom-search-form'>

            <Tippy content='Tickets o tareas' placement='top'>
                <div className='custom-search-input'>
                    <select 
                        required 
                        value={type.ticketOrTask}
                        name='ticketOrTask'
                        onChange={handleTypeChange}
                    >
                        <option value='1'>Tickets</option>
                        <option value='2'>Tareas</option>
                    </select>
                </div>
            </Tippy>

            {
                (type.ticketOrTask && type.ticketOrTask === '1') &&

                <>
                    <Tippy content='Nº del ticket' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete='off'
                                type='text' 
                                placeholder='Nº Ticket'  
                                value={value.slug}
                                name='slug'
                                onChange={handleInputChange}
                            />
                        </div>
                    </Tippy>
                    
                    <Tippy content='Contenido del ticket' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete='off'
                                type='text' 
                                placeholder='Contenido'  
                                value={value.title}
                                name='title'
                                onChange={handleInputChange}
                            />
                        </div>
                    </Tippy>
                    
                    <Tippy content='Búsqueda por empresa' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete="off"
                                list="company_filter" 
                                id="company_filter_id" 
                                value={value.company}
                                name='company'
                                onChange={handleInputChange}
                                placeholder='Empresa'
                                onFocus={() => {
                                    document.getElementById('company_filter').style.display='block'
                                }}
                            />

                            <datalist 
                                id="company_filter"
                                onClick={() => {
                                    document.getElementById('company_filter').style.display='none'
                                }}
                            >
                                {
                                    companies && companies.map( company => {
                                        return <option 
                                            value={company.name}
                                            key={company.id}
                                        >
                                            {company.name}
                                        </option>
                                    })
                                }
                            </datalist>
                        </div>
                    </Tippy>
                                        
                    <Tippy content='Búsqueda por usuario' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete="off"
                                list="user_filter" 
                                id="user_filter_id" 
                                value={value.user}
                                name='user'
                                onChange={handleInputChange}
                                placeholder='Usuario'
                                onFocus={() => {
                                    document.getElementById('user_filter').style.display='block'
                                }}
                            />

                            <datalist 
                                id="user_filter"
                                onClick={() => {
                                    document.getElementById('user_filter').style.display='none'
                                }}
                            >
                                {
                                    deriveUsers && value.company 
                                    ? deriveUsers.filter( user => testt(user, value.company))
                                        .map( user => {
                                                return <option 
                                                    value={user.username}
                                                    key={user.id}
                                                >
                                                    {user.username}
                                                    </option>;
                                            })
                                    : deriveUsers.map( user => {
                                        return <option 
                                            value={user.username}
                                            key={user.id}
                                        >
                                            {user.username}
                                            </option>;
                                    })
                                }
                            </datalist>
                        </div>
                    </Tippy>
                                    
                    <div className='custom-search-input'>
                        <ReactDatePicker
                            selected={value.start_date}
                            onChange={date => handleInputChange({
                                target:{
                                    name:'start_date',
                                    value:date
                                }
                            })}
                            selectsStart
                            startDate={value.start_date}
                            endDate={value.end_date}
                            className='start-date'
                            placeholderText="Desde"
                            closeOnScroll={true} 
                            dateFormat="dd/MM/yyyy h:mm aa"
                            locale='es'
                            showWeekNumbers
                            calendarClassName="zIndex"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                        
                        </div>
                    
                    <div className='custom-search-input'>
                        <ReactDatePicker
                            selected={value.end_date}
                            onChange={date => handleInputChange({
                                target:{
                                    name:'end_date',
                                    value:date
                                }
                            })}
                            selectsEnd
                            startDate={value.start_date}
                            endDate={value.end_date}
                            minDate={value.start_date}
                            className='end-date'
                            placeholderText="Hasta"
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            locale='es'
                            showWeekNumbers
                            calendarClassName="zIndex"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                    </div>
                    
                    <Tippy content='Prioridad' placement='top'>
                        <div className='custom-search-input'>
                            <select 
                                required 
                                value={value.priority}
                                name='priority'
                                onChange={handleInputChange}
                            >
                                <option value=''>--Prioridad--</option>
                                
                                {
                                    ticket_priorities && ticket_priorities.map( priority => {
                                        return <option 
                                            value={priority.id}
                                            key={priority.id}
                                        >
                                            {priority.name}
                                            </option>;
                                    })
                                }
                            </select>
                        </div>
                    </Tippy>
                    
                    <Tippy content='Estado del ticket' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete="off"
                                list="status_filter" 
                                id="status_filter_id" 
                                value={value.status}
                                name='status'
                                onChange={handleInputChange}
                                placeholder='Estado'
                                onFocus={() => {
                                    document.getElementById('status_filter').style.display='block'
                                }}
                            />

                            <datalist 
                                id="status_filter"
                                onClick={() => {
                                    document.getElementById('status_filter').style.display='none'
                                }}
                            >
                                {
                                    ticket_status && ticket_status.map( stat => {
                                        return <option 
                                            value={stat.name}
                                            key={stat.id}
                                        >
                                            {stat.name}
                                            </option>;
                                    })
                                }
                            </datalist>
                        </div>
                    </Tippy>
                </>
            }

            {
                (type.ticketOrTask && type.ticketOrTask === '2') &&

                <>
                    <Tippy content='Nº de la tarea' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete='off'
                                type='text' 
                                placeholder='Nº Tarea'  
                                value={value.slug}
                                name='slug'
                                onChange={handleInputChange}
                            />
                        </div>
                    </Tippy>
                    
                    <Tippy content='Contenido de la tarea' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete='off'
                                type='text' 
                                placeholder='Contenido'  
                                value={value.title}
                                name='title'
                                onChange={handleInputChange}
                            />
                        </div>
                    </Tippy>
                    
                    <Tippy content='Búsqueda por empresa' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete="off"
                                list="company_filter" 
                                id="company_filter_id" 
                                value={value.company}
                                name='company'
                                onChange={handleInputChange}
                                placeholder='Empresa'
                                onFocus={() => {
                                    document.getElementById('company_filter').style.display='block'
                                }}
                            />

                            <datalist 
                                id="company_filter"
                                onClick={() => {
                                    document.getElementById('company_filter').style.display='none'
                                }}
                            >
                                {
                                    companies && companies.map( company => {
                                        return <option 
                                            value={company.name}
                                            key={company.id}
                                        >
                                            {company.name}
                                        </option>
                                    })
                                }
                            </datalist>
                        </div>
                    </Tippy>
                                        
                    <Tippy content='Búsqueda por usuario' placement='top'>
                        <div className='custom-search-input'>
                            <input 
                                autoComplete="off"
                                list="user_filter" 
                                id="user_filter_id" 
                                value={value.user}
                                name='user'
                                onChange={handleInputChange}
                                placeholder='Usuario'
                                onFocus={() => {
                                    document.getElementById('user_filter').style.display='block'
                                }}
                            />

                            <datalist 
                                id="user_filter"
                                onClick={() => {
                                    document.getElementById('user_filter').style.display='none'
                                }}
                            >
                                {
                                    deriveUsers && value.company 
                                    ? deriveUsers.filter( user => testt(user, value.company))
                                        .map( user => {
                                                return <option 
                                                    value={user.username}
                                                    key={user.id}
                                                >
                                                    {user.username}
                                                    </option>;
                                            })
                                    : deriveUsers.map( user => {
                                        return <option 
                                            value={user.username}
                                            key={user.id}
                                        >
                                            {user.username}
                                            </option>;
                                    })
                                }
                            </datalist>
                        </div>
                    </Tippy>
                    
                    <Tippy content='Prioridad' placement='top'>
                        <div className='custom-search-input'>
                            <select 
                                required 
                                value={value.priority}
                                name='priority'
                                onChange={handleInputChange}
                            >
                                <option value=''>--Prioridad--</option>
                                {
                                    task_priorities && task_priorities.map( priority => {
                                        return <option 
                                            value={priority.id}
                                            key={priority.id}
                                        >
                                            {priority.name}
                                            </option>;
                                    })
                                }
                            </select>
                        </div>
                    </Tippy>

                                    
                    <div className='custom-search-input'>
                        <ReactDatePicker
                            selected={value.start_date}
                            onChange={date => handleInputChange({
                                target:{
                                    name:'start_date',
                                    value:date
                                }
                            })}
                            selectsStart
                            startDate={value.start_date}
                            endDate={value.end_date}
                            className='start-date'
                            placeholderText="Desde"
                            closeOnScroll={true} 
                            dateFormat="dd/MM/yyyy h:mm aa"
                            locale='es'
                            showWeekNumbers
                            calendarClassName="zIndex"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                        
                        </div>
                    
                    <div className='custom-search-input'>
                        <ReactDatePicker
                            selected={value.end_date}
                            onChange={date => handleInputChange({
                                target:{
                                    name:'end_date',
                                    value:date
                                }
                            })}
                            selectsEnd
                            startDate={value.start_date}
                            endDate={value.end_date}
                            minDate={value.start_date}
                            className='end-date'
                            placeholderText="Hasta"
                            closeOnScroll={true}
                            dateFormat="dd/MM/yyyy h:mm aa"
                            locale='es'
                            showWeekNumbers
                            calendarClassName="zIndex"
                            showTimeInput
                            shouldCloseOnSelect={false}
                        />
                    </div>
                    
                    <Tippy content='Urgencia del 1 al 10' placement='top'>
                        <div className='custom-search-input'>
                        <select 
                                required 
                                value={value.urgency}
                                name='urgency'
                                onChange={handleInputChange}
                            >
                                <option value=''>--Urgencia--</option>
                                {
                                    weights && weights.map( stat => {
                                        return <option 
                                            value={stat}
                                            key={stat}
                                        >
                                            {stat}
                                            </option>;
                                    })
                                }
                            </select>
                        </div>
                    </Tippy>
                    
                    <Tippy content='Estado de la tarea' placement='top'>
                        <div className='custom-search-input'>
                        <select 
                                required 
                                value={value.status}
                                name='status'
                                onChange={handleInputChange}
                            >
                                <option value=''>--Estado--</option>
                                {
                                    task_status && task_status.map( stat => {
                                        return <option 
                                            value={stat.id}
                                            key={stat.id}
                                        >
                                            {stat.name}
                                            </option>;
                                    })
                                }
                            </select>
                        </div>
                    </Tippy>
                    
                    <Tippy content='Categoría' placement='top'>
                        <div className='custom-search-input'>
                            <select 
                                required 
                                value={value.category}
                                name='category'
                                onChange={handleInputChange}
                            >
                                <option value=''>--Categoría--</option>
                                {
                                    categories && categories.map( category => {
                                        return <option 
                                            value={category.id}
                                            key={category.id}
                                        >
                                            {category.name}
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </Tippy>
                        
                </>
            }
            
            <Tippy content='Buscar información' placement='top'>
                <button 
                    className='custom-search-button cs-submit'
                    onClick={handleSubmit}
                >
                    <i className="fas fa-search"></i>
                </button>
            </Tippy>
            
            <Tippy content='Borrar formulario' placement='top'>
                <button className='custom-search-button cs-delete'
                    onClick={handleDelete}
                >
                    <i className="fas fa-eraser"></i>
                </button>
            </Tippy>
            
        </form>
        
        {
            error.status 
            && <p style={{
                color:'red', 
                display:'flex', 
                width:'60%', 
                margin:'0 auto',
                justifyContent:'center',
                padding:'.2rem',
                background:'rgba(255, 0, 0, 0.3)',
                border:'1px solid red',
                borderRadius:'.3rem'
            }}>
                { error.message }
            </p> 
        }
        </>
    )
}
