import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
	getCreateSwatchUserInfo,
	getSwatchUsersInfo,
	storeSwatchUser,
} from 'actions/swatch-users/get-swatch-user'
import { PATHS } from 'types/paths'

export const UserSwatchStatusChangeForm = () => {
	const swatchUserState = {
		swatch_user: '',
		swatch_role: '',
		is_destination: false,
		same_destination: false,
		enable_voucher: false,
	}
	const dispatch = useDispatch()
	const {push} = useHistory()
	const [error, setError] = useState({ status: false, message: '' })
	const [forceReset, setForceReset] = useState(true)
	const { users, roles } = useSelector((state) => state.users_list.create)
	const [swatchUser, setSwatchUser] = useState(swatchUserState)

	const {
		swatch_user,
		swatch_role,
		is_destination,
		same_destination,
		enable_voucher,
	} = swatchUser

	const handleChange = (e) => {
		setSwatchUser({
			...swatchUser,
			[e.target.name]: e.target.value,
		})
	}
	const handleCheckboxChange = (e) => {
		setSwatchUser({
			...swatchUser,
			[e.target.name]: e.target.checked,
		})
	}

	const handleCreateSwatchUser = async (type) => {
		if(type === 'swatch') {
			
		if (!swatchUser.swatch_user) {
			return setError({
				status: true,
				message:
					'Es necesario elegir el usuario a quien se le asignará un rol Swatch',
			})
		}
		if (!swatchUser.swatch_role) {
			return setError({
				status: true,
				message:
					'Es necesario elegir el nuevo rol Swatch que desempeñará el usuario',
			})
		}
		setError({ status: false, message: '' })

		const fd = new FormData(),
			user = users.filter((item) => item.username === swatch_user),
			role = roles.filter((item) => item.name === swatch_role)

		fd.append('user', user[0].id)
		fd.append('role', role[0].id)
		if (is_destination) {
			fd.append('is_destination', 1)
		}
		if (same_destination) {
			fd.append('same_destination', 1)
		}
		if (enable_voucher) {
			fd.append('enable_voucher', 1)
		}

		/* return console.log(user[0],role[0]) */
		await dispatch(storeSwatchUser(fd))
		await setForceReset(false)
		setForceReset(true)
		setSwatchUser(swatchUserState)
		dispatch(getSwatchUsersInfo('active'))
		return dispatch(getCreateSwatchUserInfo())
		} else if(type === 'regular') {
			return push(PATHS.createUsers)
		}

	}

	useEffect(() => {
		dispatch(getCreateSwatchUserInfo())
	}, [dispatch])
	return (
		<form className='swatch_user_status_change_form'>
			<div className='swatch_user_status_change_top'>
				<h6>Modificar rol Swatch</h6>
				{error.status ? (
					<span className='text-danger'>{error.message}</span>
				) : (
					<span className='swatch_user_status_change_top_info'>
						Seleccione el usuario al que desea modificar su rol Swatch
					</span>
				)}
			</div>
			<div className='swatch_user_status_change_body'>
				<div className='swatch_user_status_change_list'>
					<label htmlFor='swatch_user_status_change_input_list'>
						<small>Lista de usuarios</small>
					</label>
					<input
						id='swatch_user_status_change_input_user_list'
						list='swatch_user_status_change_datalist_users'
						placeholder='Usuarios'
						value={swatch_user}
						name='swatch_user'
						onChange={handleChange}
					/>
					<datalist id='swatch_user_status_change_datalist_users'>
						{users &&
							users.map((user) => (
								<option value={user.username} key={user.username}>
									{user.username}
								</option>
							))}
					</datalist>
				</div>

				<div className='swatch_user_status_change_list'>
					<label htmlFor='swatch_user_status_change_input_type_list'>
						<small>Roles</small>
					</label>
					<input
						id='swatch_user_status_change_input_type_list'
						list='swatch_user_status_change_datalist_types'
						placeholder='Roles'
						value={swatch_role}
						name='swatch_role'
						onChange={handleChange}
					/>
					<datalist id='swatch_user_status_change_datalist_types'>
						{roles &&
							roles.map((role) => (
								<option value={role.name} key={role.id}>
									{role.name}
								</option>
							))}
					</datalist>
				</div>

				<div className='swatch_user_status_change_checkboxes'>
					<div className='swatch_user_status_change_is_destination'>
						{forceReset && (
							<input
								type='checkbox'
								id='swatch_user_status_change_input_is_destination'
								name='is_destination'
								onChange={handleCheckboxChange}
							/>
						)}
						<label htmlFor='swatch_user_status_change_input_is_destination'>
							<small>Es destino</small>
						</label>
					</div>

					<div className='swatch_user_status_change_enable_voucher'>
						{forceReset && (
							<input
								type='checkbox'
								id='swatch_user_status_change_input_enable_voucher'
								name='enable_voucher'
								onChange={handleCheckboxChange}
							/>
						)}
						<label htmlFor='swatch_user_status_change_input_enable_voucher'>
							<small>Crear vouchers</small>
						</label>
					</div>

					<div className='swatch_user_status_change_same_destination'>
						{forceReset && (
							<input
								type='checkbox'
								id='swatch_user_status_change_input_same_destination'
								name='same_destination'
								onChange={handleCheckboxChange}
							/>
						)}
						<label htmlFor='swatch_user_status_change_input_same_destination'>
							<small>Mismo destino</small>
						</label>
					</div>
				</div>
			</div>

			<div className='swatch_user_status_change_bottom'>
				<button type='button' onClick={() => handleCreateSwatchUser('swatch')}>
					<span>
						<i className='fas fa-user-cog'></i>
						Crear usuario Swatch
					</span>
				</button>
				
				<button type='button' className='create_normal_user' onClick={() => handleCreateSwatchUser('regular')}>
					<span>
						<i className='fas fa-user-plus'></i>
						Crear usuario regular
					</span>
				</button>
			</div>
		</form>
	)
}
