import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { error, isLoading, isNotLoading } from "../ui";

export const getCreateTaskInfo = () => {
    return dispatch => {
        dispatch( isLoading() );
        
        Axios.get('api/tasks/create', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res =>{ 
            let aux = res.data.data;
            //console.log('recibir datos de tareas para crear', res.data.data);
            dispatch( setHeader(aux.title, aux.description) );
            dispatch(setCreateTaskInfo(
                aux.categories, 
                aux.dependencies, 
                aux.companies, 
                aux.priorities, 
                aux.deriveUsers, 
                aux.groups
            ));

            dispatch( isNotLoading() );
        })
        .catch( err => {
            console.log('error desde getcompanies and priorites',err);
            dispatch( isNotLoading() );
        })
    }
}

export const createTask = (form) => {
    return async (dispatch) => {
        dispatch( isLoading() );

        await Axios.post('api/tasks/create', form, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            //alert(res.data.message);
            dispatch( isNotLoading() );
        })
        .catch( err => {
            alert('Falló la creación del ticket');
            dispatch(error('Falló la creación del ticket'));
            dispatch( isNotLoading() );
        })
        .finally( final => {
            console.log(final);
        });
    }
}

export const setCreateTaskInfo = ( categories, dependencies, companies, priorities, deriveUsers, groups ) => ({
    type : TYPES.setCreateTask,
    payload : {
        categories,
        dependencies,
        companies,
        priorities,
        deriveUsers,
        groups
    }
})
