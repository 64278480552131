import React, { useState } from 'react'
import { CustomSearchBar } from '../NavBar/CustomSearchBar'
import { NewDataTable } from '../NewDataTable'
import { TaskDatatable } from '../Tasks/TaskDatatable'

export const CustomSearch = () => {
    const [table, setTable] = useState(true)
    return (
        <div className='seccion-tabla ticket-detail-special-margin'>
            <div className='seccion-tabla-interno'>
                <div 
                    className='seccion-detalle-ticket'
                    id={window.location.pathname.includes('custom/search') 
                    && 'tabla-busqueda__style'}
                >
                    <CustomSearchBar 
                        setTable={setTable}
                        table={table}
                    />
                    {
                        table
                        ? <NewDataTable />
                        : <TaskDatatable />
                    }
                </div>
            </div>
        </div>
            
    )
}
