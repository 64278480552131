
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { updateTaskState } from '../../actions/tasks/task-detail';
import { useForm } from '../../hooks/useForm';
import { PATHS } from '../../types/paths';

export const TaskCommentBox = ({slug, isTaken, status, hasTicket}) => {
    const history = useHistory();

    const dispatch = useDispatch();
    const [error, setError] = useState({
        status: false,
        message:''
    })

    const isDisabled = () => {
        if ((status && status.name === 'Finalizada') || (status && status.name === 'Finalizada aprobada') ) {
            console.log('true')
            return true;
        } else {
            return false;
        }
    }

    const taskToAprove = () => {
        if ((status && status.name === 'Finalizada')){
            return true;
        } else {
            return false
        }
    }

    /**##ACTIONS:
     * -'aproved'
     *  'take'
     *  'release'
     *  'solucionado'
     *  'reabierto'
     *  'comment'
     *  'commentTicket'
     */
    const [ value, handleInputChange, actualValue ] = useForm({
        content: '',
        action:'',
        ticketFile:''
    });

    const handleClick = async(e) => {
        
        /** Al hacer click se ha elegido una acción:
         * -Comentar
         * -Finalizar
         * -Take/Release
        */

        e.preventDefault();
        let buttonValue = '';

        if(e.target.innerHTML) {
            buttonValue = e.target.value;
        } else {
            buttonValue = e.target.parentNode.value;
        }
        
        handleInputChange({
            target : {
                name: 'action',
                value: buttonValue
            }
        });

        let valor = await actualValue();

        if( !valor.action ) {
                console.log('is not ');
                return;
            } 
            
        if((valor.action === 'solucionado' && !valor.content) || (valor.action === 'comment' && !valor.content)){
            console.log('no hay comentario, done, o comment no se pueden pasar', valor)
            document.getElementById('text-area-ticket-comment').setAttribute('required', true);
            return setError({
                status:true,
                message:'Se necesita hacer un comentario junto con la acción'
            });
        }

        let x = new FormData();

        x.append('ticketFile', valor.ticketFile);
        x.append('action', valor.action);
        x.append('content', valor.content);
        //console.log(slug)
        //console.log('valor', valor)
        await dispatch(updateTaskState(slug, x));    

        switch (valor.action) {
            case 'solucionado':
                return history.push(PATHS.generalTasks);
            case 'reabierto':
                return history.push(PATHS.generalTasks);
            default:
                return window.location.reload();
        }
    }

    
    const fileUpload = async(e) => {
        //console.log('carga de archivo',e.target.files[0]);
        handleInputChange({
            target: {
                name : [e.target.name],
                value : e.target.files[0]
            }            
        })

        //const valor = await actualValue();
        //console.log('valorrrrrrrr',valor)
    }

    return (
        <form 
            className='comment-box-form'
            encType="multipart/form-data"
        >

            <textarea 
                placeholder='Comentario...' 
                value={ value.content } 
                name='content'
                onChange={ handleInputChange } 
                id='text-area-ticket-comment'
                disabled= { isDisabled() }
            >
            </textarea>

            { //Caso de error
                error.status 
                && <small style={{ color:'red' }}>
                        {error.message} 
                    </small>
                
            }

            {  //Si se encuentra habilidata la tarea
                isDisabled()
                ? <div className='comment-box-buttons'>
                    <div>
                        <Tippy content='Reabrir la tarea' placement='bottom'>
                            {/**BOTÓN PARA REABRIR */}
                            <button 
                                className='comment-submit' 
                                name='action'
                                value='reabierto' 
                                onClick={ handleClick }
                            >
                                Reabrir
                            </button> 
                        </Tippy>
                        {
                            taskToAprove()
                            &&
                            <Tippy content='Aprobar la tarea' placement='bottom'>
                                <button 
                                    className='solved-submit' 
                                    name='action'
                                    value='aproved' 
                                    onClick={ handleClick }
                                >
                                    Aprobar
                                </button> 
                            </Tippy>
                        }
                    </div>
                    
                </div>
                : <div className='comment-box-buttons'>
                    
                    <div>
                        
                        {/**BOTÓN PARA COMENTAR */}
                        <button 
                            className='comment-submit' 
                            name='action'
                            value='comment' 
                            onClick={ handleClick }
                        >
                            Comentar
                        </button>

                        {/**BOTÓN PARA SOLUCIONAR */}
                        <Tippy content='Finalizar' placement='bottom'>
                            <button
                                className='solved-submit solve' 
                                name='action'
                                value='solucionado' 
                                onClick={ handleClick }
                            >
                                <i className="fas fa-check solve"></i>
                            </button>
                        </Tippy>
                        {
                            hasTicket
                            &&  /**BOTÓN PARA COMENTAR TICKET DESDE TAREA */
                            <Tippy content='Comentar en ticket' placement='bottom'>
                                <button
                                    className='ticket-task-submit' 
                                    name='action'
                                    value='commentTicket' 
                                    onClick={ handleClick }
                                >
                                    <i className="fas fa-comment-dots"></i>
                                </button>
                            </Tippy>
                        }

                        
                        {/**BOTÓN PARA TAKE/RELEASE */}
                        <Tippy content='Tomar/Soltar' placement='bottom'>
                            <button 
                                id='comment-take-release-button' 
                                name='action'
                                className={ isTaken ? 'release-submit tor': 'take-submit tor' } 
                                value={ isTaken ? 'release' : 'take' } 
                                onClick={ handleClick }
                            >
                                { 
                                    isTaken 
                                    ? <i className="far fa-hand-rock tor"></i> 
                                    : <i className="far fa-hand-paper tor"></i> 
                                }
                            </button>
                        </Tippy>

                    </div>
                    <div className="form-group file">

                        {/**INPUT PARA CARGAR ARCHIVO */}
                        <Tippy content='Solo imágenes' placement='bottom'>
                            <input 
                                type="file" 
                                className="form-control-file exampleFormControlFile1" 
                                name='ticketFile'
                                onChange={fileUpload}                            
                            />
                        </Tippy>
                    </div>
                </div>
            }
            
        </form>
    )
}
