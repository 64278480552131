import Tippy from '@tippyjs/react'
import React from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Loader } from '../../Loader/Loader'

export const UserDatatable = ({ filterText }) => {
	let auxUsers, filteredItems

	const history = useHistory()
	const { list: users } = useSelector((state) => state.user)
	const { loading } = useSelector((state) => state.ui)

	if (users?.length > 0) {
		auxUsers = users.map((user) => {
			return {
				_id: user.id,
				name: user.name,
				username: user.username,
				email: user.email,
				companies: user.companies,
				groups: user.groups,
				phone: user.phone,
				role: user.role?.name,
			}
		})

		filteredItems = auxUsers.filter(
			(user) =>
				user.name?.toLowerCase().includes(filterText.toLowerCase()) ||
				user.username?.toLowerCase().includes(filterText.toLowerCase()) ||
				user.email?.toLowerCase().includes(filterText.toLowerCase()) ||
				user.role?.toLowerCase().includes(filterText.toLowerCase()) ||
				user.phone?.toLowerCase().includes(filterText.toLowerCase()) ||
				user.groups?.some((group) =>
					group.name.toLowerCase().includes(filterText.toLowerCase())
				) ||
				user.companies?.some((company) =>
					company.name.toLowerCase().includes(filterText.toLowerCase())
				)
		)
	}

	const shortenText = {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	}

	const cols = [
		{
			grow: 1,
			name: 'Nombre',
			selector: 'name',
			sortable: true,
		},
		{
			grow: 1,
			name: 'Usuario',
			selector: 'username',
			sortable: true,
		},
		{
			grow: 1,
			name: 'Correo',
			selector: 'email',
			sortable: true,
		},
		{
			grow: 1,
			name: 'Empresas',
			selector: 'companies',
			cell: (row) => (
				<Tippy
					content={row.companies.map(({ name }) => name).join(', ')}
					placement='bottom'
				>
					<div style={shortenText}>
						{row.companies?.length ? (
							row.companies?.map((company, i) => (
								<span key={`${company.name}-${i}`}>{`${company.name}${
									row.companies.length - 1 !== i ? ',' : ''
								}`}</span>
							))
						) : (
							<span>-</span>
						)}
					</div>
				</Tippy>
			),
		},
		{
			grow: 1,
			name: 'Grupos',
			selector: 'groups',
			cell: (row) => (
				<Tippy
					content={row.groups?.map(({ name }) => name).join(', ')}
					placement='bottom'
				>
					<div style={shortenText}>
						{row.groups?.length ? (
							row.groups?.map((group, i) => (
								<span key={`${group.name}-${i}`}>{`${group.name}${
									row.groups.length - 1 !== i ? ',' : ''
								}`}</span>
							))
						) : (
							<span>-</span>
						)}
					</div>
				</Tippy>
			),
		},
		{
			grow: 1,
			name: 'Teléfono',
			selector: 'phone',
			sortable: true,
		},
		{
			grow: 1,
			name: 'Rol',
			selector: 'role',
			sortable: true,
		},
		{
			grow: 1,
			name: 'Opciones',
			cell: (row) => (
				<>
					<Tippy content='Editar' placement='top'>
						<button
							className='detail-new-tab'
							onClick={(e) => {
								e.preventDefault()
								history.push(`/panel/users/${row._id}/edit`)
							}}
						>
							<i className='fas fa-edit'></i>
						</button>
					</Tippy>
				</>
			),
		},
	]

	const pagOpts = {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItems: true,
		selectAllRowsItemText: 'Todos',
	}

	const customStyles = {
		rows: {
			style: {
				fontSize: '.7rem',
				cursor: 'pointer',
				'&:hover': {
					background: 'rgba(114, 107, 121, 0.2)',
				},
			},
		},
	}

	return (
		<DataTable
			columns={cols}
			data={filteredItems}
			pagination={filteredItems?.length < 10 ? false : true}
			paginationComponentOptions={pagOpts}
			/* onRowClicked={(row) => {
				return history.push(`/panel/config/${row._id}/detail`)
			}} */
			customStyles={customStyles}
			noDataComponent='No hay registros'
			/* conditionalRowStyles={conditionalRowStyles} */
			progressPending={loading}
			progressComponent={<Loader />}
		/>
	)
}
