import Tippy from '@tippyjs/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavMenu } from '../Buttons/NavMenu'
import { SecondaryTaskNavbar } from './SecondaryNavbar/SecondaryTaskNavbar'
import { SecondaryTicketNavbar } from './SecondaryNavbar/SecondaryTicketNavbar'

export const PanelHeader = () => {
    const { title } = useSelector(state => state.header);
    return (
        <header className='panel-header'>
            <div className='panel-ticket'>
                <div className='panel-ticket-title-description'>
                    <Tippy content={title} placement='bottom-start'>
                        <h2>{title}</h2>
                    </Tippy>
                </div>
                {window.location.pathname.includes('panel/ticket') 
                && <SecondaryTicketNavbar />}

                {window.location.pathname.includes('panel/task') 
                && <SecondaryTaskNavbar />}
            </div>
            <NavMenu />
        </header> 
    )
}
