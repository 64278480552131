import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { loadTask } from '../../actions/tasks/task-detail';
import { Comments } from '../Comments';
import { Interactions } from '../Interactions';
import { TaskCommentBox } from './TaskCommentBox';
import { TaskDerivedTo } from './TaskDerivedTo';

export const TaskDetail = () => {
    const { 
        detail,
        comments,
        interactions,
        time:timeSpent,
        update_info 
    } = useSelector(state => state.detail.task);

    const dispatch = useDispatch();

    const auxDerivedTask = () => {
        let aux;
        if(detail.derived_user) {
            aux = detail.derived_user.username;
        } else if(detail.derived_group) {
            aux = detail.derived_group.name;
        } 
        return aux;
    }


    let { slug } = useParams();

    useEffect( () => {
        dispatch(loadTask(slug))
    }, [ slug, dispatch ]);
    return (
        (<div className='seccion-tabla ticket-detail-special-margin'>
            <div className='seccion-tabla-interno'>
                <div className='seccion-detalle-ticket'>

                    <div className='seccion-detalle-2'>
                        <h5>Comentarios</h5>
                        <div className='seccion-detalle-comentarios'>
                            <Comments comments={ comments } />
                            
                        </div>
                        <div className='comment-box'>
                        
                        
                            <TaskCommentBox
                                slug={ detail.id } 
                                isTaken={detail.is_taken} 
                                status={detail.status}
                                hasTicket={ detail.ticket ? true : false }
                            /> 

                        </div>
                    </div>
                    
                    
                        
                    <div className='seccion-detalle-1'>
                        <h5>Datos de contacto</h5>
                            <div className='seccion-detalle-datos'>
                                <div className='seccion-detalle-datos-info'>
                                        
                                    <div>
                                        <p>Creada por: </p>
                                        <span>{ detail.user && detail.user.name }({ detail.user && detail.user.username }) </span>
                                    </div>
                                    <div>
                                        <p>Empresa: </p>
                                        <span>{ detail.company && detail.company.name } </span>
                                    </div>
                                    <div>
                                        <p>Ticket asociado:</p>
                                        {
                                            detail.ticket
                                            && <small>
                                                <Link 
                                                    to={`/panel/ticket/${ detail.ticket.slug }/detail`}
                                                    target="_blank"
                                                >
                                                    Ticket #{detail.ticket.slug}("{detail.ticket.title}")
                                                </Link>
                                            </small>
                                        }
                                    </div>
                                    <div>
                                        <p>
                                            {
                                                detail.task_dependency_id 
                                                ? 'Esta tarea pertenece a:'
                                                : 'Esta tarea no posee una tarea padre'
                                            } 
                                        </p>
                                        <span>
                                            { 
                                                detail.task_dependency_id
                                                ? <Link to={`/panel/task/${detail.task_dependency_id}/detail`}>
                                                    {`Tarea #${detail.task_dependency_id}(${detail.dependency.title})`}
                                                </Link>
                                                : null
                                            } 
                                        </span>
                                    </div>
                                    <div>
                                        <p>Derivado:</p>
                                        <span>
                                            { auxDerivedTask() /*String con el nombre, user o "-" de derivado */}
                                        </span>
                                    </div>
                                    <div>
                                        <p>Tiempo invertido: </p>
                                        <span>{ timeSpent ? `${ timeSpent } mins` : '-' }</span>
                                    </div>
                                    <div>
                                        <p>Usuarios con interacción: </p>
                                        <ul>
                                            { 
                                                update_info.task_users && update_info.task_users.map( (user,i) => {
                                                    return (<li key={i}>{user.username}</li>);
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div>
                                        <p>Tipo: </p>
                                        <span>{ detail.task_type ? detail.task_type.name : '-' }</span>
                                    </div>
                                    <div>
                                        <p>Urgencia: </p>
                                        <span>{ detail.weigth ? detail.weigth : '-' }</span>
                                    </div>
                                    
                                    <div>
                                        <p>Desde: </p>
                                        <span>{ detail.start_date ? detail.start_date : '-' }</span>
                                    </div>
                                    <div>
                                        <p>Hasta: </p>
                                        <span>{ detail.end_date ? detail.end_date : '-' }</span>
                                    </div>

                                    
                                    <div>
                                        <p>Prioridad: </p>
                                        <span>{ detail.task_priority ? detail.task_priority.name : '-' }</span>
                                    </div>
                                    
                                    
                                </div>
                                <div>
                                    <button
                                        className='seccion-detalle-datos-usuario'
                                        disabled
                                    >
                                        Datos de usuario
                                    </button>
                                </div>

                            </div>
                    </div>
                    
                    <div className='seccion-detalle-3'>
                        <h5>Interacciones</h5>
                        <div className='seccion-detalle-interaccion'>
                            
                            <Interactions interactions={ interactions }/>
                            <div className='detalle-interaccion-fin'>
                                        <div className='detalle-interaccion-fecha'>
                                            <p> </p>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    
                    <div className='seccion-detalle-4'>
                        <h5> Actualizar tarea </h5>
                        <div className='seccion-detalle-actualizacion'>
                            <TaskDerivedTo slug={slug} />   
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    )
}
