import { TYPES } from '../types/types'

const defaultState = {
	list: [],
	create: {
		companies: [],
		roles: [],
		userList: [],
		currentUser: null,
	},
}

export const userReducer = (state = defaultState, action) => {
	switch (action.type) {
		case TYPES.setUsersList:
			return {
				...state,
				list: [...action.payload],
			}
		case TYPES.setUserProfile:
			return {
				...state,
				...action.payload,
			}

		case TYPES.setCreateUserInfo:
			return {
				...state,
				create: {
					...action.payload,
				},
			}

		case TYPES.deleteUserInfo:
			return {}

		default:
			return state
	}
}
