import React from 'react'
import { ChartColorLegend } from '../ChartColorLegend'
//Primera fila
import { AssignedTaskChart } from './Divisions/First row/AssignedTaskChart'
import { GroupTaskChart } from './Divisions/First row/GroupTaskChart'
import { UnassignedTaskChart } from './Divisions/First row/UnassignedTaskChart'

//Segunda fila
import { DailyClosedTasksChart } from './Divisions/Second row/DailyClosedTasksChart'
import { MonthlyClosedTasksChart } from './Divisions/Second row/MonthlyClosedTasksChart'

//Tercera fila
import { TaskBarChart } from './BarChart/TaskBarChart'

export const TaskChart = () => {
    return (
        <div 
            className='general_charts_division'
        >
            <p>Tareas</p>

        
            <div className='general_charts_pie_n_board'>
                <div //Primera fila
                    className='general_charts_tasks'
                >
                    <small>Abiertas</small>
                    <div className='general_charts_tasks_flex'>
                        <AssignedTaskChart />
                        <div
                            className='general_charts_side'
                        >
                            <GroupTaskChart />
                            <UnassignedTaskChart />
                        </div>
                    </div>
                    <ChartColorLegend 
                        total={true} 
                        tasks={true}
                    />
                </div>

                <div //Segunda fila
                    className='general_charts_boards'
                >
                    <div className='general_charts_daily'>
                        <DailyClosedTasksChart />
                    </div>
                    <div className='general_charts_monthly'>
                        <MonthlyClosedTasksChart />
                    </div>
                </div>
            </div>

            <div //Tercera fila
                className='general_charts_bar'
            >
                <small>Rango general semanal</small>
                <TaskBarChart />
                {/**<ChartColorLegend 
                    tasks={true}
                /> */}
            </div>
        </div>
                
    )
}
