import Tippy from '@tippyjs/react'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getSubscriptionsAndTopicsInfo } from '../../../../actions/subscriptions/subscriptions'
import { storeSwatchUser } from '../../../../actions/swatch-users/get-swatch-user'

export const SubscriptionsConfirmation = (props) => {
    const dispatch = useDispatch(),
    handleConfirm = async() => {
      const {subscriptionDispatcher, code, fd,stateResetter,stateToReset} = props.show.info
      await dispatch(subscriptionDispatcher(fd,code))
      stateResetter(stateToReset)
      dispatch(getSubscriptionsAndTopicsInfo('index'));
      return props.onHide()
    }

  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='subscriptions_edit'
    >
      <Modal.Header closeButton className='subscriptions_edit_header'>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>Es necesario una confirmación</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='subscriptions_edit_body m-0'>
        <p>Para poder realizar la siguiente opcion: </p>
        <small>{props.show.info?.option}</small>
        <p>es necesario que confirme con el botón de enviar</p>
  
        <Button
          variant="success" 
          onClick={handleConfirm}
        >
          <i className="fas fa-clipboard-check"></i>Enviar
        </Button>
      </Modal.Body>
      <Modal.Footer className='subscriptions_edit_footer'>

      </Modal.Footer>
    </Modal>
  )
}
