import React, { useMemo, useState } from 'react';
import Datatable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setTakeOrRelease } from '../actions/tickets';
import { formatDateWithTime, unsetFormat } from '../helpers/helpers';
import { TextField, ClearButton, ToRButton } from './Datatables/styledComponents';

import '../styles/NewDatatable.css'

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <Tippy content='Filtro de palabras' placement='left'>
            <TextField 
                id="search" 
                type="text" 
                placeholder="Buscar..." 
                aria-label="Search Input" 
                value={filterText} 
                onChange={onFilter} 
            />
        </Tippy>

        <Tippy content='Borrar filtro' placement='top'>
            <ClearButton 
                type="button" 
                onClick={onClear}
            >
                <i className="fas fa-eraser"></i>
            </ClearButton>
        </Tippy>
    </>
  );

export const NewDataTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [filterText, setFilterText] = useState('');

    const {tickets} = useSelector(state => state.storage);

    let auxTickets;
    let filteredItems;

    const auxDerived = (ticket) => {
        let aux;
        if (ticket.derived_to) {
            if(ticket.derived_user[0]) {
                aux = ticket.derived_user[0].username;
            } else if(ticket.derived_group[0]) {
                aux = ticket.derived_group[0].name;
            } 
        } else {
            aux = '~'
        }

        return aux;
    }

    if ( tickets.length > 0 ) {
        auxTickets = tickets.map( (ticket) => {
            return {
                slug: ticket.slug, 
                title: ticket.title,
                topic: (ticket.topic ? ticket.topic.name : '~'),
                user: (ticket.user ? `${ticket.user.lastname ? ticket.user.lastname : ticket.user.name} ${ticket.user.lastname ? ticket.user.name.charAt(0) + '.' : ''}` : '~' ),
                username: (ticket.user ? ticket.user.username : '~' ),
                company: (ticket.company ? ticket.company.name : '~' ),
                date: formatDateWithTime(ticket.created_at),
                derived: auxDerived(ticket),
                priority: ticket.priority,
                status: ticket.status.name,
                is_taken: ticket.is_taken,
                agent: ticket.agent
            }
        })

        //console.log(auxTickets[0].slug, "slug")
        filteredItems = auxTickets.filter(item => (item.slug && (item.slug.toString()).includes(filterText))
                                                    || (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.topic && item.topic.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.user && item.user.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.company && item.company.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.date && item.date.includes(filterText)) 
                                                    || (item.derived && item.derived.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.priority && item.priority.name.toLowerCase().includes(filterText.toLowerCase())) 
                                                    || (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) 
                                            );
        //console.log(filteredItems)
    }
    
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    //console.log(auxTickets);
    
    const cols = [
        {   
            grow: 1,
            name:'#',
            selector:'slug',
            sortable: true
        },
        { 
            grow: 2, 
            name:'Título',
            selector:'title',
            sortFunction: (a,b) => {
                if(a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                if(a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                return 0;
            },
            sortable: true,
        },
        { 
            grow: 1, 
            name:'Tópico',
            selector:'topic',
            sortable: true
        },
        { 
            grow: 1, 
            name:'Nombre',
            selector:'user',
            sortFunction: (a,b) => {
                if(a.user.toLowerCase() > b.user.toLowerCase()) return 1;
                if(a.user.toLowerCase() < b.user.toLowerCase()) return -1;
                return 0;
            },
            sortable: true,
            cell: row => 
                <Tippy content={`${row.username}`} placement='right'>
                    <small className='ticket-date'>{row.user}</small>
                </Tippy>
        },
        { 
            grow: 1, 
            name:'Empresa',
            selector:'company',
            sortable: true,
            sortFunction: (a,b) => {
                if(a.company.toLowerCase() > b.company.toLowerCase()) return 1;
                if(a.company.toLowerCase() < b.company.toLowerCase()) return -1;
                return 0;
            },
        },
        { 
            grow: 1, 
            name:'Fecha',
            selector:'date',
            sortable: true,
            sortFunction: (a,b) => {
                return unsetFormat(b.date) - unsetFormat(a.date)
            },
            cell: row => <small className='ticket-date'>{row.date}</small>
        },
        {
            grow: 1, 
            name:'Derivado',
            selector:'derived',
            sortFunction: (a,b) => {
                if(a.derived.toLowerCase() > b.derived.toLowerCase()) return 1;
                if(a.derived.toLowerCase() < b.derived.toLowerCase()) return -1;
                return 0;
            },
            sortable: true
        },
        { 
            grow: 1, 
            name:'Prioridad',
            selector:'priority',
            sortable: true,
            sortFunction: (a,b) => {
                return parseInt(b.priority.id) - parseInt(a.priority.id)
            },
            cell: row => <small className='ticket_priority'>{row.priority.name}</small>
        },
        { 
            grow: 1, 
            name:'Estado',
            selector:'status',
            sortable:true,
            sortFunction: (a,b) => {
                if(a.status.toLowerCase() > b.status.toLowerCase()) return 1;
                if(a.status.toLowerCase() < b.status.toLowerCase()) return -1;
                return 0;
            },
            cell: row => <div className='d-flex flex-column align-items-center justify-content-center ticket-status'>
                            <p className='p-0 m-0'>{row.status}</p>
                            {row.agent && <p className='p-0 m-0'> <i className="fas fa-user-lock"></i> {row.agent.username}</p>} 
                        </div>
        },
        { 
            grow: 1,
            name:'Opciones',
            cell: row => 
                <>
                    <Tippy content='Abrir en nueva pestaña' placement='top'>
                        <button
                            className='detail-new-tab'
                            onClick={e => {
                                e.preventDefault();
                                window.open(`/panel/ticket/${ row.slug }/detail`);
                            }}
                        >
                            <i className="fas fa-external-link-alt"></i>
                        </button>
                    </Tippy>
                {
                    (row.status !== 'Resolucion pendiente' 
                        && row.status !== 'Cerrado' 
                        && row.status !== 'Solucionado') && 
                    <Tippy content='Tomar/Soltar' placement='top'>
                        <ToRButton 
                            value={ (row.is_taken) ? 'release' : 'take' } 
                            className={ (row.is_taken ? 'release-submit': 'take-submit') + ' testtor' } 
                            type='button' 
                            onClick={ async(e) => {
                                e.preventDefault();
        
                                const fd = new FormData();
                                fd.append('comment', '');
                                fd.append('action', row.is_taken ? 'release' : 'take');
                                
                                await dispatch(setTakeOrRelease(row.slug, fd ));
                                history.push('/panel/ticket/general');
                            }}
                            >
                            { row.is_taken ? <i className="far fa-hand-rock testtor"></i> : <i className="far fa-hand-paper testtor"></i> }
                        </ToRButton>
                    </Tippy>
                }
                </>,
            button:true,
        },
    ]

    const conditionalRowStyles = [
        {
            when: row => row.priority === 'Emergencia',
            style: {
                backgroundColor: 'rgb(255, 71, 71)',
                color:'white',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'red' ,
                }
            }
        },
        {
            when: row => row.is_taken,
            style: {
                backgroundColor: 'yellow',
                transition:'.3s all linear',
                '&:hover': {
                    background: 'rgb(255, 230, 0)' ,
                }
            }
        },
    ];

    /**
     * if( window.location.pathname.includes('ticket/derived') ){
        cols.splice(5,0, {
            grow: 1, 
            name:'Derivado',
            selector:'derived',
            sortable: true
        } )
    }
     */
        

    const pagOpts = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItems: true,
        selectAllRowsItemText:'Todos'
    };

    const customStyles = {
        rows:{
            style: {
                fontSize:'.7rem',
                cursor:'pointer',
                '&:hover': {
                    background: 'rgba(114, 107, 121, 0.2)'
                }
            }
        }
    }

    return (
        <>
        <div className='seccion-tabla' id={window.location.pathname.includes('custom/search') ? 'ticket-tabla-busqueda' : undefined}>
            <div className='seccion-tabla-interno' id={window.location.pathname.includes('custom/search') ? 'ticket-tabla-busqueda-in' : undefined}>
                <Datatable 
                    columns={cols} 
                    data={filteredItems} 
                    pagination={(auxTickets && auxTickets.length < 10) ? false : true}
                    paginationComponentOptions={pagOpts}
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader={window.location.pathname.includes('custom/search') ? false : true}
                    subHeaderWrap={true}
                    subHeaderComponent={ subHeaderComponentMemo }
                    fixedHeaderScrollHeight='70px'
                    persistTableHead
                    onRowClicked={ row => {
                        return history.push(`/panel/ticket/${ row.slug }/detail`)
                    } }
                    customStyles={customStyles}
                    noDataComponent='No hay registros'
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        </div>
    
            
        </>
    )
}
