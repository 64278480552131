import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { isLoading, isNotLoading } from "../ui";

export const getSwatchUsersInfo = (active) => {
  return async(dispatch) => {
    dispatch(isLoading());

    await Axios.get(`api/swatch-users/${active}/index`,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        dispatch( setUsersInfo(res.data.user_list) );
        dispatch( setHeader(res.data.title, res.data.description) );
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

export const getCreateSwatchUserInfo = () => {
  return async(dispatch) => {
    dispatch(isLoading());

    await Axios.get('api/swatch-users/create',{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        dispatch( setCreateUsersInfo(res.data.available_users,res.data.swatch_roles) );
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

export const storeSwatchUser = fd => {
  return async(dispatch) => {
    dispatch(isLoading());
    await Axios.post(`api/swatch-users/store`, fd,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        alert(res.data.message)
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}


export const getSwatchUserDetail = (_id) => {
  return async(dispatch) => {
    dispatch(isLoading());

    await Axios.get(`api/swatch-users/${_id}/detail`,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        console.log(res.data.user)
        dispatch( setSwatchUsersDetail(res.data.user,res.data.roles) )
        dispatch( setHeader(res.data.title, res.data.description) );
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

export const updateSwatchUser = (fd,_id) => {
  return async(dispatch) => {
    dispatch(isLoading());
    await Axios.post(`api/swatch-users/${_id}/detail`, fd,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        alert(res.data.message)
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

export const deleteSwatchUser = (_id) => {
  return async(dispatch) => {
    dispatch(isLoading());
    await Axios.post(`api/swatch-users/${_id}/delete`,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        alert(res.data.message)
        dispatch( isNotLoading() );
        return window.location.href = '/panel/config/swatch-users'
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}


export const getOtInfo = (id) => {
  return async(dispatch) => {

    await Axios.get(`api/rma/manage-rma-info/${id}`,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        dispatch( setOtInfo(res.data) );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
      })
    }
}

export const getEditOtInfo = () => {
  return async(dispatch) => {
    dispatch(isLoading());

    await Axios.get('api/rma/manage-rma',{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        dispatch( setEditOtInfo(res.data) );
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

export const updateOtInfo = (data) => {
  return async(dispatch) => {
    dispatch(isLoading());
    await Axios.post('api/rma/manage-rma', data,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        console.log(res)
        alert(res.data.message)
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}


const setUsersInfo = list => ({type: TYPES.setUsersInfo, payload: list })
const setCreateUsersInfo = (users,roles) => ({
  type: TYPES.setCreateSwatchUsersInfo, 
  payload: {
    
    users,roles
  }})
const setSwatchUsersDetail = (detail,roles) => ({ type: TYPES.setSwatchUsersDetail, payload: {detail,roles}})
const setEditOtInfo = (payload) => ({ type: TYPES.otEdit, payload })
const setOtInfo = (payload) => ({ type: TYPES.setOtInfo, payload })


