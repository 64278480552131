import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { isLoading, isNotLoading } from "../ui";

export const users = ({ type, url = "", method = "GET", data = {} }) => {
  return async (dispatch) => {
    dispatch(isLoading());

    await Axios({
      url: `api/users/${url}`,
      method,
      data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        type,
      },
    })
      .then(
        ({
          data: {
            companies = [],
            roles = [],
            userList = [],
            currentUser = {},
            title,
            description,
          },
        }) => {
          if (type === "activo" || type === "inactivo") {
            dispatch(setUsersList(userList));
          }
          if ((url === "create" || url.includes("edit")) && method === "GET") {
            dispatch(setCreateUserInfo({ companies, roles, currentUser }));
          }
          dispatch(setHeader(title, description));
          dispatch(isNotLoading());
          return Promise.resolve();
        }
      )
      .catch((err) => {
        // console.log(err.response?.data?.message)
        const error =
          err.response?.data?.message ||
          "Parece que no se puede procesar la petición";
        alert(error);
        // dispatch(error('Parece que no se puede procesar la petición'))
        dispatch(isNotLoading());
        return Promise.reject(error);
      });
  };
};

export const setUsersList = (users = []) => ({
  type: TYPES.setUsersList,
  payload: [...users],
});
export const setCreateUserInfo = ({ companies, roles, currentUser }) => ({
  type: TYPES.setCreateUserInfo,
  payload: { companies, roles, currentUser },
});
