import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Loader } from '../Loader/Loader';
//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { formatDateWithTime } from '../../helpers/helpers';
import { SubscriptionsRowOptions } from './SubscriptionsRowOptions';


export const SubscriptionsDatatable = ({filterText, setSimple}) => {
  let auxSubs, filteredItems;
  
  const history = useHistory();
  const {subscriptions} = useSelector(state => state.subscriptions);
  const { loading } = useSelector(state => state.ui)
  
  if ( subscriptions.length > 0 ) {
    auxSubs = subscriptions.map( sub => {
      return {
        _id: sub.id,
        name: sub.name,
        created_at: sub.created_at,
        companies: sub.companies && sub.companies.length ? sub.companies : 'No hay compañía con este abono'
      }
    })

    filteredItems = auxSubs.filter(user => 
      (user.name && user.name.toLowerCase().includes(filterText.toLowerCase())) 
      || (user.companies && user.companies.toLowerCase().includes(filterText.toLowerCase())) 
    );

  }

  const cols = [
    {   
      grow: 0.5,
      name:'#',
      selector:'_id',
      sortable: true
    },
    {   
      grow: 1,
      name:'Abono',
      selector:'name',
      sortable: true
    },
    { 
      grow: 1, 
      name:'Compañías',
      selector:'companies',
      sortable: true,
      cell: row => (
        Array.isArray(row.companies) ?
        <Tippy content={ row.companies.reduce((a, b, i) => {
          if(row.companies.length === 1) {
            return `${a}${b.name}`            
          } else {
            if(i + 1 === row.companies.length){
              return `${a} y ${b.name}`
            } else if(i + 1 === row.companies.length - 1){
              return `${a}${b.name}`
            } else {
              return `${a}${b.name}, `
            }
          }
        },'') } placement='right'>
          <i 
            className="fas fa-clipboard-list"
            style={{
              backgroundColor:'var(--midcolor)',
              color:'#fff',
              padding:'5px',
              borderRadius:'50%',
              margin:'0 auto'
            }}
          ></i>
        </Tippy> :
        <>{row.companies}</>
      )
    },
    {   
      grow: 1,
      name:'Creado',
      selector:'created_at',
      sortable: true,
      cell: row => <>{formatDateWithTime(row.created_at)}</>
    },
    {
      grow: 0.5,
      name:'Opciones',
      cell: row => <SubscriptionsRowOptions setSimple={setSimple} id={row._id} />
    }
  ]
    const pagOpts = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItems: true,
        selectAllRowsItemText:'Todos'
    };

    const customStyles = {
        rows:{
            style: {
                fontSize:'.7rem',
                cursor:'pointer',
                '&:hover': {
                    background: 'rgba(114, 107, 121, 0.2)'
                }
            }
        }
    }

    return (
      <DataTable
        columns={cols} 
        data={filteredItems} 
        pagination={(filteredItems && filteredItems.length < 10) ? false : true}
        paginationComponentOptions={pagOpts}
        /* onRowClicked={ row => {
            return history.push(`/panel/config/${ row._id }/detail`)
        } } */
        customStyles={customStyles}
        noDataComponent='No hay registros'
        progressPending={loading}
        progressComponent={<Loader />}
      />
    )
}
