import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../../types/paths'
import { UserDataFilter } from './UserDataFilter'
import { UserDatatable } from './UserDatatable'
import { UserForm } from './UserForms/UserForm'
import { UsersGeneralTitle } from './UsersGeneralTitle'

export const RegularUsersPanel = () => {
	const title = () => {}
	const { loading } = useSelector((state) => state.ui)
	const [filterText, setFilterText] = useState('')
	return (
		<div className='seccion-tabla panel_usuarios'>
			<div className='seccion-tabla-interno'>
				<div className='panel_usuarios_marco_interno'>
					<div className='panel_usuarios_izquierda ot'>
						<UsersGeneralTitle />
						<UserDataFilter
							filterText={filterText}
							setFilterText={setFilterText}
						/>
						<div className='panel_usuarios_crear_ticket'>
							<UserForm />
						</div>
					</div>
					<div className='panel_usuarios_derecha'>
						<div className='panel_usuarios_tipo_ticket'>
							<ul>
								<NavLink
									activeClassName='panel_usuarios_tipo_activo'
									to={PATHS.activeUsers}
								>
									Usuarios activos
								</NavLink>

								<NavLink
									activeClassName='panel_usuarios_tipo_activo'
									to={PATHS.inactiveUsers}
									disabled
								>
									Usuarios bloqueados
								</NavLink>
							</ul>
						</div>
						<div className='panel_usuarios_tabla'>
							<UserDatatable filterText={filterText} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
