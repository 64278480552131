import React, { useEffect } from 'react'
import { commentDate, commentClass } from '../helpers/helpers'

export const Comments = ({ comments }) => {
	let comentarios = []
	let comDate = []
	let comUser = []
	let comClass = []
	let comAttach = []

	let comkeys = Object.keys(comments)

	for (let i = 0; i < comkeys.length; i++) {
		comentarios.push(comments[comkeys[i]].comment)
		comDate.push(commentDate(comments[comkeys[i]].created_at))
		comUser.push(comments[comkeys[i]].user.name)
		comClass.push(comments[comkeys[i]].status.name)
		if (comments[comkeys[i]].attached_path) {
			comAttach.push(comments[comkeys[i]].attached_path)
		} else {
			comAttach.push('')
		}
	}

	useEffect(() => {
		if (comentarios.length > 0) {
			let auxLast = document.getElementById('last_comment').offsetTop
			document.getElementsByClassName(
				'seccion-detalle-comentarios'
			)[0].scrollTop = auxLast
		}
	}, [comentarios])
	return (
		<>
			{comentarios.map((com, i) => {
				return (
					<div
						key={i}
						className={
							commentClass(comClass[comentarios.indexOf(com)]) + ' someComment'
						}
					>
						<div>
							<p>{comDate[comentarios.indexOf(com)]}</p>
							{commentClass(comClass[comentarios.indexOf(com)]).includes(
								'interno'
							) && <strong> [ INTERNO ]</strong>}
							<span>
								El usuario <strong>{comUser[comentarios.indexOf(com)]} </strong>{' '}
								ha comentado:
							</span>
						</div>
						<p className='comentario d-flex justify-content-between'>
							{com}
							{comAttach[comentarios.indexOf(com)] && (
								<a
									href={
										process.env.REACT_APP_IMAGE_SRC +
										comAttach[comentarios.indexOf(com)]
									}
									target='_blank'
									rel='noopener noreferrer'
									className='comment-attach-clip'
								>
									<i className='fas fa-paperclip'></i>
								</a>
							)}
						</p>
					</div>
				)
			})}

			<div id='last_comment'></div>
		</>
	)
}
