import React, { useMemo, useState } from 'react'
import Tippy from '@tippyjs/react'
import { ClearButton, TextField } from '../../Datatables/styledComponents'

const FilterComponent = ({ filterText, onFilter, onClear }) => {
	const handleChange = (e) => {
		onFilter(e.target.value)
	}
	return (
		<div className='panel_cliente_filter'>
			<Tippy content='Filtro de palabras' placement='top'>
				<TextField
					id='search'
					type='text'
					placeholder='Buscar...'
					aria-label='Search Input'
					value={filterText}
					onChange={handleChange}
				/>
			</Tippy>

			<Tippy content='Borrar filtro' placement='top'>
				<ClearButton type='button' onClick={onClear}>
					<i className='fas fa-eraser'></i>
				</ClearButton>
			</Tippy>
		</div>
	)
}

export const UserDataFilter = ({ filterText, setFilterText }) => {
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle)
				setFilterText('')
			}
		}

		return (
			<FilterComponent
				onFilter={setFilterText}
				onClear={handleClear}
				filterText={filterText}
			/>
		)
	}, [filterText, resetPaginationToggle, setFilterText])

	//console.log(`filterText`, filterText)
	return <>{subHeaderComponentMemo}</>
}
