import React from 'react';
import { useSelector } from 'react-redux';

export const MonthlyClosedTasksChart = () => {
    const { finishedMonthlyTasks:tasks } = useSelector(state => state.charts);
    const { user } = useSelector(state => state.auth);
    let data2 = [];
    if(tasks) {
        const {
            high,
            medium,
            low
        } = tasks;

        data2 = [
            { name: "Total", value: parseInt(high + medium + low) }
        ];
    }

    return (
        <div>
        <p>Cerradas mensuales <br />({user && user.username})</p>
            <b>{(data2[0] && Number.isInteger(data2[0].value)) ? data2[0].value : 0}</b>
        </div>
    )
}
