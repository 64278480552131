import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { isLoading, isNotLoading } from "../ui";

export const getSubscriptionsAndTopicsInfo = (code) => {
  return async(dispatch) => {
    dispatch(isLoading());

    await Axios.get(`api/subscriptions/${code}`,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        dispatch(setSubscriptions(res.data.subscriptions,res.data.groups)) 
        dispatch( setHeader('Abonos y tópicos', res.data.title) );
        return dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        return dispatch( isNotLoading() );
      })
    }
}


export const storeSubscription = (fd,code) => {
  return async(dispatch) => {
    dispatch(isLoading());
    await Axios.post(`api/subscriptions/${code}`, fd,{
      headers:{
        'Authorization':'Bearer ' + localStorage.getItem('token')
      }
    })
      .then( res => {
        alert(res.data.message)
        dispatch( isNotLoading() );
      })
      .catch( err => {
        alert('Parece que no se puede procesar la petición de información del usuario')
        dispatch( isNotLoading() );
      })
    }
}

const setSubscriptions = (subs,groups) => ({type: TYPES.setSubscriptions, payload: {subs, groups} })

