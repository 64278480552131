import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PATHS } from '../../types/paths';
import { Loader } from '../Loader/Loader';
import { SubscriptionsDataFilter } from './SubscriptionsDataFilter';
import { SubscriptionsDatatable } from './SubscriptionsDatatable';
import { SubscriptionsForm } from './SubscriptionsForms/SubscriptionsForm';
import { SubscriptionsConfirmation } from './SubscriptionsForms/SubscriptionsModals.js/SubscriptionsConfirmation';
import { SubscriptionsEdit } from './SubscriptionsForms/SubscriptionsModals.js/SubscriptionsEdit';


export const SubscriptionsIndex = () => {
  const {loading} = useSelector(state => state.ui);
  const {description} = useSelector(state => state.header);
  const [filterText, setFilterText] = useState('');

  const auxInitialState = {status:false,info:null},
    [simple, setSimple] = useState(auxInitialState),
    [confirmation, setConfirmation] = useState(auxInitialState),
    setModal = () => {
      setSimple(auxInitialState)
    },
    setModalConfirmation = () => {
      setConfirmation(auxInitialState)
    }
  return (
    <div className='seccion-tabla panel_usuarios'>
      <div className='seccion-tabla-interno'>
        <div className='panel_usuarios_marco_interno'>
          <div className='panel_usuarios_izquierda ot'>
            <div className='swatch_user_top_left'>
              <span>{loading ? <Loader /> :description}</span>
            </div>
            <SubscriptionsDataFilter
              filterText={filterText} 
              setFilterText={setFilterText} 
            />
            <div className='panel_usuarios_crear_ticket'>
              <SubscriptionsForm 
                setConfirmation={setConfirmation}
              />
            </div>
          </div>
          <div className='panel_usuarios_derecha'>
            <div className='panel_usuarios_tipo_ticket'>
              <ul>
                <NavLink
                  activeClassName='panel_usuarios_tipo_activo'
                  to={PATHS.topicsIndex}
                >
                  Abonos
                </NavLink>

               {/*  <NavLink 
                  activeClassName='panel_usuarios_tipo_activo'
                  to={PATHS.topics}
                >
                  Tópicos
                </NavLink> */}

              </ul>
            </div>
            <div className='panel_usuarios_tabla'>
              <SubscriptionsDatatable 
                filterText={filterText}
                setSimple={setSimple}
              />
            </div>
          </div>
        </div>
      </div>
      
      <SubscriptionsEdit
        show={simple}
        onHide={setModal}
        setConfirmation={setConfirmation}
      />
      
      <SubscriptionsConfirmation
        show={confirmation}
        onHide={setModalConfirmation}
      />
    </div>
    )
}

