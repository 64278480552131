import Axios from 'axios';
import { TYPES } from '../../types/types';
import { setHeader } from '../header';
import { error, isLoading, isNotLoading } from '../ui';


export const setTasks = type => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get(`api/${type}`,{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                console.log(res.data.data)
                dispatch( uploadTasks(res.data.data.data));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de las tareas'));
                dispatch( isNotLoading() );
            })
        }
}

export const setOpenTasks = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/open-tasks',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                console.log('tareas abiertas del server',res.data.data.data)
                dispatch( uploadTasks(Object.values(res.data.data.data)));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de las tareas'));
                dispatch( isNotLoading() );
            })
        }
}


export const setDerivedTasks = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/derived-tasks',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('tareas del server',res);
                dispatch( uploadTasks(Object.values(res.data.data.tasks)));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de las tareas'));
                dispatch( isNotLoading() );
            })
        }
}



export const setFinishedTasks = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/finished-tasks',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('tareas del server',res);
                dispatch( uploadTasks(res.data.data.tasks));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de las tareas'));
                dispatch( isNotLoading() );
            })
        }
}

export const setAprovedTasks = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/aproved-tasks',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('tareas del server',res);
                dispatch( uploadTasks(res.data.data.tasks));
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de las tareas'));
                dispatch( isNotLoading() );
            })
        }
}

export const uploadTasks = tasks => ({
    type: TYPES.setTasks,
    payload: tasks
})

export const deleteAllTasks = () => ({
    type: TYPES.deleteTasks
})