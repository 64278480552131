import React from 'react'
import { bg, COLORS } from './colors'

export const ChartColorLegend = ({total,tasks}) => {
    return (
        <div className='chart_legend'>
            <div className='chart_legend_single'>
                <div 
                    className='chart_legend_color'
                    style={{background:`${COLORS[0]}`}}
                ></div>
                <small 
                    className='chart_legend_name'
                    style={{color:`${COLORS[0]}`}}
                >
                    {tasks ? 'Alta' : 'Emergencia'}
                </small>
            </div>
            <div className='chart_legend_single'>
                <div 
                    className='chart_legend_color'
                    style={{background:`${COLORS[1]}`}}
                ></div>
                <small 
                    className='chart_legend_name'
                    style={{color:`${COLORS[1]}`}}
                >
                    {tasks ? 'Media' : 'Alta'}
                </small>
            </div>
            <div className='chart_legend_single'>
                <div 
                    className='chart_legend_color'
                    style={{background:`${COLORS[2]}`}}
                ></div>
                <small 
                    className='chart_legend_name'
                    style={{color:`${COLORS[2]}`}}
                >
                    {tasks ? 'Baja' : 'Media'}
                </small>
            </div>
            
            {!tasks 
            && <div className='chart_legend_single'>
                    <div 
                        className='chart_legend_color'
                        style={{background:`${COLORS[3]}`}}
                    ></div>
                    <small 
                        className='chart_legend_name'
                        style={{color:`${COLORS[3]}`}}
                    >
                        Baja
                    </small>
                </div>}
            
            {total
            && <div className='chart_legend_single'>
                    <div 
                        className='chart_legend_color'
                        style={{background:`${bg}`, border:'1px solid grey'}}
                    ></div>
                    <small 
                        className='chart_legend_name'
                        style={{color:'#000'}}
                    >
                        Total
                    </small>
                </div>
            }
        </div>
    )
}
