import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { stringForDate } from '../../helpers/helpers';
import { PATHS } from '../../types/paths';
import { Loader } from '../Loader/Loader';
import { SwatchUserDataFilter } from './SwatchUserDataFilter';
import { SwatchUserDatatable } from './SwatchUserDatatable';
import { SwatchUserForm } from './SwatchUserForms/SwatchUserForm';
import { SwatchUsersGeneralTitle } from './SwatchUsersGeneralTitle';

export const SwatchUserPanel = () => {
    const title = () => {

    }
    const {loading} = useSelector(state => state.ui);
    const [filterText, setFilterText] = useState('');
  return (
    <div className='seccion-tabla panel_usuarios'>
      <div className='seccion-tabla-interno'>
        <div className='panel_usuarios_marco_interno'>
          <div className='panel_usuarios_izquierda ot'>
            <SwatchUsersGeneralTitle />
            <SwatchUserDataFilter 
              filterText={filterText} 
              setFilterText={setFilterText} 
            />
            <div className='panel_usuarios_crear_ticket'>
              <SwatchUserForm />
            </div>
          </div>
          <div className='panel_usuarios_derecha'>
            <div className='panel_usuarios_tipo_ticket'>
              <ul>
                <NavLink
                  activeClassName='panel_usuarios_tipo_activo'
                  to={PATHS.panelConfigActiveSwatchUsers}
                >
                  Usuarios activos
                </NavLink>

                <NavLink 
                  activeClassName='panel_usuarios_tipo_activo'
                  to={PATHS.panelConfigInactiveSwatchUsers}
                  disabled
                >
                  Usuarios bloqueados
                </NavLink>

              </ul>
            </div>
            <div className='panel_usuarios_tabla'>
                <SwatchUserDatatable filterText={filterText} />
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
