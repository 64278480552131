import Axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

//Acción para enviar al reducer
import { login } from '../actions/auth'
import { Loader } from '../components/Loader/Loader'

//Componente de Login
import { Login } from '../components/Login'
import Panel from '../components/Panel'

//Componentes de prueba
//import { LoginExample } from '../examples/LoginExample';
//import { PanelExample } from '../examples/PanelExample';

import { PrivateRouter } from './PrivateRouter'
import { PublicRouter } from './PublicRouter'

function AppRouter() {
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)

	const [checking, setChecking] = useState(true)
	const [isLogged, setIsLogged] = useState(false)
	const urlParams = new URLSearchParams(window.location.search).get('token')

	const statusLogin = useCallback(async () => {
		if (urlParams?.length > 0) {
			localStorage.setItem('token', urlParams)
		}

		await Axios.get('api/auth-state', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
			.then((res) => {
				if (res.data.status === 'failure') {
					setIsLogged(false)
				} else {
					dispatch(login(res.data.user, res.data.role))
					setIsLogged(true)
				}
			})
			.catch(() => {
				setIsLogged(false)
			})

		setChecking(false)
	}, [dispatch, urlParams])

	useEffect(() => {
		if (user) {
			return setIsLogged(true)
		}

		statusLogin()
	}, [dispatch, setChecking, setIsLogged, user, statusLogin])

	if (checking) {
		return <Loader />
	}
	return (
		<Router>
			<div>
				<Switch>
					<PublicRouter path='/auth' component={Login} isAuth={isLogged} />

					<PrivateRouter path='/panel' component={Panel} isAuth={isLogged} />

					<Redirect to={`/auth`} />
				</Switch>
			</div>
		</Router>
	)
}

export default memo(AppRouter)
