import React from 'react'

export const Interactions = ({ interactions }) => {
    let entries = Object.entries(interactions);
    return (
        <div className='interaccion-dia'>
            { entries && entries.map( (entrie, key) => {
                return (
                    <div className='interaccion-dia' key={key}>
                        <div className='detalle-interaccion-fecha'>
                                    <p>{ entrie[0] }</p>
                        </div>

                        <div className='seccion-interaccion'>
                                {entrie[1].map( comentario => {
                                let d = new Date(comentario.created_at);
                                let full = d.getHours() + ':' + (d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes());
                                return <div key={comentario.id} className='detalle-interaccion'>
                                            <p><strong>{ full }</strong> {comentario.comment}</p>
                                        </div>
                            } )} 
                        </div>
                    </div>
                )
            }) }
        </div>
        
    )
}
