import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
//import { isLoading, isNotLoading } from "../ui";


export const getChartsInfo = () => {
    return async(dispatch) => {        
        await Axios.get('api/data-viz', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res =>{ 
            let aux = res.data.data;
            dispatch( setHeader(aux.title, aux.description) );
            dispatch( setChartsInfo(
                aux.tickets,
                aux.tasks,
                aux.ticketsTop,
                aux.tasksTop,
                aux.finishedDailyTickets,
                aux.finishedMonthlyTickets,
                aux.finishedMonthlyTasks,
                aux.finishedDailyTasks,
            ) );

        })
        .catch( err => {
            console.log('error desde getChartsInfo',err);
        })
    }
}

//const deleteChartInfo = () => ({type: TYPES.deleteChartInfo});

const setChartsInfo = (
    tickets, 
    tasks, 
    ticketsTop, 
    tasksTop,
    finishedDailyTickets,
    finishedMonthlyTickets,
    finishedMonthlyTasks,
    finishedDailyTasks,) => ({
    type: TYPES.setChartsInfo,
    payload: { 
        tickets, 
        tasks, 
        ticketsTop, 
        tasksTop,
        finishedDailyTickets,
        finishedMonthlyTickets,
        finishedMonthlyTasks,
        finishedDailyTasks,
    }
});

export const deleteChartInfo = () => ({ type: TYPES.deleteChartsInfo })