import Axios from "axios";
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { uploadTasks } from "../tasks/tasks";
import { uploadTickets } from "../tickets";
import { isLoading, isNotLoading } from "../ui";

export const getSearchInfo = () => {
    return async(dispatch) => {        
        await Axios.get('api/search-info', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res =>{ 
            let aux = res.data.data;
            //console.log('aux', aux)
            dispatch( setHeader(aux.title, aux.description) );
            dispatch( setSearchInfo(
                aux.categories, 
                aux.dependencies, 
                aux.companies, 
                aux.task_priorities, 
                aux.ticket_priorities, 
                aux.task_status,
                aux.ticket_status,
                aux.users, 
                aux.groups
            ) );

        })
        .catch( err => {
            console.log('error desde getSearchInfo',err);
        })
    }
}

export const sendSearchInfo = (fd, ticketOrTask) => {
    return async(dispatch) => {
        dispatch( isLoading() );
        await Axios.post('api/search-info', fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            if(parseInt(ticketOrTask) === 1){ //Ticket
                console.log('tickets', res.data.data.data.tickets)
                dispatch(uploadTickets(res.data.data.data.tickets));
                dispatch( isNotLoading() );
            } else if(parseInt(ticketOrTask) === 2){ //Task
                console.log('tickets', res.data.data)
                dispatch(uploadTasks(res.data.data.data));
                dispatch( isNotLoading() );
            }
        })
        .catch( err => {
            console.log('err',err)
        })
    }
}

const setSearchInfo = (cat, dep, comp, taskprior, ticketprior, taskstat, tickstat, der, grps) => ({
    type:TYPES.setSearchInfo,
    payload:{
        categories:cat,
        dependencies: dep, 
        companies: comp,
        task_priorities: taskprior,
        ticket_priorities: ticketprior,
        task_status: taskstat,
        ticket_status: tickstat,
        deriveUsers: der,
        groups: grps
    }
})