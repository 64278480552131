import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from "recharts";
import { COLORS } from "../../colors";

export const TicketBarChart = () => {
    const state = useSelector(state => state.charts.ticketsTop);
    let data = [];
    if(state && state.length > 0 ) {
        data = [...state];
        if(data.length > 5) {
            data = data.splice(-5,5)
            console.log(`data`, data)
        } else if(data.length < 5) {
            const toadd = parseInt(5 - data.length)
            for(let i = 0; i < toadd; i++) {
                data.push({"name":"-usuario-","Cantidad de tickets":0})
            }
        }
    }
  
    return (
        <div
            className='general_charts_line_chart'
        >
            <ResponsiveContainer
                width='100%'
                height='100%'
            >
                <BarChart
                    data={state && state.length > 0 ? data : null} 
                    margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 0
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Cantidad de tickets" stackId='a'>
                    {
                        data.map((entry, index) => (
                            <Cell 
                                key={`cell-${index}`} 
                                fill={COLORS[index]}
                            />
                        ))
                    }
                    </Bar>
                    
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}
