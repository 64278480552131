import React, { useState } from 'react'

import { Tabs, Tab } from 'react-bootstrap'
import { FormTab } from './components/FormTab'
import { UserSwatchStatusChangeForm } from './forms/UserSwatchStatusChangeForm'

const swatchStatus = Object.freeze({
	unassigned_user: 'no swatch',
	assigned_user: 'swatch user',
	modify_ot: 'swatch ot modify',
})

export const UserForm = () => {
	const [key, setKey] = useState(swatchStatus.unassigned_user)
	return (
		<Tabs id='controlled-ot-tabs' activeKey={key} onSelect={(k) => setKey(k)}>
			<Tab
				eventKey={swatchStatus.unassigned_user}
				title={
					<FormTab
						content={'Modificar estado Swatch'}
						iconClass={'fas fa-user-cog'}
					/>
				}
			>
				<div className='panel_cliente_crear_ticket ot'>
					<UserSwatchStatusChangeForm />
				</div>
			</Tab>
		</Tabs>
	)
}
