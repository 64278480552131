import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { hasHighAccess, hasPanelAccess } from '../../../helpers/permission'
import { PATHS } from '../../../types/paths'

export const SecondaryTicketNavbar = () => {
    const { name:role } = useSelector(state => state.auth.role);
    return (
        <nav className='panel-ticket-secondary-navbar'>
            <NavLink
                to={PATHS.generalTickets}
                activeClassName='sec-navbar-active'
            >
                <small>Mis tickets</small>
            </NavLink>
            {
                hasHighAccess(role)
                && <NavLink 
                        to={PATHS.openTickets}
                        activeClassName='sec-navbar-active'
                    >
                        <small>Abiertos</small>
                    </NavLink>
            }
            {
                hasPanelAccess(role)
                && <NavLink 
                        to={PATHS.derivedTickets}
                        activeClassName='sec-navbar-active'
                    >
                        <small>Derivados</small>
                    </NavLink>
            }
            
            <NavLink 
                to={PATHS.pendantTickets}
                activeClassName='sec-navbar-active'
            >
                <small>Pendientes</small>
            </NavLink>
            <NavLink 
                to={PATHS.solvedTickets}
                activeClassName='sec-navbar-active'
            >
                <small>Solucionados</small>
            </NavLink>
            <NavLink 
                to={PATHS.closedTickets}
                activeClassName='sec-navbar-active'
            >
                <small>Cerrados</small>
            </NavLink>
        </nav>
    )
}
