import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { users } from "actions/user/user";
import { PATHS } from "types/paths";

import { initialErrorState, initialUserFormState } from "./states";
import { checkUserInfoValidator, userInfoFormData } from "./validation";
import { CreateUsersCompanies } from "./components";

const CrearUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { companies, roles } = useSelector((state) => state.user.create);

  const [error, setError] = useState(initialErrorState);
  const [userInfo, setUserInfo] = useState(initialUserFormState);

  const { role } = userInfo;

  const handleChange = ({ target: { name, value } }) => {
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sustitución de valores por ID's rol
    const { id: roleId } = roles.find(
      ({ name }) => name.toLowerCase() === role.toLowerCase()
    );

    const processedInfo = {
      ...userInfo,
      role: `${roleId}`,
    };

    const userInfoArray = Object.entries(processedInfo);

    // Se evalúan los valores requeridos y se devuelve un
    // mensaje si es que falló la validación
    const message = checkUserInfoValidator(userInfoArray);

    // Si hay campos requeridos vacíos o con información errónea
    if (message)
      return setError({
        status: true,
        message,
      });

    // Se crea el formData con la información necesaria
    const validatedUserInfo = userInfoFormData(userInfoArray);

    setError(initialErrorState);

    dispatch(users({ url: "create", method: "POST", data: validatedUserInfo }))
      .then(() => history.push(PATHS.activeUsers))
      .catch((err) => console.warn("err", err));
  };

  return (
    <div className="seccion-tabla ticket-create-special-margin">
      <div className="seccion-tabla-interno">
        <form className="ticket-form" onSubmit={handleSubmit}>
          <div className="ticket-create-form">
            {error.status && (
              <p
                style={{
                  color: "red",
                  display: "flex",
                  width: "90%",
                  margin: "15px 10%",
                  justifyContent: "center",
                  padding: "1rem",
                  background: "rgba(255, 0, 0, 0.3)",
                  border: "1px solid red",
                  borderRadius: ".3rem",
                }}
              >
                {error.message}
              </p>
            )}
            <div className="ticket-create-part-a">
              <fieldset className="row justify-between">
                <div className="ticket-create-company col-md-6">
                  <label className="" htmlFor="user-create-name">
                    Nombre
                  </label>
                  <input
                    autoFocus
                    autoComplete="off"
                    placeholder="Nombre"
                    id="user-create-name"
                    name="name"
                    value={userInfo.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="ticket-create-company col-md-6">
                  <label className="" htmlFor="user-create-last-name">
                    Apellido
                  </label>
                  <input
                    autoComplete="off"
                    placeholder="Apellido"
                    required
                    id="user-create-last-name"
                    name="lastname"
                    value={userInfo.lastname}
                    onChange={handleChange}
                  />
                </div>
              </fieldset>

              <div className="ticket-create-company">
                <label className="" htmlFor="user-create-email">
                  Correo
                </label>
                <input
                  autoComplete="off"
                  placeholder="Correo"
                  id="user-create-email"
                  name="email"
                  type="email"
                  value={userInfo.email}
                  onChange={handleChange}
                />
              </div>

              <fieldset className="row justify-between">
                <div className="ticket-create-company col-md-6">
                  <label className="" htmlFor="user-create-phone">
                    Teléfono
                  </label>
                  <input
                    autoComplete="off"
                    placeholder="1122000000"
                    id="user-create-phone"
                    name="phone"
                    type="number"
                    value={userInfo.phone}
                    onChange={handleChange}
                  />
                </div>

                <div className="ticket-create-company col-md-6">
                  <label className="" htmlFor="user-create-cellphone">
                    Celular
                  </label>
                  <input
                    autoComplete="off"
                    placeholder="1122000000"
                    id="user-create-cellphone"
                    name="cellphone"
                    type="number"
                    value={userInfo.cellphone}
                    onChange={handleChange}
                  />
                </div>
              </fieldset>
              <div className="ticket-create-user">
                <label className="" htmlFor="user-create-username">
                  Usuario
                </label>
                <input
                  required
                  autoComplete="off"
                  id="user-create-username"
                  name="username"
                  placeholder="Usuario"
                  value={userInfo.username}
                  onChange={handleChange}
                />
              </div>

              <fieldset className="row justify-between">
                <div className="ticket-create-company col-md-6">
                  <label className="" htmlFor="user-create-password">
                    Contraseña
                  </label>
                  <input
                    autoComplete="off"
                    placeholder="Contraseña"
                    required
                    id="user-create-password"
                    type="password"
                    name="password"
                    value={userInfo.password}
                    onChange={handleChange}
                  />
                </div>

                <div className="ticket-create-company col-md-6">
                  <label
                    className=""
                    htmlFor="user-create-password-confirmation"
                  >
                    Confirmar Contraseña
                  </label>
                  <input
                    autoComplete="off"
                    placeholder="Confirmar Contraseña"
                    required
                    type="password"
                    id="user-create-password-confirmation"
                    name="password2"
                    value={userInfo.password2}
                    onChange={handleChange}
                  />
                </div>
              </fieldset>
            </div>
            <div className="ticket-create-part-b">
              <div className="ticket-create-company">
                <label className="" htmlFor="user-create-role">
                  Rol
                </label>
                <input
                  required
                  autoComplete="off"
                  id="user-create-role"
                  name="role"
                  list="user-create-role-list"
                  placeholder="Rol"
                  value={userInfo.role}
                  onChange={handleChange}
                />
                <datalist id="user-create-role-list">
                  {roles?.map((role) => {
                    return (
                      <option key={role.id} value={role.name}>
                        {role.name}
                      </option>
                    );
                  })}
                </datalist>
              </div>

              <div className="ticket-create-company">
                <label className="" htmlFor="user-create-extension">
                  Interno
                </label>
                <input
                  autoComplete="off"
                  placeholder="Interno"
                  required
                  id="user-create-extension"
                  name="extension"
                  value={userInfo.extension}
                  onChange={handleChange}
                />
              </div>

              <CreateUsersCompanies
                companies={companies}
                handleChange={handleChange}
              />
              {/* 
                <label className="" htmlFor="user-create-company">
                  Empresa
                </label>

                <input
                  required
                  autoComplete="off"
                  id="user-create-company"
                  name="company"
                  list="user-create-company-list"
                  placeholder="Empresa"
                  value={userInfo.company}
                  onChange={handleChange}
                />
                <datalist id="user-create-company-list">
                  {companies?.map((company) => {
                    return (
                      <option key={company.id} value={company.name}>
                        {company.name}
                      </option>
                    );
                  })}
                </datalist> */}

              {/* <div className='ticket-create-file'>
								<label htmlFor='FormControlFile1'>Archivo adjunto</label>
								<input
									type='file'
									className='form-control-file'
									id='FormControlFile1'
									name='ticketFile'
									onChange={fileUpload}
								/>
							</div> */}
            </div>
          </div>
          <div className="ticket-create-buttons">
            <button
              className="ticket-create-button"
              type="submit"
              id="ticket-create-button"
            >
              <i className="fas fa-user-plus"></i> Crear usuario
            </button>
            <button
              type="button"
              disabled
              onClick={() => history.goBack()}
              className="ticket-create-return"
            >
              <i className="fas fa-undo-alt"></i> Volver
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrearUser;
