import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

//logo
import logo from '../../Cteam logo.png'

//Redux actions
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../actions/auth'
import { setHeader } from '../../actions/header'

import { PATHS } from '../../types/paths'

import { setTickets } from '../../actions/tickets'

import { setTasks } from '../../actions/tasks/tasks'
import { setProfileInfo } from '../../actions/user/user-profile'
import { getSearchInfo } from '../../actions/custom-search/custom-search'
import { getChartsInfo } from '../../actions/charts/general-charts'
import {
	hasHighAccess,
	hasPanelAccess,
	isAdmin,
} from '../../helpers/permission'
import { cleanTheStorage } from '../../helpers/clean_the_agent_storage'
import { getSwatchUsersInfo } from '../../actions/swatch-users/get-swatch-user'
import { getSubscriptionsAndTopicsInfo } from '../../actions/subscriptions/subscriptions'
import { users } from '../../actions/user/user'

export const Nav = () => {
	const { name: role } = useSelector((state) => state.auth.role)
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(cleanTheStorage())
		switch (location.pathname) {
			/**TICKETS */
			case PATHS.generalTickets:
				hasPanelAccess(role) && dispatch(setTickets('tickets'))
				break

			case PATHS.openTickets:
				hasHighAccess(role) && dispatch(setTickets('open'))
				break

			case PATHS.derivedTickets:
				hasPanelAccess(role) && dispatch(setTickets('derived'))
				break

			case PATHS.pendantTickets:
				hasPanelAccess(role) && dispatch(setTickets('pendant'))
				break

			case PATHS.solvedTickets:
				hasPanelAccess(role) && dispatch(setTickets('solved'))
				break

			case PATHS.closedTickets:
				hasPanelAccess(role) && dispatch(setTickets('closed'))
				break

			case PATHS.createTickets:
				hasPanelAccess(role) &&
					dispatch(
						setHeader(
							'Creación de tickets',
							'Llene correctamente los campos del formulario'
						)
					)
				break

			/**TAREAS */

			case PATHS.generalTasks:
				hasPanelAccess(role) && dispatch(setTasks('tasks'))
				break

			case PATHS.openTasks:
				hasPanelAccess(role) && dispatch(setTasks('open-tasks'))
				break

			case PATHS.derivedTasks:
				hasPanelAccess(role) && dispatch(setTasks('derived-tasks'))
				break

			case PATHS.finishedTasks:
				hasPanelAccess(role) && dispatch(setTasks('finished-tasks'))
				break

			case PATHS.aprovedTasks:
				hasPanelAccess(role) && dispatch(setTasks('aproved-tasks'))
				break

			case PATHS.expiredTasks:
				hasPanelAccess(role) && dispatch(setTasks('expired-tasks'))
				break

			/**PERFIL DE USUARIO */
			case PATHS.panelConfigProfile:
				dispatch(setProfileInfo())
				break

			case PATHS.panelConfigActiveSwatchUsers:
				dispatch(getSwatchUsersInfo('active'))
				break
			case PATHS.panelConfigInactiveSwatchUsers:
				dispatch(getSwatchUsersInfo('inactive'))
				break

			case PATHS.topicsIndex:
				dispatch(getSubscriptionsAndTopicsInfo('index'))
				break
			case PATHS.topics:
				dispatch(getSubscriptionsAndTopicsInfo('topics'))
				break

			/**BÚSQUEDA PERSONALIZADA */
			case PATHS.panelCustomSearch:
				hasPanelAccess(role) && dispatch(getSearchInfo())
				break

			/**USUARIOS */
			case PATHS.activeUsers:
				hasPanelAccess(role) && dispatch(users({ type: 'activo' }))
				break
			case PATHS.inactiveUsers:
				hasPanelAccess(role) && dispatch(users({ type: 'inactivo' }))
				break

			case PATHS.createUsers:
				hasPanelAccess(role) && dispatch(users({ url: 'create' }))
				break

			/**Gráficos de actividad */
			case PATHS.panelCustomCharts:
				hasPanelAccess(role) && dispatch(getChartsInfo())
				break

			default:
				break
		}
	}, [location, dispatch, role])

	const handleLogout = async () => {
		await dispatch(startLogout())
		return (document.location.href = '/panel')
	}

	return (
		<nav className='navbar'>
			<div className='navbar-menu'>
				{/** BOTÓN PARA IR INICIO GRÁFICOS*/}
				<Tippy content='Ir a inicio' placement='right'>
					<a href={PATHS.panelCustomCharts}>
						<img src={logo} alt='Logo C-team' />
					</a>
				</Tippy>

				{hasPanelAccess(role) && ( //GRÁFICOS DE ACTIVIDAD
					<Tippy content='Gráficos' placement='right'>
						<Link to={PATHS.panelCustomCharts} className='navbar-ticket-button'>
							<i className='fas fa-chart-bar'></i>
						</Link>
					</Tippy>
				)}

				{/**TICKETS*/}
				<Tippy content='Ir a tickets' placement='right'>
					<Link to={PATHS.generalTickets} className='navbar-ticket-button'>
						<i className='fas fa-ticket-alt'></i>
					</Link>
				</Tippy>

				{/**TAREAS*/}
				{hasPanelAccess(role) && (
					<Tippy content='Ir a tareas' placement='right'>
						<Link to='/panel/tasks/general' className='navbar-ticket-button'>
							<i className='fas fa-sticky-note'></i>
						</Link>
					</Tippy>
				)}
				{/** USUARIOS */}
				{isAdmin(role) && (
					<Tippy content='Usuarios Regulares' placement='right'>
						<Link to={PATHS.activeUsers} className='navbar-ticket-button'>
							<i className='fas fa-user-plus'></i>
						</Link>
					</Tippy>
				)}
				{/**USUARIOS SWATCH*/}
				{isAdmin(role) && (
					<Tippy content='Usuarios Swatch' placement='right'>
						<Link
							to={PATHS.panelConfigActiveSwatchUsers}
							className='navbar-ticket-button'
						>
							<i className='fas fa-user-cog'></i>
						</Link>
					</Tippy>
				)}
				{/**ABONOS*/}
				{isAdmin(role) && (
					<Tippy content='Abonos' placement='right'>
						<Link to={PATHS.topicsIndex} className='navbar-ticket-button'>
							<i className='fas fa-clipboard-list'></i>
						</Link>
					</Tippy>
				)}

				{/**FILTRO*/}
				{hasPanelAccess(role) && (
					<Tippy content='Búsqueda avanzada' placement='right'>
						<Link to={PATHS.panelCustomSearch} className='navbar-ticket-button'>
							<i className='fas fa-search-plus'></i>
						</Link>
					</Tippy>
				)}
			</div>
			<div className='navbar-user'>
				{/** BOTÓN PARA CONFIGURACIÓN DE USUARIO */}

				<Tippy content='Ir al perfil de usuario' placement='right'>
					<Link to={PATHS.panelConfigProfile} className='navbar-ticket-button'>
						<i className='fas fa-user'></i>
					</Link>
				</Tippy>

				{/** BOTÓN PARA CERRAR SESIÓN */}
				<Tippy content='Cerrar sesión actual' placement='right'>
					<button className='navbar-logout-button' onClick={handleLogout}>
						<i className='fas fa-power-off'></i>
					</button>
				</Tippy>
			</div>
		</nav>
	)
}
