import { TYPES } from "../types/types";

const initialState = {
    ticket_info: {
        priorities:[],
        companies:[],
        status:[],
        topics:[]
    },
    task_info:{
        categories:[],
        dependencies:[],
        companies:[],
        priorities:[],
        deriveUsers:[],
        groups:[],
        status:[]
    }
    
}

export const createReducer = (state = initialState, action) => {

    switch (action.type) {

        //TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS 
        case TYPES.setCompanyPriority:
            return {
                ...state,
                ticket_info: {
                    priorities: [...action.payload.priorities],
                    companies: [...action.payload.companies]
                }
            };

        case TYPES.deleteCreateInfo:
            return {...initialState};

        //TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS 
        case TYPES.setCreateTask:
            return {
                ...state,
                task_info:{
                    categories:[...action.payload.categories],
                    dependencies:[...action.payload.dependencies],
                    companies:[...action.payload.companies],
                    priorities:[...action.payload.priorities],
                    deriveUsers:[...action.payload.deriveUsers],
                    groups:[...action.payload.groups],
                }
            }
            

        /**TICKETS Y TASK PARA EL SEARCHBAR */
        case TYPES.setSearchInfo:
            return {
                ticket_info: {
                    priorities: [...action.payload.ticket_priorities],
                    companies: [...action.payload.companies],
                    status:[...action.payload.ticket_status]
                },
                task_info:{
                    categories:[...action.payload.categories],
                    dependencies:[...action.payload.dependencies],
                    companies:[...action.payload.companies],
                    priorities:[...action.payload.task_priorities],
                    deriveUsers:[...action.payload.deriveUsers],
                    groups:[...action.payload.groups],
                    status:[...action.payload.task_status],
                }
            }

        /**TICKETS PARA CLIENTES */
        case TYPES.setCreateClientTicket:
            return {
                ...state,
                ticket_info: {
                    priorities: [...action.payload.priorities],
                    topics: [...action.payload.topics],
                }
            }

        default:
            return state;
    }
}