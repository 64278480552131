import Axios from 'axios'
import { TYPES } from '../types/types'
import { error, isLoading, isNotLoading } from './ui'
import { cleanTheStorage } from '../helpers/clean_the_agent_storage'

export const startLogin = (value) => {
	return (dispatch) => {
		dispatch(isLoading())

		//console.log('valores nviados',value)
		Axios.post('api/login', value)
			.then((res) => {
				localStorage.setItem('token', res.data.token)
				dispatch(login(res.data.user, res.data.role))
				dispatch(isNotLoading())
			})
			.catch((err) => {
				/* dispatch(
					error('El servidor no registra usuarios con los datos ingresados')
				) */
				alert('El servidor no registra usuarios con los datos ingresados')
				dispatch(isNotLoading())
			})
	}
}

export const login = (user, role) => ({
	type: TYPES.login,
	payload: {
		user: user,
		role: role,
	},
})

export const startLogout = () => {
	return async (dispatch) => {
		try {
			await Axios.get('api/logout', {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
				.then((res) => {
					if (res.data.status === 'success') {
						localStorage.clear()
						dispatch(logout())
						dispatch(cleanTheStorage(false))
					} else {
						return alert('No se pudo cerrar sesión correctamente1')
					}
				})
				.catch((err) => {
					return alert('No se pudo cerrar sesión correctamente2')
				})
		} catch (error) {
			return alert('No se ha podido cerrar sesión')
		}
	}
}

export const logout = () => ({
	type: TYPES.logout,
})
