import { TYPES } from "../types/types";

const initialState = {
    tickets:{},
    tasks:{},
    ticketsTop:{},
    tasksTop:{},
    finishedDailyTickets:{},
    finishedMonthlyTickets:{},
    finishedMonthlyTasks:{},
    finishedDailyTasks:{},
}

export const chartReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case TYPES.setChartsInfo:
            return {
                ...state,
                tickets: action.payload.tickets,
                tasks: action.payload.tasks,
                ticketsTop: action.payload.ticketsTop,
                tasksTop: action.payload.tasksTop,
                finishedDailyTickets: action.payload.finishedDailyTickets,
                finishedMonthlyTickets: action.payload.finishedMonthlyTickets,
                finishedMonthlyTasks: action.payload.finishedMonthlyTasks,
                finishedDailyTasks: action.payload.finishedDailyTasks,
            };

        case TYPES.deleteChartsInfo:
            return {
                ...initialState
            };
    
        default:
            return state;
    }

}