import React from 'react'
import { NavLink } from 'react-router-dom'
import { PATHS } from '../../../types/paths'

export const SecondaryTaskNavbar = () => {
    return (
        <nav className='panel-ticket-secondary-navbar'>
            <NavLink
                to={PATHS.generalTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Mis tareas</small>
            </NavLink>
            <NavLink 
                to={PATHS.openTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Abiertas</small>
            </NavLink>
            <NavLink 
                to={PATHS.derivedTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Derivadas</small>    
            </NavLink>
            <NavLink 
                to={PATHS.finishedTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Finalizadas</small>
            </NavLink>
            <NavLink 
                to={PATHS.aprovedTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Aprobadas</small>
            </NavLink>
            <NavLink 
                to={PATHS.expiredTasks}
                activeClassName='sec-navbar-active'
            >
                <small>Vencidas</small>
            </NavLink>
        </nav>
    )
}
