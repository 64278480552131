import React from 'react';
import { useSelector } from 'react-redux';
import { 
    PieChart, 
    Pie, 
    Cell, 
    Tooltip, 
    ResponsiveContainer 
} from "recharts";
import { noData } from '../../../../../helpers/chart_data';

import { bg, COLORS } from '../../../colors';

export const AssignedTicketChart = () => {
    const { users_assigned:tickets } = useSelector(state => state.charts.tickets);
    let data = [], data2 = [];
    if(tickets) {
        const {
            emergency,
            high,
            medium,
            low
        } = tickets;

        data = [
            { name: "Emergencia", value: emergency },
            { name: "Alta", value: high },
            { name: "Media", value: medium },
            { name: "Baja", value: low }
        ];
        data2 = [
            { name: "Total", value: parseInt(emergency + high + medium + low) }
        ];
    }

    return (
        <div
            className='general_charts_big_pie'
        >
            <ResponsiveContainer
                width='100%'
                height={180}
            >
                <PieChart>
                    {(data2[0] && data2[0].value > 0) && <Tooltip />}
                    <Pie
                        data={data}
                        cx='50%'
                        cy='50%'
                        innerRadius={50}
                        outerRadius={80}
                        dataKey="value"
                        labelLine={false}
                        stroke=''
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                    <Pie 
                        data={noData(data2) ? [{name:'Total',value:1}]: data2}  
                        dataKey="value" 
                        cx="50%" 
                        cy="50%" 
                        outerRadius={noData(data2) ? 80 : 40}
                        fill={bg}
                        stroke='' 
                    />
                </PieChart>
            </ResponsiveContainer>
            <small>
                <b>{data2[0] && data2[0].value}</b> 
                <br />
                Asignados
            </small>
        </div>
    )
}
