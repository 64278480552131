import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskState } from '../../actions/tasks/task-detail';
import { Fecha } from '../DatePicker/Fecha';

export const TaskDerivedTo = ({slug}) => {
    const dispatch = useDispatch();

    const { update_info, detail } = useSelector(state => state.detail.task);
    const [error, setError] = useState({
        status:false,
        message:''
    });
    
    const weights = [ 1,2,3,4,5,6,7,8,9,10 ];
    
    const [ formValue, setFormValue ] = useState({
        action:'edit',
        company:'',
        priority:'',
        urgency:'',
        due_date:'',
        end_date:'',
        type:'',
        derive:'',
        derivedTo:'',
        dependency:'',
    });

    const {
        action,
        company,
        priority,
        urgency,
        type,
        derive,
        due_date,
        end_date,
        derivedTo,
        dependency,
    } = formValue;

    
    const handleChange = (e) => {

        const { name, value } = e.target;

        if( name === 'derive' && !value ) {
            return setFormValue({
                ...formValue,
                derivedTo:'',
                [name]: value
            })
        }

        setFormValue({
            ...formValue,
            [name]: value
        });

    }

    
    const handleClick = async(e) => {
        e.preventDefault();
        /**let aux = Object.entries(formValue).filter( value => !(value[0] === 'derive' || value[0] === 'derivedTo' || value[0] === 'action' || value[0] === 'dependency') && value[1] );
        PARA CUANDO SE TENGA QUE LLENAR TODOS LOS CAMPOS A ACTUALIZAR
         * 
        */

        let aux = Object.entries(formValue).filter( value => !(value[0] === 'action') && value[1] );

        console.log('aux', aux, formValue);

        if( aux.length < 1 ){
            return setError({
                status:true,
                message:'Debe completar correctamente los campos del formulario para poder actualizar'
            }); 
        } 
        

        if ( derive && !derivedTo ) {
            return setError({
                status:true,
                message:'Seleccione a quién va a derivar la tarea'
            });
        }

        if((due_date && end_date) && due_date > end_date) {
            return setError({
                status:true,
                message:'Cambie la fecha de finalización hasta después de la fecha de inicio'
            }); 
        }
        if((due_date && !end_date) && Date.parse(due_date) > Date.parse(detail.end_date)) {
            return setError({
                status:true,
                message:'Esta nueva fecha de inicio se asigna después de la fecha de finalización actual de la tarea, elija una nueva fecha de finalización'
            }); 
        }

        setError({
            status:false,
            message:''
        }); 
        
        let formData = new FormData();
        formData.append('action',action);

        if(aux.length > 0) {
            for(let i = 0; i < aux.length ; i++) {
                formData.append(aux[i][0], aux[i][1])
            }
        } else {
            return setError({
                status:true,
                message:'Debe completar al menos uno de los campos del formulario para poder actualizar'
            }); 
        }

        formData.append('derive',derive);
        formData.append('derivedTo',derivedTo);
        formData.append('dependency',dependency);
        

        //console.log('formdata',commentDate(due_date),commentDate(end_date));
//console.log(due_date, end_date, due_date > end_date)

await dispatch(updateTaskState(slug, formData));
        
window.location.reload();
    }


    return (
        <form className='task-update-form'>
            <Fecha
                handleChange={handleChange}
                formValue={formValue}
            />
            <div className='d-flex justify-content-left flex-wrap'>
                <div className='task-update-type'>
                    <label htmlFor='task-update-type'>Tipo: </label>
                    <select 
                        id='task-update-type'
                        name='type'
                        onChange={handleChange}
                        value={type}
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        {
                            update_info.types
                            && update_info.types.map( type => {
                                return <option
                                            key={type.id}
                                            value={type.id}
                                        >
                                            { type.name }
                                        </option>
                            })
                        }
                    </select>
                </div>

                <div className='task-update-company'>
                    <label htmlFor='inputSelectCompany'>Empresa</label>
                    <select 
                        required 
                        id='inputSelectCompany' 
                        value={company}
                        name='company'
                        onChange={handleChange}
                    >
                        <option value='' disabled>--Empresa--</option>
                        {
                            update_info.companies && update_info.companies.map( company => {
                                return (
                                    <option 
                                        value={ company.id }
                                        key={ company.id }
                                    >
                                        { company.name }
                                    </option>
                                )
                            })
                        }
                    
                    </select>
                </div>
                
                <div className='task-update-priority'>
                    <label htmlFor='task-update-priority'>Prioridad: </label>
                    <select 
                        id='task-update-priority'
                        name='priority'
                        onChange={handleChange}
                        value={ priority }
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        {
                            update_info.priorities 
                            && update_info.priorities.map( prior => {
                                return <option 
                                            key={prior.id}
                                            value={prior.id}
                                        >
                                            {prior.name}
                                        </option>
                            })
                        }
                    </select>
                </div>
                
                
                <div className='task-update-urgency'>
                    <label htmlFor='task-update-urgency'>Urgencia: </label>
                    <select 
                        id='task-update-urgency'
                        name='urgency'
                        onChange={handleChange}
                        value={ urgency }
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        {
                            weights.map( weight => {
                                return <option 
                                            key={weight}
                                            value={weight}
                                        >
                                            {weight}
                                        </option>
                            })
                        }
                    </select>
                </div>

                <hr />
                
                <div className='task-update-derive'>
                    <label htmlFor='task-update-derive'>Derivar a: </label>
                    <select 
                        id='task-update-derive'
                        name='derive'
                        onChange={handleChange}
                        value={ derive }
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        <option value='user'>Usuario</option>
                        <option value='group'>Grupo</option>
                    </select>
                </div>
                
                <div className='task-update-derived-to'>
                    <label htmlFor='task-update-derived-to'>Usuario/grupo: </label>
                    <select 
                        id='task-update-derived-to'
                        name='derivedTo'
                        onChange={handleChange}
                        value={ derivedTo }
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        {
                            (derive && derive === 'user')
                            && update_info.task_derive_users.map( user => (
                                <option
                                    key={user.id}
                                    value={user.id}
                                >
                                    {user.username}
                                </option>
                            ))
                            
                        }
                        {
                            (derive && derive === 'group')
                            && update_info.task_derive_groups.map( group => (
                                <option
                                    key={group.id}
                                    value={group.id}
                                >
                                    {group.name}
                                </option>
                            ))
                            
                        }
                    </select>
                </div>        
                
                {/**<div className='task-update-dependency'>
                    <label htmlFor='task-update-dependency'>Dependencia: </label>
                    <select 
                        id='task-update-dependency'
                        name='dependency'
                        onChange={handleChange}
                        value={ dependency }
                    >
                        <option 
                            style={{color:'grey'}}
                            value=''
                        >
                            No actualizar
                        </option>
                        {
                            update_info.dependencies
                            && update_info.dependencies.map( ticket => (
                                <option 
                                    key={ticket.id}
                                    value={ticket.id}
                                >
                                    {ticket.title}
                                </option>
                            ))

                        }
                    </select>
                </div> */}
                
                <div className='filling'><p></p></div>        

            </div>
            

            <div className='task-update-submit d-flex flex-column'>
                {
                    error.status 
                    && <p style={{ color:'red' }}>
                            {error.message} 
                        </p>
                    
                }
                <button 
                    className='task-update-submit-button'
                    type='button'
                    onClick={ handleClick }
                >
                    Actualizar
                </button>
            </div>



        </form>
     
        )
}
