import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {storeSubscription} from '../../../actions/subscriptions/subscriptions'

export const SubscriptionsCreate = ({setConfirmation}) => {
  const 
    initState = {
      subscription:"", topic:"", group:""
    },
    [error, setError] = useState({status:false,message:''}),
    {groups} = useSelector(state => state.subscriptions),
    [newSub, setNewSub] = useState(initState),
    {subscription, topic, group} = newSub,
    handleChange = (e) => {
      e.preventDefault()
      setNewSub({
        ...newSub,
        [e.target.name]:e.target.value
      })
    }

  const submitSubscription = e => {
    e.preventDefault()
    const 
      arrNewSub = Object.entries(newSub),
      aux2 = groups.filter(groupB => groupB.name === group)[0]
    if(arrNewSub.filter(entry => !entry[1]).length) {
      return setError({status:true, message:"No puede haber campos vacíos"})
    }
    if(!aux2) {
      return setError({status:true, message:"No existe un grupo con ese nombre"})
    }

    setError({status:false,message:''})
    const fd = new FormData()
    arrNewSub.map(entry => entry[0] === 'group' && aux2 ? fd.append(entry[0],aux2.id) : fd.append(entry[0],entry[1]))
    return setConfirmation({
      status:true, 
      info:{
        fd, 
        subscriptionDispatcher: storeSubscription,
        code:"store",
        stateToReset: initState,
        stateResetter: setNewSub,
        option: "Crear abono"
      }
    })
    //dispatch(placeholder(fd))
  }

  return (
    <form className='swatch_user_status_change_form'>
      <div className='swatch_user_status_change_top'>
        <h6>Crear abono</h6>
        { error.status 
        ? <span className='text-danger'>{error.message}</span>
        : <span className='swatch_user_status_change_top_info'>Ingrese el nuevo abono y su tópico</span>}
      </div>
      <div className='swatch_user_status_change_body'>
        <div className='swatch_user_status_change_list'>
          <label 
            htmlFor='subscription_name'
          >
            <small>Nombre de abono</small>
          </label>
          <input 
            id='subscription_name'
            placeholder='Abonos'
            name='subscription'
            value={subscription}
            onChange={handleChange}
          />
        </div>
        <div className='swatch_user_status_change_list'>
          <label 
            htmlFor='topic_name'
          >
            <small>Tópico</small>
          </label>
          <input 
            id='topic_name'
            placeholder='Tópico'
            name='topic'
            value={topic}
            onChange={handleChange}
          />
        </div>

        <div className='swatch_user_status_change_list'>
          <label 
            htmlFor='group_topic'
          >
            <small>Grupo</small>
          </label>
          <input 
            id='group_topic'
            list='group_list'
            placeholder='Grupos'
            value={group}
            name='group'
            onChange={handleChange}
          />
          <datalist
            id='group_list'
          >
            {
              groups?.map(group => (
                <option 
                  value={group.name}
                  key={group.id}
                >
                  {group.name}
                </option>
              ))
            }
          </datalist>
        </div>
        
      </div>
      
      <div className='swatch_user_status_change_bottom'>
        <button
          type='button'
          onClick={submitSubscription}
        >
          <span>
            <i className="fas fa-plus"></i>
            Crear abono
          </span>
        </button>
      </div>
    </form>
  )
}
