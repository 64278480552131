import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTicketCompaniesAndPriorities } from "../actions/ticket-create";
import { isLoading, isNotLoading } from "../actions/ui";
import { toTheTop } from "../helpers/helpers";
import { useForm } from "../hooks/useForm";

export const CrearTicket = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const { ticket_info } = useSelector(state => state.create)

    const [ selectedCompany, setCompany ] = useState('');
    const [ abonoTopic, setAbono ] = useState(null);
    const [ empresa, setEmpresa ] = useState({});
    const [ topic, setTopic ] = useState([]);

    const [error, setError] = useState({
        status:false,
        message:''
    })
    
    const [ value, handleInputChange, actualValue ] = useForm({
        priority:'',
        company:'',
        user:'',
        topic:'',
        support:'',
        title:'',
        content:'',
        ticketFile: null,
    });

    const { company, user } = value;

    //console.log('prueba ', toCreate)
    const handleChange = async(e) => {
        //console.log(1, e.target.name, e.target.value)
        switch (e.target.name) {
            
            case 'company':
                setCompany(e.target.value);
                break;

            case 'topic':
                setAbono(e.target.value);;
                break;

            default: 
                //console.log('No es topic o company')
                break;
        }
        
        handleInputChange({
            target: {
                name : [e.target.name],
                value : e.target.value
            }
        })
        
        //let valor = await actualValue();
        //console.log('valor actual',valor,empresa)
    }
   

    useEffect( () => {
        if(empresa[0]){
            let b = empresa[0].subscriptions.filter( sub => parseInt(sub.id) === parseInt(abonoTopic) );
            setTopic(b);
        }
        
    }, [ empresa, abonoTopic ] );

    
    useEffect( () => {
        setEmpresa(ticket_info.companies.filter( company => company.name === selectedCompany ));
        
    }, [ selectedCompany, ticket_info.companies ] );



    /** #(01) CARGA DE DATOS PARA EL FORMULARIO
     * Obtiene los datos principales del servidor para el armado de la información dentro de los 
     * selectores del form para poder elegir correctamente la información con la que se creará el ticket
     */
    useEffect( () => {
        dispatch(getTicketCompaniesAndPriorities());
    }, [ dispatch ] );
        

    const handleSubmit = async(e) => {
        e.preventDefault();
        let valores = await actualValue();
        let aux = Object.entries(valores).filter( valor => !(valor[0] === 'ticketFile') && !valor[1]);
        //console.log('aux', aux)
        if( aux.length > 0 ){
            setError({
                status:true,
                message:'Debe completar correctamente los campos necesarios del formulario. '
            });
            return toTheTop();
        }
        
        setError({
            status:false,
            message:''
        }); 

        const toSend = Object.entries(valores).filter( valor => !(valor[0] === 'ticketFile'));
        //console.log('toSend', toSend, empresa)
        let formData = new FormData();
        for(let i = 0; i < toSend.length ; i++) {
            if(toSend[i][0] === 'company') {
                const aux_company = ticket_info.companies.filter( company => company.name === selectedCompany );
                //console.log(aux_company)
                if(aux_company.length < 1) {
                    return setError({
                        status:true,
                        message:'Se requiere una COMPAÑÍA válida para el ticket'
                    }); 
                } 
                    
                formData.append(toSend[i][0], aux_company[0].id)
            } else if(toSend[i][0] === 'user'){
                const aux_user = empresa[0].users.filter(aux_user => aux_user.username === user);
                if(aux_user.length < 1) {
                    return setError({
                        status:true,
                        message:'Se requiere un USUARIO válido para el ticket'
                    }); 
                }
                formData.append(toSend[i][0], aux_user[0].id)
            } else {
                formData.append(toSend[i][0], toSend[i][1])
            }
        }
        if(valores.ticketFile) {
            formData.append('ticketFile', valores.ticketFile)
        }

        dispatch(isLoading());

        await Axios.post('api/tickets/create', formData, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then( res => {
            //console.log(res)
            localStorage.setItem('act', '')
            //alert('Ticket creado correctamente');
            dispatch(isNotLoading())
            history.push('/panel/ticket/general');
        } )
        .catch( err => {
            //console.log(err)
            //console.log('si no se creó el ticket')
            alert('El sistema no pudo crear correctamente el ticket, por favor inténtelo nuevamente.');
            dispatch(isNotLoading())
        } )        
    }

    const fileUpload = (e) => {
        //console.log('carga de archivo',e.target.files[0]);
        handleInputChange({
            target: {
                name : [e.target.name],
                value : e.target.files[0]
            }            
        })
    }

    return (
        <div className='seccion-tabla ticket-create-special-margin'>
            <div className='seccion-tabla-interno'>
                <form className='ticket-form' onSubmit={handleSubmit}>
                    <div className='ticket-create-form'>
                    
                        { 
                            error.status 
                            && <p style={{
                                color:'red', 
                                display:'flex', 
                                width:'90%', 
                                margin:'0 10%',
                                justifyContent:'center',
                                padding:'1rem',
                                background:'rgba(255, 0, 0, 0.3)',
                                border:'1px solid red',
                                borderRadius:'.3rem'
                            }}>
                                { error.message }
                            </p> 
                        }
                        <div className='ticket-create-part-a'>

                            <div className="ticket-create-company">
                                <label className="" htmlFor="inlineFormCustomSelect2">Empresa</label>
                                <input 
                                    autoComplete="off"
                                    list="company_filter" 
                                    placeholder='Empresa'
                                    required  
                                    value={company}
                                    id="inlineFormCustomSelect2" 
                                    name='company' 
                                    onChange={ handleChange }
                                />
                                <datalist
                                    id="company_filter"
                                >
                                    { ticket_info.companies && ticket_info.companies.map( comp => {
                                        return <option 
                                                    key={ comp.id } 
                                                    value={ comp.name }
                                                >
                                                    { comp.name }
                                                </option>
                                    } ) }
                                </datalist>
                            </div>
                            <div className="ticket-create-user">
                                <label className="" htmlFor="inlineFormCustomSelect3">Usuario</label>
                                <input 
                                    required 
                                    autoComplete="off"
                                    list="user_filter" 
                                    id="inlineFormCustomSelect3" 
                                    name='user' 
                                    placeholder='Usuario'
                                    onChange={ handleChange } 
                                    />
                                    <datalist
                                        id="user_filter"
                                    >
                                        { empresa[0] && empresa[0].users.map( user => {
                                            return ( <option 
                                                        key={user.id} 
                                                        value={user.username}
                                                    >
                                                        { user.username }
                                                    </option> 
                                            );
                                        } ) }
                                    </datalist>
                            </div>
                            <hr />
                            
                            <div className="ticket-create-tema">
                                <label className="" htmlFor="inlineFormCustomSelect4">Tema</label>
                                <select required className="" id="inlineFormCustomSelect4" name='topic' onChange={ handleChange }>
                                    <option defaultValue >--Tema--</option>
                                    { empresa[0] && empresa[0].subscriptions.map( sub => {
                                        //console.log(sub.id);
                                        return ( <option key={sub.id} value={sub.id}>{ sub.name }</option> );
                                    } ) }
                                </select>
                            </div>
                            <div className="ticket-create-topic">
                                <label className="" htmlFor="inlineFormCustomSelect5">Tópico</label>
                                <select required className="" id="inlineFormCustomSelect5" name='support' onChange={ handleChange } >
                                    <option defaultValue >--Tópico--</option>
                                    { topic[0] && topic[0].topics.map( topic => {
                                        return <option key={ topic.id } value={ topic.id }>{ topic.name }</option>
                                    } ) }
                                </select>
                            </div>

                        </div>
                        <div className='ticket-create-part-b'>

                        <div className='ticket-create-title'>
                            <label className="" htmlFor="ticket-create-title">Título</label>
                            <input type='text' id='ticket-create-title' name='title' placeholder='Título del ticket' onChange={ handleChange } />
                        </div>
                        <div className='ticket-create-description'>
                            <label className="" htmlFor="ticket-create-description">Descripción</label>
                            <textarea col='3' id='ticket-create-description' name='content' placeholder='Agregue una descripción' onChange={ handleChange }></textarea>
                        </div>
                        <hr />

                        <div className="ticket-create-priority">
                            <label className="" htmlFor="inlineFormCustomSelect">Prioridad</label>
                            <select required className="" id="inlineFormCustomSelect" name='priority' onChange={ handleChange } >
                                <option defaultValue >--Prioridad--</option>
                                { ticket_info.priorities && ticket_info.priorities.map( priority => {
                                    return (<option 
                                                key={priority.id} 
                                                value={priority.id}
                                            >
                                                {priority.name}
                                            </option>
                                    )
                                } ) }
                            </select>
                        </div>

                        <div className="ticket-create-file">
                            <label htmlFor="FormControlFile1">Archivo adjunto</label>
                            <input 
                                type="file" 
                                className="form-control-file" 
                                id="FormControlFile1"
                                name='ticketFile'
                                onChange={fileUpload}
                            />
                        </div>
                    </div>
                    </div>
                    <div className='ticket-create-buttons'>
                        <button 
                            className='ticket-create-button' 
                            type='submit'
                            id='ticket-create-button'
                        >
                            <i className="fas fa-ticket-alt"></i> Crear ticket
                        </button>
                        <button 
                            type='button' 
                            disabled
                            onClick={() => history.goBack()} 
                            className='ticket-create-return'
                        ><i className="fas fa-undo-alt"></i> Volver</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
