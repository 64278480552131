import { TYPES } from "../types/types";
const initialState = {
  users:[],
  create:{
    users:[],roles:[]
  },
  detail:{}
}

export const swatchUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.setUsersInfo:
            return {
                ...state,
                users:[...action.payload],
            }
        
        case TYPES.setCreateSwatchUsersInfo:
            return {
                ...state,
                create:{
                    users:[...action.payload.users],
                    roles:[...action.payload.roles],
                }
            }
        
        case TYPES.setSwatchUsersDetail:
            return {
                ...state,
                detail: {...action.payload.detail},
                roles:[...action.payload.roles],
            }

        case TYPES.deleteUserInfo:
            return {...initialState}
    
        default:
            return state;
    }
}