export const PATHS = {
	/**Lista de paths registrados en la aplicación para mejorar separación de código */

	//TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS
	//TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS
	//TICKETS TICKETS TICKETS TICKETS TICKETS TICKETS

	//Tabla de tickets
	templateTickets: '/panel/ticket/:type',

	//Índice de tickets generales
	generalTickets: '/panel/ticket/general',

	//Índice de tickets abiertos
	openTickets: '/panel/ticket/open',

	//Índice de tickets derivados
	derivedTickets: '/panel/ticket/derived',

	//Índice de tickets pendientes
	pendantTickets: '/panel/ticket/pendant',

	//Índice de tickets solucionados
	solvedTickets: '/panel/ticket/solved',

	//Índice de tickets cerrados
	closedTickets: '/panel/ticket/closed',

	//Crear tickets
	createTickets: '/panel/ticket/create',

	//Detalle de tickets
	detailTickets: '/panel/ticket/:slug/detail',

	//TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS
	//TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS
	//TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS TASKS

	//Tabla de tareas
	templateTasks: '/panel/tasks/:type',

	//Índice de tareas generales
	generalTasks: '/panel/tasks/general',

	//Índice de tareas abiertas
	openTasks: '/panel/tasks/open',

	//Índice de tareas derivadas
	derivedTasks: '/panel/tasks/derived',

	//Índice de tareas derivadas
	finishedTasks: '/panel/tasks/finished',

	//Índice de tareas derivadas
	aprovedTasks: '/panel/tasks/aproved',

	//Índice de tareas vencidas
	expiredTasks: '/panel/tasks/expired',

	//Detalle de tareas
	detailTasks: '/panel/task/:slug/detail',

	//Crear tareas:
	createTasks: '/panel/task/create',

	//OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS
	//OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS
	//OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS OPTIONS

	//Panel de usuarios
	activeUsers: '/panel/users/active',
	inactiveUsers: '/panel/users/inactive',
	createUsers: '/panel/users/create',
	editUsers: '/panel/users/:id/edit',
	users: '/panel/users/:type',

	//Panel de información de perfil
	panelConfigProfile: '/panel/config/profile',

	//Panel de búsqueda avanzada
	panelCustomSearch: '/panel/custom/search',

	//Panel de gráficos de promedios tareas y tickets en general y tareas/ticket por usuario
	panelCustomCharts: '/panel/custom/charts',

	//Panel de usuarios Swatch
	panelConfigSwatchUsers: '/panel/config/:active',
	panelConfigActiveSwatchUsers: '/panel/config/swatch-users',
	panelConfigInactiveSwatchUsers: '/panel/config/inactive-users',
	configSwatchUsersDetail: '/panel/config/:_id/detail',

	/**CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS
	 * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS
	 * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS
	 * CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS CLIENTS**/

	clientTickets: '/panel/customer/:type',
	clientGeneralTickets: '/panel/customer/ticket',
	clientSolvedTickets: '/panel/customer/solved',
	clientClosedTickets: '/panel/customer/closed',
	clientPendantTickets: '/panel/customer/pendant',

	topicsPanel: '/panel/subscriptions/:type',
	topicsIndex: '/panel/subscriptions/index',
	topics: '/panel/subscriptions/topics',
}
